export function createImageUrl(oldUrl, size = 500) {
    // let newImageUrl = 'https://d3bd2yxnyotjem.cloudfront.net/';
    let newImageUrl = '';
    if (oldUrl) {
        const linkArr = oldUrl.split('/');
        for (let i = 0; i < linkArr.length - 1; i += 1) {
            newImageUrl += `${linkArr[i]}/`;
        }
        let renderingSize = 'medium';
        if(size < 300){
            renderingSize = 'small';
        }
        if(size > 1000) {
            renderingSize = 'big';
        }
        if(size > 1999) {
            return oldUrl
        }
        newImageUrl += `${renderingSize}/${linkArr[linkArr.length - 1]}`;
        return newImageUrl;
    }
    return '';
}
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage } from "react-lazy-images";
import styles from './image.module.sass';

const Image = ({ src, alt, className, width, height, circle, proxy, ...attrs }) => {
    const classes = classNames(className, { [styles.circle]: circle });
    let url;
    if (proxy) {
        url = process.env.REACT_APP_IMAGE_URL + src;
    }
    if (!src) {
        src = '/images/userAvatar.png';
    }
    return <img src={src} alt={alt} className={classes} width={width} height={height} {...attrs}/>;
};

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    circle: PropTypes.bool,
    className: PropTypes.string,
    proxy: PropTypes.bool,
};

Image.defaultProps = {
    src: '',
    alt: 'image',
    // width: 100,
    // height: 100,
    circle: false,
    className: '',
    proxy: false,
};

export default Image;

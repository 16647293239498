import React, { forwardRef } from 'react';
import styles from './time.module.sass';
import ClassNames from 'classnames';

const TimeInput = forwardRef(({timeState, setTimeState, data_qa}, ref) => {
    
    React.useEffect(() => {
        if (Number(timeState.hours) && !timeState?.minutes) {
            setTimeState((state) => ({ ...state, minutes: '00' }));
        }
    }, [timeState]);

    const amPmHandler = (e) => {
        e.stopPropagation();
        setTimeState((state) => ({ ...state, am: state.am === 'AM' ? 'PM' : 'AM' }));
    };
    const hoursDecrease = (e) => {
        e.stopPropagation();
        let hr = Number(timeState.hours) ? Number(timeState.hours) : 12;
        hr = hr === 1 ? 12 : (hr -= 1);
        setTimeState((state) => ({ ...state, hours: hr < 10 ? '0' + hr : String(hr) }));
    };
    const hoursIncrease = (e) => {
        e.stopPropagation();
        let hr = Number(timeState.hours) ? Number(timeState.hours) : 12;
        hr = hr === 12 ? 1 : (hr += 1);
        setTimeState((state) => ({ ...state, hours: hr < 10 ? '0' + hr : String(hr) }));
    };

    const minutesDecrease = (e) => {
        e.stopPropagation();
        let min = Number(timeState.minutes);
        min = min === 0 ? 45 : (min -= 15);
        setTimeState((state) => ({ ...state, minutes: min < 10 ? '0' + min : String(min) }));
    };

    const minutesIncrease = (e) => {
        e.stopPropagation();
        let min = Number(timeState.minutes);
        min = min === 45 ? 0 : (min += 15);
        setTimeState((state) => ({ ...state, minutes: min < 10 ? '0' + min : String(min) }));
    };

    const timeInputHandler = (e) => {
        e.persist();
        if (Number(e.target.value) > 12 || isNaN(e.target.value)) {
            return;
        }

        if (e.target.value === '') {
            setTimeState((state) => ({ ...state, hours: '' }));
        } else if (Number(e.target.value) === 0) {
            setTimeState((state) => ({ ...state, hours: '0' }));
        } else if (Number(e.target.value) === 1) {
            setTimeState((state) => ({ ...state, hours: '01' }));
        } else if (Number(e.target.value) === 10) {
            setTimeState((state) => ({ ...state, hours: '10' }));
        } else if (Number(e.target.value) === 11) {
            setTimeState((state) => ({ ...state, hours: '11' }));
        } else if (Number(e.target.value) === 12) {
            setTimeState((state) => ({ ...state, hours: '12' }));
        } else {
            setTimeState((state) => ({ ...state, hours: `0${Number(e?.target?.value)}` }));
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.blockWrapper}>
                <button
                    className={ClassNames(styles.arrow, styles.arrowUp)}
                    type="button"
                    onClick={hoursIncrease}
                ></button>
                <input
                    data-qa={data_qa==="start-time"? "hour_start":"hour_end"}
                    className={styles.hoursInput}
                    value={timeState?.hours}
                    onChange={timeInputHandler}
                    placeholder="XX"
                    ref={ref}
                    onClick={() => ref?.current && ref.current.select()}
                />
                <button
                    className={ClassNames(styles.arrow, styles.arrowDown)}
                    type="button"
                    onClick={hoursDecrease}
                ></button>
            </div>
            <span className={styles.colon}>:</span>
            <div className={styles.blockWrapper}>
                <button
                    className={ClassNames(styles.arrow, styles.arrowUp)}
                    type="button"
                    onClick={minutesIncrease}
                ></button>
                <input 
                    data-qa={data_qa==="start-time"? "min_start":"min_end"}
                    className={styles.hoursInput}  
                    value={timeState?.minutes} 
                    readOnly placeholder="XX" />
                <button
                    className={ClassNames(styles.arrow, styles.arrowDown)}
                    type="button"
                    onClick={minutesDecrease}
                ></button>
            </div>
            <button data_atr={data_qa==="start-time"? "startAMPM":"closeAMPM"} className={styles.amPmButton} onClick={amPmHandler} type="button">
                {timeState?.am}
            </button>
        </div>
    );
});

export default TimeInput;

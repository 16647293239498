import React, { useRef } from 'react';
import ClassNames from 'classnames';
import styles from '../../../pages/native-verification/native-input/native-input.module.sass';
import Typography from '../typography';
import { ErrorMessage } from 'formik';
import moment from 'moment';
import { IMask, IMaskInput } from 'react-imask';

const DateInput = ({
    maxlength,
    readonly,
    className,
    label,
    labelClassName,
    error,
    refEffect,
    type,
    children,
    message,
    // onChange,
    placeholder,
    hidden,
    data_attr,
    variant: Component,
    ...props
}) => {
    const {
        field: { name },
        form,
        editVerificationFlow,
    } = props;
    // document.addEventListener(
    //     'touchmove',
    //     function (event) {
    //         if (event.scale !== 1) {
    //             event.preventDefault();
    //         }
    //     },
    //     { passive: false }
    // );

    const htmlFor = `${type}-${Math.random()}`;
    const wrapperRef = useRef(null);
    const maxLength = (inputObj) => {
        const { value } = inputObj;
        if (value.toString().length <= props.valueLength) return true;
        return false;
    };

    const allowedProps = {};
    if (props.valueLength) {
        allowedProps.isAllowed = maxLength;
    }

    const attrs = props.field ? { ...props.field } : { ...props };
    const { setFieldValue } = props.form;

    let errorStyle; // Styles fof different states (verifications and edit verifications flows)

    if (!editVerificationFlow) {
        errorStyle = props.form
            ? props.form.errors && props.form.errors[attrs.name] && props.form.touched && props.form.touched[attrs.name]
            : error;
    } else errorStyle = props.form ? props.form.errors && props.form.errors[attrs.name] : error;

    const value = props.field ? (props.field.value ? props.field.value : '') : props.value;
    // const customStyles = {
    //     input: (defaultStyles) => {
    //         return {
    //             ...defaultStyles,
    //             color: '#D1291B',
    //         };
    //     },
    // };
    // const StyledInput = styled.input`
    //     color: green;
    // `;
    return (
        <div
            className={ClassNames(
                props.erorStyle ? styles.eror : styles.wrapper,
                styles.isActiveInput,
                className
                // props?.field?.value?.length > 0 && styles.filled
            )}
            ref={wrapperRef}
        >
            <label
                className={ClassNames(
                    styles.label,
                    errorStyle ? styles.error : '',
                    labelClassName,
                    props?.field?.value?.length > 0 && styles.label__filledLabel
                )}
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <IMaskInput
                form={props.form}
                message={message}
                value={value}
                hidden={hidden}
                mask={Date}
                name={name}
                onAccept={(value) => {
                    setFieldValue(name, value.replace(/\D/g, ''));
                }}
                pattern="MM/DD/YYYY"
                min={new Date(1897, 0, 1)}
                max={new Date(2004, 0, 1)}
                format={(date) => moment(date).format('MM/DD/YYYY')}
                parse={(str) => moment(str, 'MM/DD/YYYY')}
                lazy={false}
                onBlur={editVerificationFlow ? () => form.validateField(name) : () => form.setFieldTouched(name)}
                erorStyle={editVerificationFlow && props.form.errors[name] ? editVerificationFlow : errorStyle}
                className={ClassNames(
                    errorStyle ? styles.error : null,
                    props?.field?.value?.length > 0 && styles.filled
                )}
                blocks={{
                    YYYY: {
                        mask: IMask.MaskedRange,
                        from: 1897,
                        to: 2004,
                        maxLength: 4,
                        placeholderChar: 'Y',
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                        placeholderChar: 'M',
                    },
                    DD: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                        maxLength: 2,
                        placeholderChar: 'D',
                    },
                }}
            />
            {children}
            {!editVerificationFlow && (
                <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                    {attrs.name && message && <ErrorMessage name={attrs.name} />}
                </Typography>
            )}
            {editVerificationFlow && props.form.errors[attrs.name] && (
                <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                    {props.form.errors[attrs.name]}
                </Typography>
            )}
        </div>
    );
};

export default DateInput;

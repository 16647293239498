import React, { useEffect, useRef, useState } from 'react';
import styles from './date.module.sass';
import ClassNames from 'classnames';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRange } from 'react-date-range';
import moment from 'moment';
import './calendarSpacePage.sass';
import { useMedia } from 'use-media';
import close from '../close.svg';
import { useOnClickOutside } from '../../../utils/useOnClickOutside';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import TimeInput from '../../../components/time/TimeInput';
import { individualBookingSelector } from '../../../store/reducers/bookingRequest';
import {  addMonthsToDate, checkTime, lessThenHour, getTimeState, blockTime, findFirstWorkingDay, dateIsValid, checkIsAvailableHourly } from '../../../utils/helpers/timeHelpers';
import { format, getDay, endOfYesterday } from 'date-fns';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import {useQuery} from "../../hooks/useQuery";
import {useHistory} from "react-router-dom";
import { is } from 'date-fns/locale';

export const Dropdown = ({
    applyDateChanges,
    footerText,
    disabledDatesString,
    available,
    className,
    countOfMonthCalendar,
    clearStorage,
    closeHandler,
    setDateState,
    startState,
    applyHandler,
    disabledDates,
    disabledTabs,
    tabTypeSpace,
    length,
    children,
    applyText="Apply",
    qtyInput,
    bookedDates,
    hourlyBookings,
    clearChildCalendar
}) => {
    const ref = useRef();
    const isMobileHome = useMedia({ maxWidth: '740px' });
    const isMobilePlace = useMedia({ maxWidth: '768px' });

    const { pathname } = useSelector(({ router: { location } }) => location);
    const isSearchPage = pathname.includes('search');
    const isBookingPage = pathname.includes('request-booking');
    const isPlacePage = pathname.includes('place');

    const isMobile = isPlacePage ? isMobilePlace : isMobileHome;

    const booking = useSelector(individualBookingSelector);
    const location = useLocation();
    const isBooking = location.pathname.includes('booking');

    const [type, setType] = React.useState(
        startState?.type ? startState?.type : tabTypeSpace ? tabTypeSpace : 'hourly'
    );

    const [dailyState, setDailyState] = useState(
        startState?.type === 'daily' && startState?.data
            ? startState?.data
            : [
                  {
                      startDate: null,
                      endDate: new Date(''),
                      key: 'selection',

                      //   startDate: findFirstWorkingDay(disabledDatesString),
                      //   endDate: findFirstWorkingDay(disabledDatesString),
                      //   key: 'selection',
                  },
              ]
    );

    const [monthlyState, setMonthlyState] = useState(
        startState?.type === 'monthly' && startState?.data ? startState?.data : null
        // startState?.type === 'monthly' && startState?.data
        //     ? startState?.data
        //     : [
        //           {
        //               startDate: new Date(),
        //               endDate: addMonthsToDate(new Date(), '1'),
        //               key: 'selection',
        //           },
        //       ]
    );

    const [calendarState, setCalendarState] = useState(
        startState?.type === 'hourly' && startState?.data ? startState?.data : null
    );

    const startHours = getTimeState(startState?.time?.startTime).hours;
    const startMinutes = getTimeState(startState?.time?.startTime).minutes;
    const startAM = getTimeState(startState?.time?.startTime).am;

    const endHours = getTimeState(startState?.time?.endTime).hours;
    const endMinutes = getTimeState(startState?.time?.endTime).minutes;
    const endAM = getTimeState(startState?.time?.endTime).am;

    const [startTimeState, setStartTimeState] = React.useState({
        hours: startHours,
        minutes: startMinutes,
        am: startAM,
    });
    const [endTimeState, setEndTimeState] = React.useState({
        hours: endHours,
        minutes: endMinutes,
        am: endAM,
    });

    const { minutes: startMinutesState } = startTimeState;
    const { minutes: endMinutesState } = endTimeState;

    React.useEffect(() => {
        setEndTimeState((state) => ({ ...state, minutes: startMinutesState }));
    }, [startMinutesState]);
    React.useEffect(() => {
        setStartTimeState((state) => ({ ...state, minutes: endMinutesState }));
    }, [endMinutesState]);

    const startAmFormat = React.useMemo(() => {
        return Number(startTimeState?.hours)
            ? `${startTimeState?.hours}:${startTimeState?.minutes} ${startTimeState?.am}`
            : '';
    }, [startTimeState]);
    const endAmFormat = React.useMemo(() => {
        return Number(endTimeState?.hours) ? `${endTimeState?.hours}:${endTimeState?.minutes} ${endTimeState?.am}` : '';
    }, [endTimeState]);

    const [inputValue, setInputValue] = React.useState(startState?.months ? startState?.months : '1');

    const [timeError, setTimeError] = React.useState('');
    const [hourlyBookingError, setHourlyBookingError] = React.useState('');

    useEffect(() => {
        if (startAmFormat && endAmFormat && checkTime(startAmFormat, endAmFormat)) {
            setTimeError('End time cannot be before start time.');
        } else if(startAmFormat && endAmFormat && calendarState && hourlyBookings?.length > 0) {
            setTimeError('');
            const isAvailable = checkIsAvailableHourly(hourlyBookings, calendarState, startAmFormat, endAmFormat); 
    if(!isAvailable && !blockTime(calendarState, startAmFormat, endAmFormat, available)) {
                setHourlyBookingError('The space is currently booked at this time.');
            } else {
                setHourlyBookingError('');
            } 
        } else {
            setTimeError('');
            setHourlyBookingError('');
        }
    }, [startAmFormat, endAmFormat, calendarState]);


    useOnClickOutside(ref, () => {
        clearChildCalendar && clearChildCalendar();
        closeHandler && closeHandler()});
    const clearHandler = () => {
        clearStorage && clearStorage();
        setCalendarState(null);
        setStartTimeState({
            hours: '',
            minutes: '',
            am: 'AM',
        });
        setEndTimeState({
            hours: '',
            minutes: '',
            am: 'AM',
        });
        setDateState({
            type: null,
            data: null,
            time: null,
        });
        setMonthlyState(null);
        
        setInputValue('1');
        setDailyState([
            {
                startDate: null,
                endDate: new Date(''),
                key: 'selection',
            },
        ]);

        setTimeError('');
    };

    const validateDate = () => {
        if (booking.pricingType === 'hourly' && isBooking) {
            return (
                (format(calendarState, 'MM/dd/yyyy') === booking.hourlyDateVal &&
                    startAmFormat ===
                        `${booking.hourlyStartTime.slice(0, 2)}:${booking.hourlyStartTime.slice(2)} ${
                            booking.startAM
                        }` &&
                    endAmFormat ===
                        `${booking.hourlyEndTime.slice(0, 2)}:${booking.hourlyEndTime.slice(2)} ${booking.endAM}`) ||
                blockTime(calendarState, startAmFormat, endAmFormat, available)
            );
        }
        if (booking.pricingType === 'daily' && isBooking) {
            const start = dateIsValid(dailyState?.[0]?.startDate)
                ? format(dailyState?.[0]?.startDate, 'MM/dd/yyyy')
                : null;
            const end = dateIsValid(dailyState?.[0]?.endDate) ? format(dailyState?.[0]?.endDate, 'MM/dd/yyyy') : null;
            return (!start && !end) || (start === booking.dailyStartVal && end === booking.dailyEndVal);
        }
        if (booking.pricingType === 'monthly' && isBooking) {
            return (
                (format(monthlyState, 'MM/dd/yyyy') === booking.monthlyStartVal &&
                    inputValue === booking.numberOfMonths) ||
                !Number(inputValue)
            );
        }
        if (type === 'hourly') {
            return (
                !calendarState ||
                !Number(startTimeState?.hours) ||
                !Number(endTimeState?.hours) ||
                checkTime(startAmFormat, endAmFormat) ||
                lessThenHour(startAmFormat, endAmFormat) ||
                blockTime(calendarState, startAmFormat, endAmFormat, available) || 
                hourlyBookingError
            );
        }
        if (type === 'daily') {
            const start = dateIsValid(dailyState?.[0]?.startDate)
                ? format(dailyState?.[0]?.startDate, 'MM/dd/yyyy')
                : null;
            const end = dateIsValid(dailyState?.[0]?.endDate) ? format(dailyState?.[0]?.endDate, 'MM/dd/yyyy') : null;
            return (!start && !end) || (start === end && disabledDatesString?.includes(start));
        }
        if (type === 'monthly') {
            return !Number(inputValue) || !monthlyState;
        }
    };

    const apply = (e) => {
        e.preventDefault();
        applyHandler(e, type, calendarState, startAmFormat, endAmFormat, dailyState, monthlyState, inputValue);
    };

    const startRef = React.useRef();
    const endRef = React.useRef();

    React.useEffect(() => {
        if (
            !(startAmFormat && endAmFormat && checkTime(startAmFormat, endAmFormat)) &&
            !blockTime(calendarState, startAmFormat, endAmFormat, available) &&
            applyDateChanges
        ) {
            applyDateChanges(type, calendarState, startAmFormat, endAmFormat, dailyState, monthlyState, inputValue);
        }
    }, [type, calendarState, startAmFormat, endAmFormat, dailyState, monthlyState, inputValue, available]);

    const getDisabledDays = (day) =>
        disabledDatesString?.includes(format(day, 'MM/dd/yyyy')) && day.getTime() >= endOfYesterday().getTime();
    function customDayContent(day) {
        let extraDot = null;
        let whiteLine = null;
        const isDisabled =
            disabledDatesString?.includes(format(day, 'MM/dd/yyyy')) && day.getTime() >= endOfYesterday().getTime();
        if (isDisabled) {
            extraDot = (
                <div
                    style={{
                        height: '1.5px',
                        width: '20px',
                        background: '#999EAC',
                        position: 'absolute',
                        top: 11,
                        left: 8,
                        zIndex: 10,
                    }}
                />
            );
            whiteLine = (
                <div
                    style={{
                        height: '38px',
                        width: '6px',
                        background: '#ffffff',
                        position: 'absolute',
                        top: -6,
                        left: getDay(day) === 6 ? 34 : -2,
                        zIndex: 1000,
                    }}
                />
            );
        }
        return (
            <>
                {extraDot}
                {whiteLine}
                <span style={{ color: isDisabled && '#999EAC', background: isDisabled && 'white', width: 35 }}>
                    {format(day, 'd')}
                </span>
            </>
        );
    }

    return (
        <>
            <div
                className={ClassNames(
                    styles.dropdown__wrapper,
                    {
                        [styles.booking]: isBookingPage,
                        [styles.search]: isSearchPage,
                        [styles.place]: isPlacePage,
                    },
                    className
                )}
                ref={ref}
            >
                {isMobile && (
                    <div className={styles.mobileTop}>
                        <p className={styles.mobileTitle}>When</p>
                        <button
                            className={styles.closeBtn}
                            onClick={(e) => {
                                e.stopPropagation();
                                closeHandler();
                            }}
                        >
                            <img src={close} alt="close" />
                        </button>
                    </div>
                )}
                <Tabs
                    type={type}
                    setType={setType}
                    clearHandler={clearHandler}
                    disabledTabs={disabledTabs}
                    length={length}
                    qtyInput={qtyInput}
                >
                    <div label="Hourly">
                        <div
                            className={ClassNames(styles.calendarWrapper, {
                                [styles.calendarWrapperPlace]: isPlacePage,
                            })}
                        >
                            <Calendar
                                className="calendar"
                                date={calendarState}
                                color="#2A1BA1"
                                weekdayDisplayFormat="EEEEE"
                                showMonthAndYearPickers={false}
                                minDate={moment().toDate()}
                                disabledDates={disabledDates}
                                onChange={(item) => {
                                    setCalendarState(item);
                                }}
                                // dayContentRenderer={customDayContent}
                            />
                            {!isMobile && <div className={ClassNames(styles.calendar__center__line, {[styles.withInput]: qtyInput})}></div>}
                            <div
                                className={ClassNames(styles.rightWrapper, { [styles.rightWrapperPlace]: isPlacePage })}
                            >
                                <p className={styles.timeTitle}>Select your desired time</p>
                                <div
                                    className={classNames(styles.timeWrapper, {
                                        [styles.timeWrapperPlace]: isPlacePage,
                                    })}
                                >
                                    <div>
                                        <p className={styles.timeSubtitle}>Start</p>
                                        <TimeInput
                                            timeState={startTimeState}
                                            setTimeState={setStartTimeState}
                                            ref={startRef}
                                        />
                                    </div>
                                    <div>
                                        <p className={styles.timeSubtitle}>End</p>
                                        <TimeInput
                                            timeState={endTimeState}
                                            setTimeState={setEndTimeState}
                                            ref={endRef}
                                        />
                                    </div>
                                </div>

                                {(timeError || hourlyBookingError) && <p className={styles.timeError}>{timeError || hourlyBookingError}</p>}
                            </div>
                        </div>
                    </div>
                    <div label="Daily" className={styles.dailyWrapper}>
                        <DateRange
                            className="daterange-home"
                            rangeColors={['#2A1BA1', '#2A1BA1']}
                            monthDisplayFormat="MMMM yyyy"
                            weekdayDisplayFormat="EEEEE"
                            showDateDisplay={false}
                            disabledDay={getDisabledDays}
                            disabledDates={bookedDates}
                            minDate={moment().toDate()}
                            onChange={(item) => {
                                // if (
                                //     disabledDatesString?.includes(format(item?.selection?.startDate, 'MM/dd/yyyy')) ||
                                //     disabledDatesString?.includes(format(item?.selection?.endDate, 'MM/dd/yyyy'))
                                // ) {
                                //     return;
                                // }
                                setDailyState([item.selection]);
                            }}
                            months={isMobile ? 1 : 2}
                            showMonthAndYearPickers={false}
                            direction="horizontal"
                            ranges={dailyState}
                            // dayContentRenderer={customDayContent}
                        />
                    </div>
                    <div label="Monthly">
                        <div
                            className={ClassNames(styles.calendarWrapper, {
                                [styles.calendarWrapperPlace]: isPlacePage,
                            })}
                        >
                            <div
                                className={ClassNames(styles.monthlyWrapper, {
                                    [styles.fullMonthly]: countOfMonthCalendar === 2,
                                    [styles.monthlyWrapperPlace]: isPlacePage,
                                })}
                            >
                                <Calendar
                                    className={
                                        countOfMonthCalendar === 2 && !isMobile ? 'calendar-monthly' : 'calendar'
                                    }
                                    color="#2A1BA1"
                                    monthDisplayFormat="MMMM yyyy"
                                    weekdayDisplayFormat="EEEEE"
                                    showMonthAndYearPickers={false}
                                    minDate={moment().toDate()}
                                    disabledDates={disabledDates}
                                    date={monthlyState}
                                    onChange={(item) => setMonthlyState(item)}
                                    // onChange={(item) => {
                                    //     setCalendarState(item);
                                    // }}
                                    months={countOfMonthCalendar && !isMobile ? countOfMonthCalendar : 1}
                                    direction={countOfMonthCalendar === 2 && !isMobile ? 'horizontal' : 'vertical'}
                                    //dayContentRenderer={customDayContent}
                                />
                                {/* <DateRange
                                    className="daterange-home"
                                    rangeColors={['#2A1BA1']}
                                    monthDisplayFormat="MMMM yyyy"
                                    weekdayDisplayFormat="EEEEE"
                                    showDateDisplay={false}
                                    minDate={moment().toDate()}
                                    onChange={(item) => {
                                        const date = new Date(item?.selection?.startDate);
                                        item.selection.endDate = addMonthsToDate(date, inputValue);
                                        setMonthlyState([item.selection]);
                                    }}
                                    months={countOfMonthCalendar && !isMobile ? countOfMonthCalendar : 1}
                                    showMonthAndYearPickers={false}
                                    direction="horizontal"
                                    ranges={monthlyState}
                                /> */}
                            </div>
                            {!isMobile && !countOfMonthCalendar === 2 && (
                                <div className={styles.calendar__center__line}></div>
                            )}
                            {countOfMonthCalendar !== 2 && (
                                <div
                                    className={ClassNames(styles.rightWrapper, {
                                        [styles.rightWrapperPlace]: isPlacePage,
                                    })}
                                >
                                    <p className={styles.timeTitle}>How many months</p>
                                    <div className={ClassNames(styles.timeSelectWrapper, styles.monthWrapper)}>
                                        <span className={ClassNames(styles.time__label)}>Months</span>
                                        <NumberFormat
                                            type="text"
                                            className={ClassNames(styles.timeInput, styles.monthInput)}
                                            placeholder="Number of months"
                                            value={inputValue}
                                            decimalScale={0}
                                            allowNegative={false}
                                            onChange={(e) => {
                                                if (!e.target.value.length) {
                                                    setDateState({
                                                        type: null,
                                                        data: null,
                                                        time: null,
                                                    });
                                                }
                                                setInputValue(e.target.value);
                                                // const date = new Date(monthlyState[0].startDate);
                                                // const endDate = addMonthsToDate(date, e.target.value);
                                                // setMonthlyState((state) => [{ ...state[0], endDate: endDate }]);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Tabs>
                {/* {children} */}
                <div className={styles.footer}>
                    <p className={styles.text}>{footerText || 'Choose the date and time of renting the space.'}</p>
                    <button type="button" onClick={clearHandler} className={styles.clearButton}>
                        Clear
                    </button>
                    <button
                        type="button"
                        className={ClassNames(styles.applyBtn, { [styles.disabledBtn]: validateDate() })}
                        onClick={apply}
                        disabled={validateDate()}
                    >
                        {applyText}
                    </button>
                </div>
                {/* {isMobile && (
                    <div className={styles.buttonsWrapper}>
                        <button className={styles.clear} onClick={clearHandler} type="button">
                            <img src={clear} />
                            clear
                        </button>
                        <button
                            type="button"
                            onClick={apply}
                            className={styles.done}
                            disabled={validateDate()}
                        >
                            Done
                        </button>
                    </div>
                )} */}
            </div>
        </>
    );
};

const Tabs = ({ children, type, clearHandler, setType, disabledTabs=[1, 1, 1], length=3, qtyInput}) => {
    const [activeTab, setActiveTab] = React.useState(type === 'daily' ? 1 : type === 'monthly' ? 2 : 0);
    const location = useLocation();
    const query = useQuery();
    const clearQuery = () => {
        if(query.has('date') || query.has('hoursstart') || query.has('hoursend') || query.has('datestart') || query.has('dateend') || query.has('months') || query.has('type')) {
            query.delete('type');
            query.delete('date');
            query.delete('hoursstart');
            query.delete('hoursend');
            query.delete('datestart');
            query.delete('dateend');
            query.delete('months');
            window.history.pushState("", "", location.pathname);
        }
    };

    const onClickTabItem = (tab) => {
        setActiveTab(tab);
        setType(tab === 0 ? 'hourly' : tab === 1 ? 'daily' : 'monthly')
        clearHandler();
        clearQuery();
    };

    const childrenList = React.useMemo(() => (Array.isArray(children) ? children : [children]), [children]);
    return (
        <div>
            <div className={styles.wrapper}>
                <ul className={styles.tabs}>
                    <span
                    className={ClassNames(styles.slider, {
                        [styles.sliderFull]: length === 1,
                        [styles.sliderHalf]: length === 2,
                        [styles.sliderHalfLeft]: length === 2 && !disabledTabs?.[0] && activeTab === 1,
                        [styles.sliderRight]:
                            (length === 2 && (activeTab === 1 || activeTab === 2) && disabledTabs?.[0]) ||
                            (!disabledTabs?.[0] && activeTab === 2 && length === 2),
                        [styles.sliderMiddle]: length === 3 && activeTab === 1,
                        [styles.sliderRightFull]: length === 3 && activeTab === 2,
                    })}
                    ></span>
                    {childrenList.map((child, index) => {
                        const { label } = child.props;
                        return (
                            <TabItem
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                index={index}
                                onClick={onClickTabItem}
                                disabledTabs={disabledTabs}
                                length={length}
                            />
                        );
                    })}
                </ul>
            </div>
            {qtyInput}
            <div className={styles.tabContent}>
                {childrenList.map((child, index) => {
                    if (index !== activeTab) return null;
                    return child;
                })}
            </div>
        </div>
    );
};
export const TabItem = ({ label, index, onClick, activeTab, disabledTabs, length }) => {
    const onTabClick = () => {
        onClick(index);
    };
    return (
        <li
            onClick={onTabClick}
            className={ClassNames(styles.tabItem, {
                [styles.tabItemFull]: length === 1,
                [styles.tabItemHalf]: length === 2,
                [styles.tabItemActive]: index === activeTab,
                [styles.tabItemDisabled]: !disabledTabs?.[index],
            })}
        >
            {label}
        </li>
    );
};

export default Dropdown;


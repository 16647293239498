import { all, call, put, take } from '@redux-saga/core/effects';
import { createAction, handleActions } from 'redux-actions';
import { resource } from '../../service/http';
import {setPaidList} from "./account";

const namespace = `transactions`;

const GET_TRANSACTIONS = `${namespace}/GET_TRANSACTIONS`;
const SET_TRANSACTIONS = `${namespace}/SET_TRANSACTIONS`;
const SET_STRIPE_DEATAILS = `${namespace}/SET_STRIPE_DEATAILS`;
const SET_ACCOUNT_LINK = `${namespace}/SET_ACCOUNT_LINK`;
// const SET_USER = `${namespace}/SET_USER`;

export const getTransactions = createAction(GET_TRANSACTIONS);
export const setTransactions = createAction(SET_TRANSACTIONS);
export const setStripeDetails = createAction(SET_STRIPE_DEATAILS);
export const setSAccountLink = createAction(SET_ACCOUNT_LINK);

const initialState = {
    transactions: null,
    stripeDetails: null,
    accountLink: null,
    list: null
};

export default handleActions(
    {
        [SET_TRANSACTIONS]: (state, { payload }) => ({ ...state, transactions: payload }),
        [SET_STRIPE_DEATAILS]: (state, { payload }) => ({ ...state, stripeDetails: payload }),
        [SET_ACCOUNT_LINK]: (state, { payload }) => ({ ...state, accountLink: payload }),
        // [SET_USER]: (state, { payload }) => ({ ...state, list: payload }),
    },
    initialState
);

export const transactionsSelector = (state) => state[namespace];

function* getTransactionsRequest() {
    while (true) {
        yield take(GET_TRANSACTIONS);
        try {
            const { transactions, stripeDetailsSubmitted, accountLink } = yield call(resource.get, `/user/transactions`);
            yield put(setTransactions(transactions));
            yield put(setSAccountLink(accountLink));
            yield put(setStripeDetails(stripeDetailsSubmitted));

        } catch (err) {
            // console.log(err);
        }
    }
}

export function* sagas() {
    yield all([getTransactionsRequest()]);
}


// const depositString = (place.capturePrice / 100).toFixed(2).toString()
// {`$${depositString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}

import React from 'react';
import styles from './price-filter.module.sass';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import classNames from 'classnames';
import ClassNames from 'classnames';
import 'rheostat/initialize';
import Rheostat from 'rheostat';
import './rheostat.sass';
import { useQuery } from '../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { filtersSelctor, setPriceOpen } from '../../store/reducers/search';
import Image from '../../components/ui/image/Image';
import closeSvg from './close.svg';
import { useMedia } from 'use-media';
import { ReactComponent as PriceIcon } from './price.svg';
import NumberFormat from 'react-number-format';

const PriceFilter = (props) => {
    const { indicatorState, setIndicatorState } = props;

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const dispatch = useDispatch();

    const openHandler = (e) => {
        e.stopPropagation();
        setDropdownOpen(true);
        dispatch(setPriceOpen(true));
    };
    const closeHandler = (e) => {
        e.stopPropagation();
        setDropdownOpen(false);
        dispatch(setPriceOpen(false));
    };
    const isMobile = useMedia({ maxWidth: '550px' });
    const query = useQuery();

    const priceSort = query.get('priceSort');
    const priceMin = query.get('priceMin');
    const priceMax = query.get('priceMax');
    const showIndicator = React.useMemo(() => {
        return priceSort || priceMin || priceMax;
    }, [priceSort, priceMin, priceMax]);

    return (
        <div className={styles.wrapper}>
            <button
                className={classNames(styles.price__button, { [styles.active]: dropdownOpen })}
                onClick={openHandler}
            >
                {isMobile && showIndicator && (
                    <div className={styles.indicator}>
                        <div className={styles.inner} />
                    </div>
                )}
                {isMobile ? (
                    <PriceIcon />
                ) : priceSort ? (
                    `${priceSort[0].toUpperCase() + priceSort.slice(1)} price`
                ) : (
                    'Price'
                )}
            </button>
            {dropdownOpen && (
                <Dropdown
                    closeHandler={closeHandler}
                    dropdownOpen={dropdownOpen}
                    priceSort={priceSort}
                    priceMin={priceMin}
                    priceMax={priceMax}
                    indicatorState={indicatorState}
                    setIndicatorState={setIndicatorState}
                />
            )}
        </div>
    );
};

export default PriceFilter;

const Dropdown = ({ closeHandler, priceSort, priceMin, priceMax, dropdownOpen, indicatorState, setIndicatorState }) => {

    const ref = React.useRef();
    const filters = useSelector(filtersSelctor);

    const [min, setMin] = React.useState(priceMin ? +priceMin : filters?.minprice);
    const [max, setMax] = React.useState(priceMax ? +priceMax : filters?.maxprice);

    const [price, setPrice] = React.useState(priceSort ? priceSort : 'lowest');
    const [custom, setCustom] = React.useState(null);
    useOnClickOutside(ref, (e) => closeHandler(e));
    const { pathname } = useSelector(({ router: { location } }) => location);
    const query = useQuery();
    const dispatch = useDispatch();

    const isMobile = useMedia({ maxWidth: '990px' });
    React.useEffect(() => {
        if (dropdownOpen && isMobile) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => (document.body.style.overflow = '');
    }, [dropdownOpen, isMobile]);

    React.useEffect(() => {
        if (min > filters?.minprice || max < filters?.maxprice) {
            setCustom('custom');
        } else {
            setCustom(null);
        }
    }, [min, max]);

    const applyFilters = (e, props) => {
        query.delete('page');
        if (price) {
            query.set('priceSort', price);
            setIndicatorState(true);
        }
        if (!custom) {
            query.delete('priceMin');
            query.delete('priceMax');
        } else if (filters?.minprice !== min || filters?.maxprice !== max) {
            if (min < filters?.minprice) {
                query.set('priceMin', filters?.minprice);
                setIndicatorState(true);
            } else {
                query.set('priceMin', min);
                setIndicatorState(true);
            }

            if (max > filters?.maxprice) {
                query.set('priceMax', filters?.maxprice);
                setIndicatorState(true);
            } else {
                query.set('priceMax', max);
                setIndicatorState(true);
            }
        }
        dispatch(push({ pathname, search: query.toString() }));
        closeHandler(e);
    };
    const clearFilters = (e) => {
        query.delete('priceSort');
        query.delete('priceMin');
        query.delete('priceMax');
        dispatch(push({ pathname, search: query.toString() }));
        closeHandler(e);
    };
    const clearCustom = () => {
        setMin(filters?.minprice);
        setMax(filters?.maxprice);
        setCustom(null);
    };

    const checkFilter = () => {
        if (max - min < 1) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className={styles.overlay} />
            <div className={styles.dropdown} ref={ref}>
                {isMobile && <h3 className={styles.title}>Price</h3>}
                <button className={styles.closeBtn} onClick={closeHandler}>
                    <Image src={closeSvg} />
                </button>
                <div className={styles.flex}>
                    <div className={styles.rheostat__wrapper}>
                        <Rheostat
                            min={filters?.minprice}
                            max={filters?.maxprice}
                            values={[min, max]}
                            onValuesUpdated={(e) => {
                                setMin(e.values[0]);
                                setMax(e.values[1]);
                            }}
                        />
                        <div className={styles.price__inputs}>
                            <div className={styles.input__wrapper}>
                                <label className={styles.label} htmlFor="minimum">
                                    Minimum
                                </label>
                                <NumberFormat
                                    value={min}
                                    className={ClassNames(styles.input, styles.topInput)}
                                    allowNegative={false}
                                    allowEmptyFormatting={true}
                                    prefix="$"
                                    isNumericString={true}
                                    onValueChange={(values, sourceInfo) => {
                                        if (values.value > filters?.maxprice) {
                                            return;
                                        }
                                        setMin(values.value);
                                    }}
                                    thousandSeparator=" " 
                                />
                            </div>
                            <div className={styles.input__wrapper}>
                                <label className={styles.label} htmlFor="maximum">
                                    Maximum
                                </label>
                                <NumberFormat
                                    value={max}
                                    className={ClassNames(styles.input, styles.topInput)}
                                    allowNegative={false}
                                    allowEmptyFormatting={true}
                                    prefix="$"
                                    isNumericString={true}
                                    onValueChange={(values, sourceInfo) => {
                                        setMax(values.value);
                                    }}
                                    thousandSeparator=" " 
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <p
                            onClick={() => setPrice('highest')}
                            className={classNames(styles.btn, { [styles.active]: price === 'highest' })}
                        >
                            Highest price
                        </p>
                        <p
                            onClick={() => setPrice('lowest')}
                            className={classNames(styles.btn, { [styles.active]: price === 'lowest' })}
                        >
                            Lowest price
                        </p>
                        <p onClick={clearCustom} className={classNames(styles.btn, { [styles.active]: !!custom })}>
                            Custom
                        </p>
                    </div>
                </div>
                <div className={styles.footer}>
                    <p className={styles.text}>Choose a price range or sort.</p>
                    <div className={styles.buttons__wrapper}>
                        <button className={styles.clear} onClick={clearFilters}>
                            Clear
                        </button>
                        <button
                            className={ClassNames(styles.apply, { [styles.disabled]: checkFilter() })}
                            onClick={applyFilters}
                            disabled={checkFilter()}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

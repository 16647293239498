import { createAction, handleActions } from 'redux-actions';
import { all, call, put, take } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { dangerMessage } from '../../components/ui/alert/alertReducer';
import { push } from 'connected-react-router';
import {popupWarning} from "./popupReduser";


const namespace = 'editBooking';

const RESET_BOOKING = `${namespace}/RESET_BOOKING`;

const SET_LOAD = `${namespace}/SET_LOAD`;

const GET_BOOKING_DETAILS = `${namespace}/GET_BOOKING_DETAILS`;
const SET_BOOKING_DETAILS = `${namespace}/SET_BOOKING_DETAILS`;
const SUBMIT_EDIT_FORM = `${namespace}/SUBMIT_FORM`;

export const setLoad = createAction(SET_LOAD);

export const getBookingDetails = createAction(GET_BOOKING_DETAILS);
export const setBookingDetails = createAction(SET_BOOKING_DETAILS);
export const submitEditForm = createAction(SUBMIT_EDIT_FORM);

export const resetBooking = createAction(RESET_BOOKING);

const initialState = {
    load: false,
    bookingDetails: null,
};

export default handleActions(
    {
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_BOOKING_DETAILS]: (state, { payload }) => ({ ...state, bookingDetails: payload }),

        [RESET_BOOKING]: () => initialState,
    },
    initialState
);

export const bookingDetailsSelector = (state) => state[namespace].bookingDetails;
export const loadSelector = (state) => state[namespace].load;

export function* getBookingRequest() {
    while (true) {
        const { payload } = yield take(GET_BOOKING_DETAILS);

        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, `/booking-request/${payload}`);
            if (response.length) {
                yield put(setBookingDetails(response));
            }
        } catch (error) {
            // console.log(error);
            yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* submitEditRequest() {
    while (true) {
        const {
            payload: { form, setSubmitting },
        } = yield take(SUBMIT_EDIT_FORM);
        const { id, amount, ...values } = form;

        if (values.range.length === 1) {
            values.range.push(values.range[0]); // if user select only one date we create range with same date
        }

        try {
            yield put(setLoad(true));
            const response = yield call(resource.post, '/booking-request/edit', { ...values, amount: amount, id: id });
            if (response) {
                setSubmitting(false);
                yield put(
                    push({
                        pathname: '/thanks',
                        state: {
                            title: 'Thank you for your booking request!',
                            description: 'One of our agents will be in touch shortly. Please call for urgent requests.',
                            buttonTitle: 'Go to my account',
                            buttonLink: '/account/requests',
                            // phone: `${response.user.phoneNumber}`,
                        },
                    })
                );
                yield put(resetBooking());
            }
        } catch (err) {
            // console.log('err :>> ', err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* sagas() {
    yield all([getBookingRequest(), submitEditRequest()]);
}

import { createAction, handleActions } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { setError } from './search';
import { popupSuccess, popupWarning } from './popupReduser';
import { logoutAction } from './auth';
import posthog from 'posthog-js';

const namespace = 'user';

const GET_USER = `${namespace}/GET_USER`;
const SET_USER = `${namespace}/SET_USER`;
const RESET_USER = `${namespace}/RESET_USER`;
const SET_ERROR = `${namespace}/SET_ERROR`;

const UPDATE_AVATAR = `${namespace}/UPDATE_AVATAR`;
const DELETE_AVATAR = `${namespace}/DELETE_AVATAR`;

const SUBMIT_SETTINGS = `${namespace}/SUBMIT_SETTINGS`;
const SET_LOAD = `${namespace}/SET_LOAD`;
const SET_CUSTOM_AMENITIES = `${namespace}/SET_CUSTOM_AMENITIES`;

export const setUser = createAction(SET_USER);
export const resetUser = createAction(RESET_USER);
export const getUser = createAction(GET_USER);

export const updateAvatar = createAction(UPDATE_AVATAR);
export const deleteAvatar = createAction(DELETE_AVATAR);
export const submitSettings = createAction(SUBMIT_SETTINGS);
export const setLoad = createAction(SET_LOAD);
export const setCustomAmenities = createAction(SET_CUSTOM_AMENITIES);

const initialState = {
    load: false,
    error: null,
    payload: null,
    persons: { data: [] },
    paymentsMethods: { data: [] },
    account: {
        requirements: {
            eventually_due: [],
            currently_due: [],
            past_due: [],
            errors: [],
            pending_verification: [],
        },
        business_profile: {},
    },
};

export default handleActions(
    {
        [RESET_USER]: () => initialState,
        [SET_USER]: (state, { payload }) => ({ ...state, ...payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_CUSTOM_AMENITIES]: (state, { payload }) => ({ ...state, custom_amenities: payload }),
    },
    initialState
);

export const userSelector = (state) => state[namespace];

export function* getUserRequest() {
    while (true) {
        yield take(GET_USER);
        try {
            yield put(setLoad(true));
            const response = yield call(resource.get, '/user');
            if (response === null || !response.role || response.role === undefined) {
                yield put(setUser(null));
                // console.log('user failed');
            } else {
                yield put(setUser({ ...response }));
            }
            yield put(setLoad(false));
        } catch (err) {
            yield put(setLoad(false));
        }
    }
}

export function* updateAvatarRequest({ payload }) {
    const data = new FormData();
    data.append('file', payload);
    const type = payload?.type.split('/')[0];
    if (type !== 'image') {
        return yield put(popupWarning({ h2: 'Upload Failed', message: ' Please make sure you are uploading a png or jpeg.', showImg: true }));
    }
    try {
        yield put(setLoad(true));
        const response = yield call(resource.post, '/user-avatar', data);
        if (response && typeof response === 'object') {
            yield put(setUser(response));
        } else {
            yield put(popupWarning({ h2: 'Upload Failed', message: ' Please make sure you are uploading a png or jpeg.', showImg: true }));
            console.error(response);
        }
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* deleteAvatarRequest() {
    const { avatarUrl } = yield select(userSelector);
    if (avatarUrl) {
        try {
            const response = yield call(resource.delete, '/user-avatar');
            if (response && typeof response === 'object') {
                yield put(
                    popupSuccess({ h2: 'Image Deleted', message: 'Your profile image has been successfully deleted.' })
                );
                yield put(setUser(response));
            } else {
                yield put(
                    popupWarning({ h2: 'Image Not Deleted', message: ' Your profile image has not been deleted.' })
                );
                console.error(response);
            }
        } catch (error) {
            // console.log(error);
            // yield put(setLoad(false));
        }
    }
}

export function* watchAvatar() {
    yield takeLatest(UPDATE_AVATAR, updateAvatarRequest);
    yield takeLatest(DELETE_AVATAR, deleteAvatarRequest);
}

export function* submitSettingsRequest() {
    // yield put(setLoad(true));
    while (true) {
        const {
            payload: {
                values,
                actions: { setSubmitting, resetForm, setFieldError, setFieldValue },
            },
        } = yield take(SUBMIT_SETTINGS);
        setSubmitting(true);
        try {
            const response = yield call(resource.put, '/user', { ...values });

            if (response && typeof response === 'object') {
                yield put(
                    popupSuccess({
                        h2: 'Profile Updated',
                        message: 'Your profile has been successfully updated.',
                        showImg: true,
                    })
                );
                yield put(setUser(response));
                if (
                    !window.location.host.includes('127.0.0.1') &&
                    !window.location.host.includes('localhost') &&
                    !window.location.host.includes('dev')
                ) {
                    posthog.people.set({
                        email: response?.email,
                        lastName: response?.lastName,
                        phoneNumber: response?.phoneNumber,
                        firstName: response?.firstName,
                    });
                }
                // resetForm({ oldPassword: null, password: null, passwordConfirm: null });
                setFieldValue('oldPassword', '');
                setFieldValue('password', '');
                setFieldValue('passwordConfirm', '');
            } else {
                yield put(popupWarning({ h2: 'Insufficient Funds', message: response.message, showImg: true }));
            }
        } catch (err) {
            const error = JSON.parse(err.message);
            yield put(setError(err));
            if (error === 'This phone number already exist') {
                setFieldError('phoneNumber', 'This phone number already exists');
            }
            if (error === 'The email has already been taken') {
                setFieldError('email', 'This email already exists');
            }
            if (error?.error === 'Invalid credentials') {
                setFieldError('oldPassword', 'Password is incorrect');
            }
        } finally {
            setSubmitting(false);
            // yield put(setLoad(false));
            // resetForm();
        }
    }
}

export function* sagas() {
    yield all([getUserRequest(), watchAvatar(), submitSettingsRequest()]);
}

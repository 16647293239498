import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import './container.sass';

const Container = ({ variant, children, className, gutter, ...props }) => {
    const Tag = variant;
    // const classes = ClassNames('Container__wrapper', className);
    const classes = className;
    let margin;
    if (gutter) {
        if (typeof gutter === 'string') {
            margin = { margin: gutter };
        } else {
            margin = Object.keys(gutter).reduce((obj, e) => {
                const prop = e[0].toUpperCase() + e.slice(1);
                obj[`margin${prop}`] = gutter[e];
                return obj;
            }, {});
        }
    }

    const domRef = useRef(null);

    const { refEffect } = props;

    useEffect(() => {
        if (refEffect) {
            refEffect(domRef);
        }
    }, [refEffect]);

    return (
        <Tag ref={domRef} className={classes} style={margin} {...props}>
            {children}
        </Tag>
    );
};

Container.propTypes = {
    variant: PropTypes.string,
};

Container.defaultProps = {
    variant: 'div',
};

export default Container;

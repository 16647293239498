import React from 'react';
import ClassNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import NativeRadio from '../../../native-checkbox/NativeRadio';
import styles from '../../busines-details.module.sass';
import NativeInput from '../../../native-input/NativeInput';

const LegalProduct = (props) => {
    const { values, setFieldValue } = useFormikContext();
    const { editVerificationFlow, type, setType } = props;

    const handleWebsite = (e) => {
        setType(e.target.value);
    };

    const didMount = React.useRef(false);
    React.useEffect(() => {
        if ((values.productDescription || values.website) && !didMount.current) {
            setType(values.productDescription ? 'description' : 'website');
        }
    }, [values.productDescription]);

    React.useEffect(() => {
        if (type) didMount.current = true;
    }, [type]);

    const didMountType = React.useRef(false);
    React.useEffect(() => {
        if (didMountType.current) {
            setFieldValue('productDescription', '');
            setFieldValue('website', '');
        } else if (didMount.current) {
            didMountType.current = true;
        }
    }, [type]);

    return (
        <>
            <div className={styles.descriptionBox}>
                <div className={styles.descriptionTitleBox}>
                    <span className={styles.descriptionTitle}>Legal Product</span>
                    <span className={styles.businessSubtitle}>
                        Add a product description instead should be a link. When selected the Your website section goes
                        away and a paragraph text box opens where host can free type in a box labeled “Product
                        description”.
                    </span>
                </div>
                {!editVerificationFlow && (
                    <div className={styles.descriptionCheckbox}>
                        <Field
                            component={NativeRadio}
                            className={ClassNames(styles.checkboxWebsite)}
                            label="Website"
                            onChange={handleWebsite}
                            value="website"
                            name="productDescription"
                            type={type}
                            editVerificationFlow={editVerificationFlow}
                        />
                        <Field
                            component={NativeRadio}
                            className={ClassNames(styles.checkboxWebsite)}
                            label="Product description"
                            onChange={handleWebsite}
                            value="description"
                            name="productDescription"
                            type={type}
                            editVerificationFlow={editVerificationFlow}
                        />
                    </div>
                )}
                {type === 'website' ? (
                    <Field
                        component={NativeInput}
                        className={ClassNames(styles.checkboxWebsite)}
                        name="website"
                        label="Website"
                        placeholder="https://example.com"
                        data="website"
                        id="0"
                        message
                        editVerificationFlow={editVerificationFlow}
                    />
                ) : null}
                {type === 'description' ? (
                    <Field
                        component={NativeInput}
                        className={ClassNames(styles.checkboxDescription)}
                        name="productDescription"
                        label="Product description"
                        placeholder="Enter your product description"
                        data="productDescription"
                        variant="textarea"
                        id="1"
                        message
                        editVerificationFlow={editVerificationFlow}
                    />
                ) : null}
            </div>
        </>
    );
};

export default LegalProduct;

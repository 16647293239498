import { createAction, handleActions } from 'redux-actions';
import {all, call, put, take} from 'redux-saga/effects';
import { resource } from '../../service/http';

const namespace = 'metaTags';

const GET_META = `${namespace}/GET_META`;
const SET_TITLE = `${namespace}/SET_TITLE`;
const SET_DESCRIPTION = `${namespace}/SET_DESCRIPTION`;
const SET_CANONICAL = `${namespace}/SET_CANONICAL`;
const SET_NOINDEX = `${namespace}/SET_NOINDEX`;
const SET_NOFOLLOW = `${namespace}/SET_NOFOLLOW`;

export const getMeta = createAction(GET_META);
export const setTitle = createAction(SET_TITLE);
export const setDescription = createAction(SET_DESCRIPTION);
export const setCanonical = createAction(SET_CANONICAL);
export const setNoindex = createAction(SET_NOINDEX);
export const setNofollow = createAction(SET_NOFOLLOW);

const initialState = {
    title: "The Office Exchange",
    description: "",
    canonical: "",
    noindex: null,
    nofollow: null,
};

export default handleActions(
    {
        [GET_META]: (state, { payload }) => ({ ...state, ...payload }),
        [SET_TITLE]: (state, { payload }) => ({ ...state, title: payload }),
        [SET_DESCRIPTION]: (state, { payload }) => ({ ...state, description: payload }),
        [SET_CANONICAL]: (state, { payload }) => ({ ...state, canonical: payload }),
        [SET_NOINDEX]: (state, { payload }) => ({ ...state, noindex: payload }),
        [SET_NOFOLLOW]: (state, { payload }) => ({ ...state, nofollow: payload }),
    },
    initialState
);

export const getMetaSelector = (state) => state[namespace];

export function* getMetaRequest() {
    while (true) {
        const {payload: location} = yield take(GET_META);
        try {
            const response = yield call(resource.post, '/getmeta', { url: location });
            yield put(setTitle(response.title));
            yield put(setDescription(response.description));
            yield put(setCanonical(response.canonical));
            yield put(setNoindex(response.noindex));
            yield put(setNofollow(response.nofollow));
        } catch (err) {
            // console.log(err);
        }
    }
}

export function* sagas() {
    yield all([getMetaRequest()]);
}
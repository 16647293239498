import { createAction, handleActions } from 'redux-actions';
import { all, call, put, take } from 'redux-saga/effects';
import {resource} from "../../service/http";

const namespace = 'sitemap';

const GET_SITEMAP_ITEM = `${namespace}/GET_SITEMAP_ITEM`;
const SET_SITEMAP_ITEM = `${namespace}/SET_SITEMAP_ITEM`;

const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_LOAD = `${namespace}/SET_LOAD`;

export const getSitemapItem = createAction(GET_SITEMAP_ITEM);
export const setSitemapItem = createAction(SET_SITEMAP_ITEM);

export const setError = createAction(SET_ERROR);
export const setLoad = createAction(SET_LOAD);

const initialState = {
    error: false,
    load: false,
    sitemap: null,
};

export default handleActions(
    {
        [SET_SITEMAP_ITEM]: (state, { payload }) => ({ ...state, sitemap: payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
    },
    initialState
);

export const sitemapSelector = (state) => state[namespace].sitemap;
export const approveLoader = (state) => state[namespace].load;

export function* getSitemapItemsRequest() {
    while (true) {
        const { payload } = yield take(GET_SITEMAP_ITEM);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, '/sitemaphtml', { ...payload });
            yield put(setSitemapItem(response));
        } catch (err) {
            // console.log(err);
            yield put(setError(err));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* sagas() {
    yield all([
        getSitemapItemsRequest(),
    ]);
}
import React, {useEffect, useRef, useState} from "react";
import styles from './footer.module.sass';
import logo from "../assets/images/Logo.svg"
import ClassNames from "classnames";
import {Field, Form, Formik} from "formik";
import InputControl from "../ui/input-control";
import Button from "../ui/button";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {subscribeAction} from "../../store/reducers/footer";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {EmailShareButton} from "react-share";
import {
    BottomArrowIcon,
    CloseBtn, DotIcon,
    EmailIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon, RightSmallArrow,
    TwitterIcon
} from "../../assets/svg";

const SettingsSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email address'),
});

const Footer  = ({avoidBottomNav}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const formikRef = useRef();
    const date = new Date();
    const year = date.getFullYear();

    useEffect(() => {
        document.documentElement.style.setProperty('--animation-time-footer', '0s');
    }, []);

    const onHandleClose = () => {
        formikRef.current.resetForm()
        setIsOpen(false);
    }

    const toggleShowFullFooter = () => {
        setIsOpen(!isOpen);
        formikRef.current.resetForm()
        document.documentElement.style.setProperty('--animation-time-footer', '0.5s');
    }

    const onHandleSubmit = (values, actions) => {
        dispatch(subscribeAction({ values, actions }));
    };

    const onHandlerLogoClick = () => {
        history.push('/');
        if (location.pathname === '/') {
            setIsOpen(!isOpen);
        }
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.mini_footer}>
                    <div className={styles.mini_footer_text_area}>
                        <span className={styles.span}>
                            © {year} The Office Exchange, Inc.
                        </span>
                        <ul className={styles.mini_footer_ul}>
                            <li className={styles.mini_footer_li}>
                                <DotIcon/>
                                <Link to="/privacy-policy" className={styles.mini_footer_a}>Privacy Policy</Link>
                            </li>
                            <li className={styles.mini_footer_li}>
                                <DotIcon/>
                                <Link to="/terms-and-conditions" className={styles.mini_footer_a}>Terms & Conditions</Link>
                            </li>
                            <li className={styles.mini_footer_li}>
                                <DotIcon/>
                                <Link to="/sitemap/" className={styles.mini_footer_a}>Sitemap</Link>
                            </li>
                            <li className={styles.mini_footer_li}>
                                <DotIcon/>
                                <Link to="/search" className={styles.mini_footer_a}>Search Spaces</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.mini_footer_support} onClick={toggleShowFullFooter}>
                        <p>FAQ & Support</p>
                        <BottomArrowIcon/>
                    </div>
                </div>
            </div>
            <div onClick={onHandleClose} className={ClassNames(styles.big_footer_bg, isOpen ? styles.big_footer_bg_show : styles.big_footer_bg_close)}>
                <div onClick={e => e.stopPropagation()} className={ClassNames(styles.big_footer_bg_white, {[styles.avoidBottomNav]: avoidBottomNav})}>
                    <CloseBtn className={styles.footer_close} onClick={toggleShowFullFooter} />
                    <div className={styles.big_footer}>
                        <img onClick={onHandlerLogoClick} className={styles.logo} src={logo}  alt=""/>
                        <div className={styles.contacts}>
                            <p className={styles.p}>1721 Third Avenue North STE 201 Birmingham, AL 35203</p>
                            <p className={styles.p}>contactus@theofficeexchange.com</p>
                            <p className={styles.p}>310-999-1903</p>
                            <div className={styles.social_media}>
                                <EmailShareButton
                                    onClick={() => window.open('mailto:contactus@theofficeexchange.com')}
                                    className={styles.social_media_link}
                                >
                                    <EmailIcon/>
                                </EmailShareButton>
                                <a href="https://www.facebook.com/The-Office-Exchange-105106185592232/" target="_blank" className={styles.social_media_link}>
                                    <FacebookIcon/>
                                </a>
                                <a href="https://www.instagram.com/officeexchange/" target="_blank" className={styles.social_media_link}>
                                    <InstagramIcon/>
                                </a>
                                <a href="https://twitter.com/officeexchange_" target="_blank" className={styles.social_media_link}>
                                    <TwitterIcon/>
                                </a>
                                <a href="https://www.linkedin.com/company/the-office-exchange-inc/" target="_blank" className={styles.social_media_link}>
                                    <LinkedinIcon/>
                                </a>
                            </div>
                            <p className={styles.p}>© {year} All rights reserved.</p>
                        </div>
                        <div className={styles.company}>
                            <p className={styles.footer_title}>Company</p>
                            <ul>
                                <li className={styles.footer_li}>
                                    <Link to="/our-mission" className={styles.footer_a}>Our Mission</Link>
                                </li>
                                <li className={styles.footer_li}>
                                    <Link to="/contact" className={styles.footer_a}>Contact Us</Link>
                                </li>
                                <li className={styles.footer_li}>
                                    <Link to="/privacy-policy" className={styles.footer_a}>Privacy Policy</Link>
                                </li>
                                <li className={styles.footer_li}>
                                    <Link to="/terms-and-conditions" className={styles.footer_a}>Terms & Conditions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.resources}>
                            <p className={styles.footer_title}>Resources</p>
                            <ul>
                                <li className={styles.footer_li}>
                                    <Link to="/blog" className={styles.footer_a}>Blog</Link>
                                </li>
                                <li className={styles.footer_li}>
                                    <Link to="/faq" className={styles.footer_a}>FAQ</Link>
                                </li>
                                {/*<li className={styles.footer_li}>*/}
                                {/*    <Link to="/" className={styles.footer_a}>In the News</Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        <div className={styles.newsletter}>
                            <p className={styles.newsletter_title}>Sign up for the newsletter</p>
                            <Formik
                                initialValues={{
                                    email: "",
                                }}
                                onSubmit={onHandleSubmit}
                                validationSchema={SettingsSchema}
                                innerRef={formikRef}
                            >
                                {({ isSubmitting, isValid, dirty, status }) => (
                                    <Form className={styles.form}>
                                        <div className={styles.inputArea}>
                                            <Field
                                                className={styles.input}
                                                label="Email"
                                                name="email"
                                                message
                                                type="email"
                                                placeholder="Email"
                                                data_atr="footerEmail"
                                                id="footer_email"
                                                component={InputControl}
                                            />
                                            <Button className={styles.submitButton} disabled={!isValid || isSubmitting || !dirty}>
                                                <RightSmallArrow/>
                                            </Button>
                                        </div>

                                        {status?.sent && <p className={styles.successSubmitting}>{status.sent}</p>}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
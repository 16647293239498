import React, { lazy, useEffect } from 'react';
import './App.sass';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from './service/PrivateRoute';
import StripeWidget from './components/stripe-widget';
import { useScrollUp } from './components/hooks/useScrollUp';
import { useDispatch, useSelector } from 'react-redux';
import Popup from './components/ui/popup/Popup';
import { Helmet } from 'react-helmet';
import { getMeta, getMetaSelector } from './store/reducers/metaTags';
import FormSwitcher from './pages/native-verification/screens';

const HomePage = lazy(() => import('./pages/home'));
const Test = lazy(() => import('./pages/test'));
const SignIn = lazy(() => import('./pages/sign-in'));
const SignUp = lazy(() => import('./pages/sign-up'));
const ForgotPassword = lazy(() => import('./pages/forgot-password'));
const NewPassword = lazy(() => import('./pages/new-password'));
const SearchPage = lazy(() => import('./pages/search-page'));
const PlacePage = lazy(() => import('./pages/place'));
const RequestBooking = lazy(() => import('./pages/request-booking'));
const TermsAndConditions = lazy(() => import('./pages/terms&conditions'));
const Account = lazy(() => import('./pages/account'));
const CreatePlace = lazy(() => import('./pages/create-place'));
const UpdatePlace = lazy(() => import('./pages/update-place'));
const EditChildSpace = lazy(() => import('./pages/update-place/EditChildSpace'));
const Contact = lazy(() => import('./pages/contact'));
const Thanks = lazy(() => import('./pages/thanks'));
const Gallery = lazy(() => import('./pages/gallery'));
const EditRequest = lazy(() => import('./pages/edit-request-booking'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'));
const NativeVerification = lazy(() => import('./pages/native-verification'));
const Sitemap = lazy(() => import('./pages/sitemap'));
const StartListing = lazy(() => import('./pages/start-listing'));
const DuplicateChildSpace = lazy(() => import('./pages/start-listing/DuplicateChildSpace'));
const Duplicate = lazy(() => import('./pages/start-listing/Duplicate'));
const CreateCoworking = lazy(() => import('./pages/create-place/CreateCoworking'));
const Summary = lazy(() => import('./pages/native-verification/summary/Summary'));
const EditVerificationInfoSwitcher = lazy(() => import('./pages/native-verification/edit-verification-info/Switcher'));
const LoadPage = lazy(() => import('./pages/LoadPage'));
const ErrorPage = lazy(() => import('./pages/error-page/ErrorPage'));
const LandingPage = lazy(() => import('./pages/landing'));
const FAQ = lazy(() => import('./pages/faq-page'));
const Blog = lazy(() => import('./pages/blog'));
const Article = lazy(() => import('./pages/blog/article'));
const MissionPage = lazy(() => import('./pages/mission'));

// eslint-disable-next-line react/prop-types
function App({ history }) {
    const location = useSelector(({ router: { location } }) => location);
    const dispatch = useDispatch();

    useScrollUp(location);

    const meta = useSelector(getMetaSelector);

    useEffect(() => {
        dispatch(getMeta(location.pathname));
    }, [location.pathname]);

    return (
        <div className="app">
            <Helmet>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description} />
                <link rel="canonical" href={meta.canonical} />
                {meta.noindex === 1 && <meta name="robots" content="noindex" />}
                {meta.nofollow === 1 && <meta name="robots" content="nofollow" />}
            </Helmet>
            <Popup />
            <ConnectedRouter history={history}>
                <StripeWidget />
                <Switch>
                    <Route exact path="/page-not-found" component={ErrorPage} />
                    <Route exact path="/test" component={Test} />
                    <Route exact path="/thanks" component={Thanks} />
                    <Route exact path="/place/:id/gallery" component={Gallery} />
                    <Route exact path="/place/:id/:kind/:childId" component={PlacePage} />
                    <Route exact path="/place/:id/:kind" component={PlacePage} />
                    <Route exact path="/place/:id" component={PlacePage} />
                    <Route exact path="/preview/:id/gallery" component={Gallery} />
                    <Route exact path="/preview/:id/:kind" component={PlacePage} />
                    <Route exact path="/preview/:id" component={PlacePage} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/request-booking/:step/:id" component={RequestBooking} />
                    <Route exact path="/edit/request-booking/:req/:id" component={EditRequest} />
                    <Route exact path="/search?" component={SearchPage} />
                    <Route exact path="/sitemap" component={Sitemap} />
                    <Route exact path="/search" component={SearchPage} />
                    <Route exact path="/become-a-host" component={LandingPage} />
                    <Route exact path="/our-mission" component={MissionPage} />

                    <Route exact path="/faq" component={FAQ} />

                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/terms-and-conditions">
                        <TermsAndConditions />
                    </Route>
                    <PrivateRoute auth exact host path="/verification">
                        <NativeVerification />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/verification-step">
                        <FormSwitcher />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/summary">
                        <Summary />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/edit-verification-info">
                        <EditVerificationInfoSwitcher />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/list-space/:step">
                        <CreatePlace />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/create-space">
                        <CreatePlace />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/start-listing">
                        <StartListing />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/duplicate-space">
                        <Duplicate />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/duplicate-coworking/:id">
                        <DuplicateChildSpace />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/finish-listing">
                        <StartListing />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/create-coworking/:step">
                        <CreateCoworking />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/create-coworking">
                        <CreateCoworking />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/edit-space/:id">
                        <UpdatePlace />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/load">
                        <LoadPage />
                    </PrivateRoute>
                    <PrivateRoute auth exact host path="/edit-subspace/:id">
                        <EditChildSpace />
                    </PrivateRoute>

                    <PrivateRoute auth path="/account">
                        <Account />
                    </PrivateRoute>
                    <PrivateRoute exact location path="/login">
                        <SignIn />
                    </PrivateRoute>
                    <PrivateRoute exact path="/restore-password">
                        <ForgotPassword />
                    </PrivateRoute>
                    <PrivateRoute exact path="/new-password/:token?">
                        <NewPassword />
                    </PrivateRoute>
                    <PrivateRoute exact path="/registration-guest/:step">
                        <SignUp />
                    </PrivateRoute>
                    <PrivateRoute exact path="/registration-host/:step">
                        <SignUp />
                    </PrivateRoute>
                    <Route exact path="/" component={HomePage} />

                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/blog/:slug" component={Blog} />
                    <Route exact path="/:slug" component={Article} />

                    <Redirect to="/page-not-found" />
                </Switch>
            </ConnectedRouter>
        </div>
    );
}

export default App;

import { ErrorMessage } from 'formik';
import React from 'react';
import Typography from '../typography';
import styles from './input-control.module.sass';

function prepareInput(value, step, separator, length) {
    if (!value) {
        return '';
    }
    const prepareValue = value.replace(/\D/g, '').slice(0, length);
    let valueInArray = prepareValue.split('');
    let placeholder = [];
    let hidden = [];
    step.forEach((stepElement) => {
        if (valueInArray.length > +stepElement) {
            valueInArray.splice(+stepElement, 0, separator[stepElement]);
        }
    });
    placeholder = [...separator.slice(+valueInArray.length)];
    hidden = [...valueInArray];
    return {
        formattedValue: valueInArray.join(''),
        placeholder,
        hidden,
    };
}

export default function MaskInputControl({ onChange, mask, message, variant: Component, ...props }) {
    let attrs = { ...props };
    const erorStyle = attrs.form
        ? attrs.form.errors &&
          attrs.form.errors[attrs.field.name] &&
          attrs.form.touched &&
          attrs.form.touched[attrs.field.name]
        : false;
    if (props.field) {
        attrs = Object.keys(props).reduce((propsObject, propName) => {
            if (propName !== 'field' && propName !== 'form') {
                propsObject = { ...propsObject, [propName]: props[propName] };
                return propsObject;
            }
            return propsObject;
        }, {});
    }

    const maskArray = mask.split('');
    const maskIntersect = maskArray.reduce((acc, e, i) => {
        if (!e.match(/\w/)) {
            acc = [...acc, i];
        }
        return acc;
    }, []);
    const { name, value, onBlur } = props.field ? props.field : props;
    const numbersLength = maskArray.length - maskIntersect.length;
    const onChangeHandler = (e) => {
        const { value } = e.target;
        if (props.form) {
            const { setFieldValue } = props.form;
            setFieldValue(name, value.replace(/\D/g, '').slice(0, numbersLength));
        } else {
            onChange(value.replace(/\D/g, '').slice(0, numbersLength));
        }
    };
    const { formattedValue, placeholder, hidden } = prepareInput(value, maskIntersect, maskArray, numbersLength);

    return (
        <>
            <Component
                value={formattedValue || value}
                onChange={onChangeHandler}
                placeholder={placeholder}
                hidden={hidden}
                mask={mask}
                name={name}
                onBlur={onBlur}
                erorStyle={erorStyle}
                message={message}
                {...attrs}
            />
            <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                {erorStyle && name && <ErrorMessage name={name} />}
            </Typography>
        </>
    );
}

import { createAction, handleActions } from 'redux-actions';
import {all, call, put, take} from 'redux-saga/effects';
import { resource } from '../../service/http';
import {popupSuccess, popupWarning} from "./popupReduser";
import {authWithRegister, setLoading} from "./auth";
import errors from "../../pages/sign-up/twillio_errors.json";
import {signupRoutes} from '../../utils/routing';
import posthog from 'posthog-js';

const namespace = 'singUp';

const CHECK_USER_EMAIL = `${namespace}/CHECK_USER_EMAIL`;
const CHECK_USER_PHONE = `${namespace}/CHECK_USER_PHONE`;
const RESEND_CODE = `${namespace}/RESEND_CODE`;
const SUBMIT_USER = `${namespace}/SUBMIT_USER`;
const SET_ERROR = `${namespace}/SET_ERROR`;

export const checkUserEmail = createAction(CHECK_USER_EMAIL);
export const checkUserPhone = createAction(CHECK_USER_PHONE);
export const resendCode = createAction(RESEND_CODE);
export const submitUser = createAction(SUBMIT_USER);
export const setError = createAction(SET_ERROR);

const initialState = {
    error: null,
};

export default handleActions(
    {
        [SUBMIT_USER]: (state, { payload }) => ({ ...state, ...payload }),
        [CHECK_USER_EMAIL]: (state, { payload }) => ({ ...state, ...payload }),
        [CHECK_USER_PHONE]: (state, { payload }) => ({ ...state, ...payload }),
        [RESEND_CODE]: (state, { payload }) => ({ ...state, ...payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
    },
    initialState
);

// export const singUpErrorSelector = (state) => state[namespace].error;

const SearchErrorMessage = (code) => {
    return errors.find((e) => {
        return e.code === code;
    }).message;
};

export function* handleEmailRequest() {
    while (true) {
        const {
            payload: {
                values,
                actions: {
                    setSubmitting,
                    setTouched,
                    setErrors,
                    setFieldError,
                },
                setStep,
                history, 
                linkRole,
                booking,
                bookingId
            },
        } = yield take(CHECK_USER_EMAIL);
        setSubmitting(true);
        try {
            yield put(setLoading(true));
            const response = yield call(resource.post,'/register/check-email', { ...values });
            if (response){
                if(response.messages?.email ==="The email has already been taken.") {
                    setFieldError('email', 'The email has already been taken');
                } else {
                    if (booking) {
                        history.push(`/request-booking/${signupRoutes[1]}/${bookingId}`)
                        //setStep((s) => s + 1);
                    } else {
                        linkRole ? history.push(`/registration-host/${signupRoutes[1]}`) : history.push(`/registration-guest/${signupRoutes[1]}`);
                    }
                       
                    setTouched({});
                    setSubmitting(false);
                }
            }
        }
        finally {
            // setTouched({});
            setSubmitting(false);
            yield put(setLoading(false));
        }
    }
}

export function* handlePhoneRequest() {
    while (true) {
        const {
            payload: {
                values,
                actions: {
                    setSubmitting,
                    setTouched,
                    setErrors,
                    setFieldError
                },
                setStep,
                history,
                linkRole,
                booking,
                bookingId
            },
        } = yield take(CHECK_USER_PHONE);
        setSubmitting(true);

        try {
            yield put(setLoading(true));
            const response = yield call(resource.post,'/register/phone', { ...values });
            if (response){
                if(response.messages?.phoneNumber ==="The phone number has already been taken.") {
                    setFieldError('phoneNumber', 'The phone number has already been taken');
                } else {
                    if (booking) {
                        history.push(`/request-booking/${signupRoutes[2]}/${bookingId}`)
                        //setStep((s) => s + 1);
                    } else {
                        linkRole ? history.push(`/registration-host/${signupRoutes[2]}`) : history.push(`/registration-guest/${signupRoutes[2]}`);
                    }    
                    setTouched();
                    setTouched({});
                    setSubmitting(false);
                    // const mask = phoneNumber.split('');
                    // const fill = mask.fill('*', 2, mask.length - 2);
                    // setMaskPhone(fill.join(''));
                }
            }
        } finally {
            // setTouched({});
            setSubmitting(false);
            yield put(setLoading(false));
        }
    }
}

export function* handleResendCode() {
    while (true) {
        const {
            payload: {
                values,
                setSubmitting,
                setTouched,
                setErrors,
                setFieldError
            },
        } = yield take(RESEND_CODE);
        setSubmitting(true);
        try {
            yield call(resource.post,'/register/phone', { ...values });
        } catch (err) {
            if (err.code) {
                setErrors('This phone number is invalid. Please try a new number.');
            }
        } finally {
            setSubmitting(false);
            yield put(setLoading(false));
        }
    }
}

export function* submitRegistrationRequest() {
    while (true) {
        const {
            payload: {
                values,
                setSubmitting,
                resetForm,
                setFieldError,
                booking,
                bookingId,
                history
            },
        } = yield take(SUBMIT_USER);
        setSubmitting(true);
        try {
            yield put(setLoading(true));
            const response = yield call(resource.post,'/register', { ...values });
            if (response) {
                resetForm();
                setSubmitting(false);
                yield put(authWithRegister(response));
                if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost') && !window.location.host.includes('dev')) {
                    posthog.identify(response.id, {
                        email: response?.email,
                        lastName: response?.lastName,
                        phoneNumber: response?.phoneNumber,
                        firstName: response?.firstName
                    });
                }
                if(booking) {
                    history.push(`/request-booking/confirm-details/${bookingId}`)
                }
                if (response.role === 'tenant' && !booking) {
                    yield put(
                        popupSuccess({
                            h2: 'Welcome!',
                            message: 'You have successfully finished registration.',
                            showImg: true,
                            mainBtnText: 'Find Spaces to Book',
                            btn_link: '/search',
                            relocateOnClose: false,
                        })
                    );
                } else if (response.role === 'host') {
                    yield put(
                        popupSuccess({
                            h2: 'Welcome!',
                            message: 'You have successfully finished registration.',
                            showImg: true,
                            mainBtnText: 'List my first space',
                            btn_link: '/start-listing',
                            showSecondBtn: true,
                            secondBtnText: 'Go to my dashboard',
                            btn_link2: '/account/spaces',
                            relocateOnClose: false,
                        })
                    );
                }
            } else {
                yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
            }
        } catch (err) {
            if (err.message) {
                const temp = JSON.parse(err.message);

                if (temp.code) {
                    const message = SearchErrorMessage(temp.code);
                    setFieldError('code', message);
                    setSubmitting(false);
                    return;
                }
                setSubmitting(false);
                // console.log(err);
            }
        } finally {
            yield put(setLoading(false));
        }
    }
}

export function* sagas() {
    yield all([
        handleEmailRequest(),
        handlePhoneRequest(),
        handleResendCode(),
        submitRegistrationRequest()
    ]);
}
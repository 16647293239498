import { createAction, handleActions } from 'redux-actions';
import { all, call, put, race, select, take, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import Http, { resource } from '../../service/http';

const namespace = 'search';

const GET_ITEMS = `${namespace}/GET_ITEMS`;

const GET_ACTIVITIES = `${namespace}/GET_ACTIVITIES`;
const GET_CITIES = `${namespace}/GET_CITIES`;
const SET_ACTIVITIES = `${namespace}/SET_ACTIVITIES`;
const SET_CITIES = `${namespace}/SET_CITIES`;
const SET_FEATURED = `${namespace}/SET_FEATURED`;

const GET_PLACES = `${namespace}/GET_PLACES`;
const SET_PLACES = `${namespace}/SET_PLACES`;
const SET_PAGINATION = `${namespace}/SET_PAGINATION`;
const RESET_PLACES = `${namespace}/RESET_PLACES`;

const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_LOAD = `${namespace}/SET_LOAD`;

const GET_SEARCHED_CITIES = `${namespace}/GET_SEARCHED_CITIES`;
const SET_SEARCHED_CITIES = `${namespace}/SET_SEARCHED_CITIES`;
const RESET_SEARCHED_CITIES = `${namespace}/RESET_SEARCHED_CITIES`;

const SET_CITY = `${namespace}/SET_CITY`;

const GET_PLACES_MAP_MOVE = `${namespace}/GET_PLACES_MAP_MOVE`;
const SET_MAP_SEARCH = `${namespace}/SET_MAP_SEARCH`;
const SHOW_SEARCH_MODAL = `${namespace}/SHOW_SEARCH_MODAL`;
const CANCEL_GET_SEARCHED_CITIES = `${namespace}/CANCEL_GET_SEARCHED_CITIES`;
const SET_FILTERS = `${namespace}/SET_FILTERS`;
const PRICE_OPEN = `${namespace}/PRICE_OPEN`;

export const getItems = createAction(GET_ITEMS);

export const getActivities = createAction(GET_ACTIVITIES);
export const getCities = createAction(GET_CITIES);
export const setActivities = createAction(SET_ACTIVITIES);
export const setCities = createAction(SET_CITIES);
export const setFeatured = createAction(SET_FEATURED);

export const getSearchedCities = createAction(GET_SEARCHED_CITIES);
export const setSearchedCities = createAction(SET_SEARCHED_CITIES);
export const resetSearchedCities = createAction(RESET_SEARCHED_CITIES);

export const getPlaces = createAction(GET_PLACES);
export const setPlaces = createAction(SET_PLACES);
export const setPagination = createAction(SET_PAGINATION);
export const resetPlaces = createAction(RESET_PLACES);

export const setCity = createAction(SET_CITY);

export const setError = createAction(SET_ERROR);
export const setLoad = createAction(SET_LOAD);

export const getPlacesMapMove = createAction(GET_PLACES_MAP_MOVE);
export const setMapSearch = createAction(SET_MAP_SEARCH);
export const showSearchModal = createAction(SHOW_SEARCH_MODAL);
export const cancelGetSearhedCities = createAction(CANCEL_GET_SEARCHED_CITIES);
export const setFilters = createAction(SET_FILTERS);
export const setPriceOpen = createAction(PRICE_OPEN);

const initialState = {
    activities: null,
    cities: null,
    places: null,
    pagination: null,
    error: null,
    featured: null,
    load: false,
    searchedCities: null,
    city: null,
    mapSearch: false,
    searchModal: false,
    filters: null,
    priceOpen: false
};

export default handleActions(
    {
        [SET_ACTIVITIES]: (state, { payload }) => ({ ...state, activities: payload }),
        [SET_CITIES]: (state, { payload }) => ({ ...state, cities: payload }),
        [SET_PLACES]: (state, { payload }) => ({ ...state, places: payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_PAGINATION]: (state, { payload }) => ({ ...state, pagination: payload }),
        [RESET_PLACES]: (state, _) => ({
            ...initialState,
            searchedCities: state.searchedCities,
            mapSearch: state.mapSearch,
        }),
        [SET_FEATURED]: (state, { payload }) => ({ ...state, featured: payload }),
        [SET_CITY]: (state, { payload }) => ({ ...state, city: payload }),
        [SET_SEARCHED_CITIES]: (state, { payload }) => ({ ...state, searchedCities: payload }),
        [RESET_SEARCHED_CITIES]: (state, _) => ({ ...state, searchedCities: null }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_MAP_SEARCH]: (state, { payload }) => ({ ...state, mapSearch: payload }),
        [SHOW_SEARCH_MODAL]: (state, { payload }) => ({ ...state, searchModal: payload }),
        [SET_FILTERS]: (state, { payload }) => ({ ...state, filters: payload }),
        [PRICE_OPEN]: (state, { payload }) => ({ ...state, priceOpen: payload })
    },
    initialState
);

export const searchSelector = (state) => state[namespace];
export const activitiesSelector = (state) => state[namespace].activities;
export const citiesSelector = (state) => state[namespace].cities;

export const featuredSelector = (state) => state[namespace].featured;
export const searchLoad = (state) => state[namespace].load;

export const searchedCitiesSelector = (state) => state[namespace].searchedCities;
export const mapSearchSelector = (state) => state[namespace].mapSearch;
export const searchModalSelector = (state) => state[namespace].searchModal;
export const filtersSelctor = (state) => state[namespace].filters;
export const priceOpenSelector = (state) => state[namespace].priceOpen;

function* getActivitiesSaga() {
    const { activities } = yield select(searchSelector);
    if (!activities) {
        const { request } = Http;
        const result = yield call(request, '/activities');
        yield put(setActivities(result));
        return result;
    }
    return activities;
}

function* getCitiesSaga() {
    const { cities } = yield select(searchSelector);
    if (!cities) {
        const { request } = Http;
        return yield call(request, '/cities');
    }
    return cities;
}

// function* getFeaturedSaga() {
//     const { featured } = yield select(searchSelector);
//     if (!featured) {
//         const { request } = Http;
//         return yield call(request, '/home/getData');
//     }
//     return featured;
// }

export function* getCitySaga() {
    while (true) {
        yield take(GET_CITIES);
        try {
            yield put(setLoad(true));
            const [cities] = yield all([getCitiesSaga()]);
            yield put(setCities(cities));
        } catch (err) {
            // console.log(err);
            yield put(setError(err.message));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* getItemsSaga() {
    while (true) {
        yield take(GET_ITEMS);
        try {
            yield put(setLoad(true));
           // const [activities, featured] = yield all([getActivitiesSaga()]); //, getFeaturedSaga()
           // yield put(setActivities(activities));
           // yield put(setFeatured(featured));
        } catch (err) {
            // console.log(err);
            yield put(setError(err.message));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export const getPlacesSaga = function* ({ payload }) {
    try {
        yield put(setLoad(true));
        // const { response } = yield race({
        //     response: call(resource.get, '/spaces', { ...payload }),
        //     cancel: take(RESET_PLACES),
        // });
        const response = yield call(resource.get, '/spaces', { ...payload });
        if (response) {
            const { spaces, pagination, city, filters } = response;
            if (spaces.length) {
                yield put(setPlaces(spaces));
                yield put(setPagination(pagination));
                yield put(setCity(city));
                yield put(setFilters(filters));
            } else {
                yield put(setFilters(filters));
                yield put(setCity(city));
            }
            yield put(setLoad(false));
        }
    } catch (err) {
        // console.log(err, 'ERROR');
        yield put(setLoad(false));
    }
};

export function* getPlacesMapMoveSaga({ payload }) {
    try {
        const { response } = yield race({
            response: call(resource.get, `/spaces?x1=${payload.x1}&x2=${payload.x2}&y1=${payload.y1}&y2=${payload.y2}`),
            cancel: take(RESET_PLACES),
        });
        if (response) {
            const { spaces, pagination, city } = response;
            if (spaces.length) {
                yield put(setPlaces(spaces));
                yield put(setPagination(pagination));
                yield put(setCity(city));
            } else {
                // yield put(popupSuccess('Oops!', 'Looks like there are no available spaces in this location.'));
            }
        }
    } catch (err) {
        // console.log(err, 'ERROR');
    }
}

export function* getSearchedCitiesRequest({payload}) {
    // while (true) {
        try {
            //const { payload } = yield takeEvery(GET_SEARCHED_CITIES);
            if(!payload) {
                yield put(setSearchedCities([]));
                return;
            }
            const response = yield call(resource.get, `/search-cities?s=${payload}`);
            const citiesFirst = response
                .filter((item) => item.title.toLowerCase().startsWith(payload.toLowerCase()))
                .sort((a, b) => a.title.localeCompare(b.title));
            const citiesSecond = response
                .filter((item) => !item.title.toLowerCase().startsWith(payload.toLowerCase()))
                .sort((a, b) => a.title.localeCompare(b.title));
            if(!isNaN(payload)){
                const res = citiesFirst.concat(citiesSecond).map(item => ({...item, title: `${item.title} ${payload}`}));
                yield put(setSearchedCities(res));
            }else{
                yield put(setSearchedCities(citiesFirst.concat(citiesSecond)));
            }
        } catch (err) {
            // console.log(err);
        } finally {
        }
    // }
}

export function* clearSearchedCities() {
    yield delay(200);
    yield put(setSearchedCities(null))
}

export function* sagas() {
    yield all([
        //getItemsSaga(),
        getCitySaga(),
        takeLatest(GET_SEARCHED_CITIES, getSearchedCitiesRequest),
        takeLatest(GET_PLACES, getPlacesSaga),
        //takeEvery(GET_ACTIVITIES, getActivitiesSaga),
        takeEvery(CANCEL_GET_SEARCHED_CITIES, clearSearchedCities)
        // takeLatest(GET_PLACES_MAP_MOVE, getPlacesMapMoveSaga),
    ]);
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessBackUp, businessBackUpSelector } from '../../../../store/reducers/nativeVerification';

const useBackUp = ({ formikRef, structure, initialFieldsData, type }) => {
    const businessBackUp = useSelector(businessBackUpSelector);
    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => dispatch(setBusinessBackUp({ fields: formikRef.current.values }));
    }, []);

    const doseBackUpDataMatchCurrentForm =
        businessBackUp.type === 'company'
            ? businessBackUp.type === type && businessBackUp.structure === structure
            : businessBackUp.type === type;

    const initialValues =
        doseBackUpDataMatchCurrentForm && businessBackUp.fields ? businessBackUp.fields : initialFieldsData;

    return { initialValues };
};

export default useBackUp;

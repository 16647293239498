import React from 'react';
import load from './load.svg';

import styles from './load.module.sass';

export default function Load({ absolute }) {
    return (
        <div className={styles.wrapper} style={absolute && { position: 'absolute', background: 'none' }}>
            <img className={styles.image} src={load} />
        </div>
    );
}

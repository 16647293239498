import React from 'react';
import { Link, NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import ClassNames from 'classnames';

import styles from './header.module.sass';
import logo from '../assets/images/Logo.png';
import Typography from '../ui/typography';

import Image from '../ui/image';
import Authorization from '../../service/Authorization';
import Role from '../../service/Role';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelector } from '../../store/reducers/auth';
import { showSearchModal } from '../../store/reducers/search';
import { userSelector } from '../../store/reducers/user';
import { createImageUrl } from '../../utils/imageUrl';
import { useBlockBodyScroll } from '../../pages/create-place/useBlockBodySrcoll';
import { signupRoutes } from '../../utils/routing';

const Header = () => {
    const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
    useBlockBodyScroll(openMobileMenu);
    const role = useSelector(roleSelector);
    const user = useSelector(userSelector);
    const location = useLocation();
    let accountUrl = '/';
    const { url } = useRouteMatch();
    const accoutnExact = url.split('/')[1] === 'account';
    if (role === 'host') {
        accountUrl = '/account/spaces';
    } else if (role === 'tenant') {
        accountUrl = '/account/requests';
    }

    const openMobileMenuClick = () => {
        setOpenMobileMenu(!openMobileMenu);
    };
    const closeMobileMenuClick = () => {
        setOpenMobileMenu(false);
    };
    const dispatch = useDispatch();
    const findASpace = () => {
        dispatch(showSearchModal(true));
        setOpenMobileMenu(false);
    };
    const isHomePage = location.pathname === '/';

    return (
        <header className={ClassNames({ [styles.home__wrapper]: (location.pathname === '/' || location.pathname === '/faq' || location.pathname.includes('blog'))})}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.content}>
                        <Link to="/" className={styles.header__logo}>
                            <Image src={logo} />
                        </Link>

                        <div className={styles.nav}>
                            <NavLink
                                to="/contact"
                                className={ClassNames(styles.nav__item, styles.nav__item_adaptive)}
                                activeClassName={styles.nav__item_active}
                            >
                                <Typography fontVariant="bold" variant="span">
                                    Contact us
                                </Typography>
                            </NavLink>
                            <NavLink
                                to="/search"
                                className={ClassNames(styles.nav__item, styles.nav__item_adaptive)}
                                activeClassName={styles.nav__item_active}
                            >
                                <Typography fontVariant="bold" variant="span">
                                    Search spaces
                                </Typography>
                            </NavLink>
                            <Authorization>
                                <Link
                                    to={"/become-a-host"}
                                    data_atr="becomeHost"
                                    className={ClassNames(styles.nav__item, styles.host)}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Become a Host
                                    </Typography>
                                </Link>
                                <NavLink
                                    to={`/registration-guest/${signupRoutes[0]}`}
                                    data_atr="sign_up"
                                    // className={ClassNames(styles.nav__item, {[styles.signup]: isHomePage})}
                                    className={styles.nav__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Sign Up
                                    </Typography>
                                </NavLink>
                                <NavLink
                                    to="/login"
                                    data_atr="sign_in"
                                    className={styles.nav__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Sign In
                                    </Typography>
                                </NavLink>
                            </Authorization>
                            <Role auth role="host">
                                <NavLink
                                    to="/start-listing"
                                    activeClassName={styles.nav__item}
                                    className={ClassNames(
                                        styles.nav__item,
                                        styles.nav__item_adaptive,
                                        styles.nav__item__border
                                    )}
                                >
                                    <Typography fontVariant="bold" variant="span" data_atr="listSpace">
                                        List your space
                                    </Typography>
                                </NavLink>
                            </Role>
                            <Authorization auth>
                                <NavLink
                                    to={accountUrl}
                                    className={styles.nav__item}
                                    activeClassName={styles.nav__item_active}
                                    isActive={() => accoutnExact}
                                >
                                    <div className={styles.user__avatar__block}>
                                        {user.avatarUrl && (
                                            <Image
                                                src={createImageUrl(user.avatarUrl, 50)}
                                                className={styles.user__avatar}
                                            />
                                        )}
                                        {!user.avatarUrl && user.firstName && !user.lastName && (
                                            <div className={styles.user__avatar__letter}>
                                                {user.firstName.substring(0, 1)}
                                            </div>
                                        )}
                                        {!user.avatarUrl && user.firstName && user.lastName && (
                                            <div className={styles.user__avatar__letter}>
                                                {user.firstName.substring(0, 1)}
                                                {user.lastName.substring(0, 1)}
                                            </div>
                                        )}
                                    </div>
                                    <Typography fontVariant="bold" variant="span">
                                        My profile
                                    </Typography>
                                </NavLink>
                            </Authorization>
                        </div>

                        <div className={ClassNames(styles.mobile__menu, { [styles.active]: openMobileMenu })}>
                            <div className={styles.menu__top}>
                                <h4 className={styles.menu__title}>Menu</h4>
                                <button
                                    className={styles.burger__menu}
                                    data_atr="burger_menu_close"
                                    onClick={closeMobileMenuClick}
                                >
                                    <div
                                        className={ClassNames(styles.burger__wrapper, {
                                            [styles.open]: openMobileMenu,
                                        })}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>
                            </div>
                            <div className={styles.links__wrapper}>
                                {!role && (
                                    <Authorization>
                                        <Link
                                            onClick={openMobileMenuClick}
                                            data_atr="burger_signup"
                                            to={`/registration-guest/${signupRoutes[0]}`}
                                            className={styles.mobile__item}
                                        >
                                            <Typography fontVariant="bold" variant="span">
                                                Sign Up
                                            </Typography>
                                        </Link>
                                    </Authorization>
                                )}
                                {!role && (
                                    <Authorization>
                                        <Link
                                            onClick={openMobileMenuClick}
                                            data_atr="sign_in"
                                            to="/login"
                                            className={styles.mobile__item}
                                        >
                                            <Typography fontVariant="bold" variant="span">
                                                Sign In
                                            </Typography>
                                        </Link>
                                    </Authorization>
                                )}
                                <Link
                                    onClick={openMobileMenuClick}
                                    data_atr="burger_contact"
                                    to="/contact"
                                    className={ClassNames(styles.mobile__item)}
                                >
                                    Contact us
                                </Link>
                                <Link
                                    to="/blog"
                                    className={styles.mobile__item}
                                    data_atr="burger_blog"
                                >
                                    Blog
                                </Link>
                                <Link
                                    to="/faq"
                                    className={styles.mobile__item}
                                    data_atr="burger_FAQ"
                                >
                                    FAQ
                                </Link>
                                <Link
                                    onClick={openMobileMenuClick}
                                    data_atr="burger_contact"
                                    to="/search"
                                    className={ClassNames(styles.mobile__item)}
                                >
                                    Find a Space
                                </Link>
                                {!role && (
                                    <Authorization>
                                        <Link
                                            onClick={openMobileMenuClick}
                                            data_atr="burger_becomehost"
                                            to={"/become-a-host"}
                                            className={ClassNames(styles.nav__item, styles.host, styles.mobile__host)}
                                        >
                                            <Typography fontVariant="bold" variant="span">
                                                Become a Host
                                            </Typography>
                                        </Link>
                                    </Authorization>
                                )}
                                <Authorization auth>
                                    <Link
                                        onClick={openMobileMenuClick}
                                        data_atr="my_acc"
                                        to={accountUrl}
                                        className={styles.mobile__item}
                                    >
                                        <div className={styles.user__avatar__block}>
                                            {user.avatarUrl && (
                                                <Image
                                                    src={createImageUrl(user.avatarUrl, 50)}
                                                    className={styles.user__avatar}
                                                />
                                            )}
                                            {!user.avatarUrl && user.firstName && !user.lastName && (
                                                <div className={styles.user__avatar__letter}>
                                                    {user.firstName.substring(0, 1)}
                                                </div>
                                            )}
                                            {!user.avatarUrl && user.firstName && user.lastName && (
                                                <div className={styles.user__avatar__letter}>
                                                    {user.firstName.substring(0, 1)}
                                                    {user.lastName.substring(0, 1)}
                                                </div>
                                            )}{' '}
                                        </div>
                                        <Typography fontVariant="bold" variant="span">
                                            My profile
                                        </Typography>
                                    </Link>
                                </Authorization>
                                <Role auth role="host">
                                    <Link
                                        onClick={openMobileMenuClick}
                                        data_atr="listSpaceM"
                                        to="/start-listing"
                                        className={ClassNames(styles.mobile__item)}
                                    >
                                        <Typography fontVariant="bold" variant="span">
                                            List your space
                                        </Typography>
                                    </Link>
                                </Role>
                            </div>
                        </div>
                        {openMobileMenu === false ? (
                            <button
                                className={styles.burger__menu}
                                data_atr="burger_menu_open"
                                onClick={openMobileMenuClick}
                            >
                                <div className={ClassNames(styles.burger__wrapper)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;

import React from 'react';
import InputControl from './';

const PhoneControl = (props) => {
    const { field, form, ...inputProps } = props;

    const { name, value, onBlur } = props.field ? props.field : props;

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        if (props.form) {
            const { setFieldValue } = props.form;
            setFieldValue(name, formatter(value));
        } else {
            props.onChange(formatter(value));
        }
    };
    return (
        <InputControl
            {...inputProps}
            value={prepareInput(value, '-')}
            name={name}
            onChange={onChangeHandler}
            form={props.form}
            onBlur={onBlur}
            data_atr={props.data_atr}
        />
    );
};

export default PhoneControl;

function maskSeparator(array, numberElement, separator) {
    if (array.length > numberElement) {
        array[numberElement - 1] = array[numberElement - 1] + separator;
    }
    return array;
}

function prepareInput(value, separator) {
    if (!value) {
        return '';
    }
    const step = [2, 5, 8];
    const prepareValue = value.replace(/\D/g, '').slice(0, 11);
    let valueInArray = prepareValue.split('');
    if (valueInArray[0] !== '1' && valueInArray.length > 0) {
        valueInArray.unshift('1');
    }
    if (valueInArray[0] !== '+' && valueInArray.length > 0) {
        valueInArray.unshift('+');
    }

    let maskArray;
    step.forEach((stepElement) => {
        maskArray = maskSeparator(valueInArray, stepElement, separator);
    });
    return maskArray.join('');
}

function formatter(value) {
    if (!value) {
        return '';
    }
    // const codeArray = ['3', '8'];
    // let valueInArray = value.split('');
    // if (valueInArray[0] === '0' && valueInArray.length === 1) {
    //     valueInArray = [...codeArray, ...valueInArray];
    // }
    let valueInArray = value.split('');
    const result = valueInArray.join('').replace(/\D/g, '').slice(0, 11);
    return result;
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './checkbox.module.sass';
import {CheckSmallArrow} from "../../../assets/svg";

const Checkbox = ({ className, width, height, checked, onChange, ...props }) => {
    return (
        <label
            className={classnames(styles.wrapper, className, { [styles.checked]: checked })}
            style={{ width, height }}
        >
            <input type="checkbox" checked={checked} onChange={onChange} {...props} />
            <CheckSmallArrow className={styles.custom}/>
        </label>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};
Checkbox.defaultProps = {
    className: '',
    width: '',
    height: '',
    checked: false,
    onChange: () => {},
};

export default Checkbox;

import search from './search';
import homePage from './home-page';
import auth from './auth';
import place from './place';
import user from './user';
import account from './account';
import payment from './payment';
import createPlace from './create-place';
import updatePlace from './update-place';
import home from './home';
import transactions from './transactions';
import dealHistory from './dealHistory';
import alert from '../../components/ui/alert/alertReducer';
import editBooking from './edit-booking';
import popup from './popupReduser';
import sitemap from './sitemap';
import singUp from './singUp';
import metaTags from './metaTags';
import nativeVerification from './nativeVerification';
import bookingRequest from './bookingRequest';

export const reducers = {
    alert,
    dealHistory,
    updatePlace,
    home,
    createPlace,
    payment,
    auth,
    search,
    homePage,
    place,
    user,
    account,
    transactions,
    editBooking,
    popup,
    sitemap,
    singUp,
    metaTags,
    nativeVerification,
    bookingRequest,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Select, { components } from 'react-select';
import './select.sass';
import Typography from '../../typography';
import { connect, ErrorMessage } from 'formik';

const CaretDownIcon = ({ isOpen }) => {
    return (
        <svg
            className={ClassNames('css-6q0nyr-Svg', 'select__icon', { 'select__icon--open': isOpen })}
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
        >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
    );
};

const DropdownIndicator1 = (props) => {
    const {
        selectProps: { menuIsOpen },
    } = props;

    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon isOpen={menuIsOpen} />
        </components.DropdownIndicator>
    );
};

function SelectControl({
    value,
    className,
    location,
    label,
    options,
    placeholder,
    onChange,
    message,
    isSearchable = false,
    ...props
}) {
    useEffect(() => {
        if (props.name === 'state') {
            return;
        }
        if (props.value && props.formik)
            props.formik.setFieldValue(props.name, props.value.value).then(() => props.formik.validateForm());
    }, []);

    const handleChange = (value) => {
        if(props?.formik && props?.field?.name === 'stateLegal') {
            props.formik.setFieldValue(props.field.name, value.value).then(() => props.formik.validateForm());
            return;
        };        
        if (props.formik) props.formik.setFieldValue(props.name, value.value); //if we use category at request-booking page (props.formik --> connect(component) HoC from formik library)
        
        if (props.field && props.form) {
            props.form.setFieldValue(props.field.name, value.value);
        } else {
            onChange(value);
        }
    };

    let erorStyle;
    if (props.editVerificationFlow) {
        erorStyle = props.form.errors[props.field.name];
    } else {
        erorStyle = props.formik
            ? props.formik.errors &&
              props.formik.errors[props.name ?? props.field.name] &&
              props.form.touched[props.field.name] !== undefined
            : false;
    }

    const handleBlur = () => {
        if (props.form) {
            props.form.setFieldTouched(props.field.name);
        } else return null;
    };
    // const hideMenu = {
    //     menu: (defaultStyles) => {
    //         return {
    //             ...defaultStyles,
    //             display: 'none',
    //         };
    //     },
    // };

    return (
        <div className={ClassNames('select__container', className)}>
            <span
                className={ClassNames(
                    props?.value ? 'select__label' : 'select__label',
                    props.error ?? erorStyle ? 'error' : '',
                    props.name ?? props.field.value ? 'filled' : ''
                )}
            >
                {label}
            </span>
            <Select
                {...props}
                components={{ IndicatorSeparator: () => null, DropdownIndicator: DropdownIndicator1 }}
                className={ClassNames(
                    erorStyle ? 'hasError' : 'select',
                    props.name ?? props.field.value ? 'filled' : ''
                )}
                classNamePrefix="select"
                placeholder={placeholder}
                options={options ? options : []}
                value={
                    options
                        ? props.field
                            ? options.find((option) => option.value === props?.field?.value)
                            : options.find((option) => option.value === props?.value?.value)
                        : '' //props.field.name === 'countryLegal' && props?.form?.initialValues?.countryLegal
                }
                closeMenuOnSelect={true}
                isSearchable={isSearchable}
                onChange={handleChange}
                onBlur={
                    props.editVerificationFlow ? () => props.form.validateField(props.field.name) : () => handleBlur()
                }
                // defaultMenuIsOpen={true} //from get DOM option styles and className
            />

            {!props.editVerificationFlow && (
                <Typography className="message" variant="div" fontVariant="subtextMini">
                    {erorStyle && <ErrorMessage name={props.name ? props.name : props.field.name} />}
                </Typography>
            )}

            {props.editVerificationFlow && props?.form?.errors[props.field.name] && (
                <Typography className="message" variant="div" fontVariant="subtextMini">
                    {props?.form?.errors[props.field.name]}
                </Typography>
            )}
        </div>
    );
}

SelectControl.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
};
SelectControl.defaultProps = {
    className: '',
    label: '',
    placeholder: '',
    options: [],
    onChange: () => {},
};

export default connect(SelectControl);

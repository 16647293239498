import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { authorizationSelector, roleSelector } from '../store/reducers/auth';

const PrivateRoute = ({ children, auth, host, props, ...rest }) => {

    const isAuth = useSelector(authorizationSelector);
    const role = useSelector(roleSelector);
    const flag = auth && host ? isAuth && role === "host" : auth ? isAuth :  !isAuth;

    return (
        <Route
            {...rest}
            render={({ location, match, history }) => {

                return flag ? (
                    React.cloneElement(children, { match, history })
                ) : (
                    <Redirect
                        to={{
                            pathname: auth
                                ? rest.redirect
                                : (!rest.location.state ? '/' : rest.redirect) ||
                                  (rest.location.state.data && role==='tenant' ? `request-booking/${rest.location.state.data.id}` : '/') ||
                                  (rest.location.state.from ? '/' : rest.redirect) ||
                                  // (rest.location.state === 'undefined' ? '/' : rest.redirect) ||
                                  '/',
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};
// pathname: auth ? rest.redirect || '/login' : rest.redirect || '/',

PrivateRoute.propTypes = {
    auth: PropTypes.bool,
    children: PropTypes.node,
};
PrivateRoute.defaultProps = {
    auth: false,
    children: null,
};

export default PrivateRoute;

import { createAction, handleActions } from 'redux-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { push } from 'connected-react-router';
import { getUser, resetUser, setUser } from './user';
import { bookingAuthRoutes } from '../../utils/routing';
import posthog from 'posthog-js';
import { getUserCarts } from './bookingRequest';

const namespace = 'auth';

const SET_TOKEN = `${namespace}/SET_TOKEN`;
const SET_AUTHORIZATION = `${namespace}/SET_AUTHORIZATION`;
const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_LOADING = `${namespace}/SET_LOADING`;
const AUTH_SUBMIT = `${namespace}/AUTH_SUBMIT`;
const SET_ROLE = `${namespace}/SET_ROLE`;
const LOGOUT = `${namespace}/LOGOUT`;
const FORGOT_PASSWORD = `${namespace}/FORGOT_PASSWORD`;
const RESTORE_PASSWORD = `${namespace}/RESTORE_PASSWORD`;
const SUCCESS_EMAIL = `${namespace}/SUCCESS_EMAIL`;

const AUTH_WIDTH_REGISTER = `${namespace}/AUTH_WIDTH_REGISTER`;

export const setToken = createAction(SET_TOKEN);
export const setAuthorization = createAction(SET_AUTHORIZATION);
export const setError = createAction(SET_ERROR);
export const setSuccesEmail = createAction(SUCCESS_EMAIL);
export const setLoading = createAction(SET_LOADING);
export const setRole = createAction(SET_ROLE);

export const authSubmit = createAction(AUTH_SUBMIT);
export const logoutAction = createAction(LOGOUT);

export const authWithRegister = createAction(AUTH_WIDTH_REGISTER);
export const forgotPassword = createAction(FORGOT_PASSWORD);
export const restorePassword = createAction(RESTORE_PASSWORD);

const initialState = {
    loading: false,
    error: null,
    token: null,
    authorization: false,
    role: '',
    success: null,
};

export default handleActions(
    {
        [SET_TOKEN]: (state, { payload }) => ({ ...state, token: payload }),
        [SET_AUTHORIZATION]: (state, { payload }) => ({ ...state, authorization: payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
        [SET_ROLE]: (state, { payload }) => ({ ...state, role: payload }),
        [SUCCESS_EMAIL]: (state, { payload }) => ({ ...state, success: payload }),
        [LOGOUT]: () => initialState,
    },
    initialState
);

export const authorizationSelector = (state) => state[namespace].authorization;
export const roleSelector = (state) => state[namespace].role;
export const authErrorSelector = (state) => state[namespace].error;
export const authLoading = (state) => state[namespace].loading;
export const successSelector = (state) => state[namespace].success;

export function* logout() {
    while (true) {
        yield take(LOGOUT);
        yield put(setToken(null));
        yield put(resetUser());
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost') && !window.location.host.includes('dev')){
            posthog.reset();
        }   
    }
}

export function* setTokenToLocalStorage() {
    while (true) {
        const { payload } = yield take(SET_TOKEN);
        localStorage.setItem('token', JSON.stringify({ token: payload }));
    }
}

export function* setData(payload) {
    const userObject = Object.keys(payload).reduce((acc, prop) => {
        if (prop !== 'authToken') {
            acc = { ...acc, [prop]: payload[prop] };
        }
        return acc;
    }, {});
    const { authToken } = payload;
    localStorage.setItem('role', userObject?.role);
    yield put(setToken(authToken));
    yield put(setRole(userObject?.role));
    yield put(setAuthorization(!!authToken));
    yield put(setUser(userObject));
    // yield put(goBack());
}

export function* EmailSendRestorePassword() {
    const url = '/user/restore';

    while (true) {
        const {
            payload: { values, setSubmitting, setFieldError },
        } = yield take(FORGOT_PASSWORD);
        try {
            yield put(setLoading(true));
            const response = yield call(resource.post, url, { email: values.email });

            if (response === 'User not found!') {
                setSubmitting();
                setFieldError('email', 'User not found!');
            }
            if (response.status === 'success') {
                yield put(setSuccesEmail(response.data));
            } else {
                setSubmitting();
            }
        } catch (error) {
            yield put(setError(error));
            setSubmitting();
        } finally {
            yield put(setLoading(false));
        }
    }
}

export function* SendRestorePassword() {
    const url = '/user/new-password-save';

    while (true) {
        const { payload } = yield take(RESTORE_PASSWORD);

        try {
            yield put(setLoading(true));
            const { setSubmitting } = payload;
            const response = yield call(resource.post, url, {
                password: payload.values.password,
                token: payload.token.token,
            });
            yield fork(setData, response);

            if (response.status === 'success') {
                yield put(push('/'));
            } else {
                yield put(setError(response));
                setSubmitting();
            }
        } catch (error) {
            yield put(setError(error));
            // console.log(error);
        } finally {
            //setSubmitting(false);
            yield put(setLoading(false));
        }
    }
}

export function* submit() {
    const url = '/login';
    while (true) {
        const {
            payload: { values, setSubmitting, resetForm, setFieldError, history, booking, bookingId },
        } = yield take(AUTH_SUBMIT);
        try {
            yield put(setLoading(true));
            const response = yield call(resource.post, url, { ...values }); 
            if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost') && !window.location.host.includes('dev')){
                posthog.identify(response.id, {
                    email: response?.email,
                    lastName: response?.lastName,
                    phoneNumber: response?.phoneNumber,
                    firstName: response?.firstName
                });
            }
            if(booking && response.authToken){   
                if(response.role === 'host'){
                    history.replace(`/place/${bookingId}`);
                } else {
                    history.push(`/request-booking/${bookingAuthRoutes[0]}/${bookingId}`);
                }
            }
            yield fork(setData, response);
            yield call(getUserCarts);
            // resetForm();
            if (response.error == 'Invalid credentials') {
                setSubmitting();
                setFieldError('username', 'Email address or password are incorrect');
                setFieldError('password', 'Email address or password are incorrect');
            }
        } catch (error) {
            yield put(setError(error));
            // console.log(error);
        } finally {
            setSubmitting(false);
            yield put(setLoading(false));
        }
    }
}

export function* AuthWithRegisterSaga() {
    while (true) {
        const { payload } = yield take(AUTH_WIDTH_REGISTER);
        yield fork(setData, payload);
    }
}

function* init() {
    const tokens = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    // yield put(getItems());
    if (tokens) {
        const { token } = JSON.parse(tokens);
        yield put(setToken(token));
        yield put(setRole(role));
        yield put(setAuthorization(!!token));
        yield put(getUser());
    }
}

export function* sagas() {
    yield all([
        init(),
        submit(),
        setTokenToLocalStorage(),
        logout(),
        AuthWithRegisterSaga(),
        EmailSendRestorePassword(),
        SendRestorePassword(),
    ]);
}

import { createAction, handleActions } from 'redux-actions';
import { all } from 'redux-saga/effects';
import image_success from '../../../src/components/assets/images/Check.svg';
import image_warning from '../../../src/components/assets/images/Failure.svg';
import image_loading from '../../../src/components/assets/images/nativeVerifLoader.png';

const namespace = 'popup';

const CLOSE_POPUP = `${namespace}/CLOSE_POPUP`;
const OPEN_SUCCESS_POPUP = `${namespace}/OPEN_SUCCESS_POPUP`;
const OPEN_WARNING_POPUP = `${namespace}/OPEN_WARNING_POPUP`;
const OPEN_LOADING_POPUP = `${namespace}/OPEN_LOADING_POPUP`;

const OPEN_DECLINE_POPUP = `${namespace}/OPEN_DECLINE_POPUP`;

const initialState = {
    isOpen: false, // isOpen: false,
    image: '',
    h2: '',
    message: '',
    mainBtnText: 'Done',
    showImg: false,
    showSecondBtn: false,
    secondBtnText: 'Done',
    btn_link: '',
    btn_link2: '',
    declineInfo: null,
    relocateOnClose: true,
    disableClose: false,
    closeOnMainButton: false,
    openBtnLinkOnOverlay: false,
};

export const popupClose = createAction(CLOSE_POPUP);
export const popupSuccess = createAction(
    OPEN_SUCCESS_POPUP,
    ({
        h2,
        message,
        showImg = false,
        mainBtnText = 'Done',
        btn_link = '',
        showSecondBtn = false,
        secondBtnText,
        btn_link2 = '',
        relocateOnClose = true,
        disableClose = false,
        closeOnMainButton = false,
        openBtnLinkOnOverlay = false,
    }) => ({
        ...initialState,
        h2,
        message,
        showImg,
        mainBtnText,
        showSecondBtn,
        secondBtnText,
        isOpen: true,
        image: image_success,
        btn_link,
        btn_link2,
        relocateOnClose,
        disableClose,
        closeOnMainButton,
        openBtnLinkOnOverlay,
    })
);
export const popupWarning = createAction(
    OPEN_WARNING_POPUP,
    ({
        h2,
        message,
        showImg = false,
        mainBtnText = 'Done',
        btn_link,
        relocateOnClose = true,
        disableClose = false,
        closeOnMainButton = false,
        openBtnLinkOnOverlay = false,
    }) => ({
        ...initialState,
        h2,
        mainBtnText,
        btn_link,
        message,
        showImg,
        isOpen: true,
        image: image_warning,
        relocateOnClose,
        disableClose,
        closeOnMainButton,
        openBtnLinkOnOverlay,
    })
);
export const popupLoading = createAction(
    OPEN_LOADING_POPUP,
    ({
        h2,
        message,
        showImg = false,
        mainBtnText = '',
        loading = true,
        relocateOnClose = true,
        disableClose = true,
        closeOnMainButton = false,
        openBtnLinkOnOverlay = false,
    }) => ({
        ...initialState,
        h2,
        mainBtnText,
        message,
        showImg,
        isOpen: true,
        image: image_loading,
        loading,
        relocateOnClose,
        disableClose,
        closeOnMainButton,
        openBtnLinkOnOverlay,
    })
);

export const popupDecline = createAction(
    OPEN_DECLINE_POPUP,
    ({
        h2,
        message,
        showImg = false,
        mainBtnText = 'Done',
        btn_link = '',
        showSecondBtn = false,
        secondBtnText,
        btn_link2 = '',
        declineInfo = null,
        relocateOnClose = true,
        disableClose = false,
        closeOnMainButton = false,
        openBtnLinkOnOverlay = false,
    }) => ({
        ...initialState,
        h2,
        message,
        showImg,
        mainBtnText,
        showSecondBtn,
        secondBtnText,
        isOpen: true,
        image: image_success,
        btn_link,
        btn_link2,
        declineInfo,
        relocateOnClose,
        disableClose,
        closeOnMainButton,
        openBtnLinkOnOverlay,
    })
);

export default handleActions(
    {
        [CLOSE_POPUP]: (state) => ({
            ...state,
            isOpen: false,
            loading: false,
            btn_link: '',
            declineInfo: null,
            ...initialState,
        }),
        [OPEN_SUCCESS_POPUP]: (state, { payload }) => ({ ...state, ...payload }),
        [OPEN_WARNING_POPUP]: (state, { payload }) => ({ ...state, ...payload }),
        [OPEN_LOADING_POPUP]: (state, { payload }) => ({ ...state, ...payload }),
        [OPEN_DECLINE_POPUP]: (state, { payload }) => ({ ...state, ...payload }),
    },
    initialState
);

export function* sagas() {
    yield all([]);
}

export const popupSelector = (state) => state[namespace];

import React, {useEffect, useState, useRef} from 'react';
import styles from "./subspaceWithAddBtn.module.sass"
import Typography from '../../../../../../components/ui/typography';
import logotype from "../../../../assets/Logotype.svg";
import ClassNames from "classnames";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {amenitiesList} from "../../../../../create-place/utils";
import Gallery from "../gallery";
import {useDispatch, useSelector} from "react-redux";
import {
    bookingSelector, setActiveTabCalendar,
    setCart,
    setCheck,
    setDate,
    setTypeOfDate,
    saveCart,
    setIndCalendar
} from "../../../../../../store/reducers/bookingRequest";
import { format } from 'date-fns';
import {useMedia} from "use-media";
import {prettifyPrice} from "../../../../../../utils/helpers/helper";
import {createImageUrl} from "../../../../../../utils/imageUrl";
import ImageLazy from '../../../../../../components/ui/image/ImageLazy';
import { CheckArrowIcon, ShoppingCart, Calendar} from "../../../../../../assets/svg";
import Dropdown from '../../../../../../components/home-search/date-select/CalendarDropdown';
import {
    getDisabledDates,
    getDisabledDatesString,
    getBookedDaysDailyString,
    getBookedMonthlyString,
    convertTo24Format,
    convertToMiliseconds,
} from '../../../../../../utils/helpers/timeHelpers';



export default function SubspaceWithAddBtn({ item, setShowCalendar, role, place, applyDateChanges, highlight }) {
    const [showAbout, setShowAbout] = useState(false);
    const history = useHistory();
    let amenitiesListFiltered = amenitiesList.filter((i) => item[i.name]);
    let otherAmenities = item?.amenities_other?.split(', ');
    const dispatch = useDispatch();
    const {cart, check, typeOfData, data, indCalendarOpen} = useSelector(bookingSelector);
   
    const isMobile = useMedia({ maxWidth: '768px' });
    const params = useParams();
    const ref = useRef();
    const location = useLocation();
    const isPreview = location?.pathname?.includes('preview');
    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const closeCalendar = () => {
        setCalendarOpen(false);
        dispatch(setIndCalendar(false));
        dispatch(setTypeOfDate(null));
        dispatch(
            setDate({
                type: null,
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                number_months: null,
                dateKey: null,
            })
        );
    };

    useEffect(() => {
        if(Number(params?.childId) === item.id) {
            setShowAbout(true);  
            ref?.current && ref.current.scrollIntoView(); 
        }
    }, []);

    useEffect(() => {
        return history.listen(() => {
            setShowAbout(false);
            item.qty = 0;
            item.date = null;
        })
    },[history]);

    const updateLsCart = () => {
        let lsCart = JSON.stringify(cart);
        let lsIndexCart = item.parent_id+'-Cart';
        localStorage.setItem(lsIndexCart, lsCart);
    }

    const updateLsCheck = () => {
        let lsCheck = JSON.stringify(check);
        let lsIndexCheck = item.parent_id+'-Check';
        localStorage.setItem(lsIndexCheck, lsCheck);
    }

    const btnRef = React.useRef();
    const onHandleAdd = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(!typeOfData) {
            if(btnRef?.current){
                btnRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
            }
            setCalendarOpen(!calendarOpen)
            dispatch(setIndCalendar(!calendarOpen));
            return;
        }
        let tempCart = cart;
        let tempCheckId = check;
        let checkdate = data;

        dispatch(setActiveTabCalendar({
            hourly: item.price_per_hour>0,
            daily: item.price_per_day>0,
            monthly: item.price_per_month>0,
        }))

        if(data.type === null){
            setShowCalendar(true);
            let calendarToScrollTop = document.getElementById("calendarToScroll").offsetTop;
            if(!isMobile) {
                window.scrollTo({
                    top: calendarToScrollTop + 600,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            if(isMobile) {
                window.scrollTo({
                    top: calendarToScrollTop + 1600,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            return;
        } else {
            setShowCalendar(false);
        }

        if(tempCheckId.includes(item.id + "-" + checkdate.dateKey)===false){
            item.date = checkdate;
            tempCart[item.id + "-" + checkdate.dateKey]=({...item});
            tempCheckId.push(item.id + "-" + checkdate.dateKey);
            dispatch(setCheck(tempCheckId));
            updateLsCheck();
            dispatch(setCart(tempCart));
            role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
            updateLsCart();
        } else {
            const indexOfId = tempCheckId.indexOf(item.id + "-" + checkdate.dateKey);
            if(indexOfId!==false){
                tempCheckId.splice(indexOfId, 1);
                dispatch(setCheck(tempCheckId));
                updateLsCheck();
                delete tempCart[item.id + "-" + checkdate.dateKey]
                dispatch(setCart(tempCart));
                role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
                updateLsCart();
            }
        }
    }

    const showSubspace = () => {
        if(!showAbout){
            history.push(`/place/${params.id}/${params.kind}/${item.id}`)
            setShowAbout(true);
        }else {
            history.push(`/place/${params.id}/${params.kind}`)
            setShowAbout(false);
        }
    }


    const [dateState, setDateState] = React.useState({
        type: null,
        data: null,
        time: null,
        months: null,
    });

    const dailyBookings = item?.booked?.filter((item) => item.type_of_date === 'daily');
    const monthlyBookings = item?.booked?.filter((item) => item.type_of_date === 'monthly');
    const hourlyBookings = item?.booked?.filter((item) => item.type_of_date === 'hourly');

    const disabledDates = React.useMemo(() => {
        const bookedDaysDailyString = getBookedDaysDailyString(dailyBookings);
        const bookedDaysDaily = bookedDaysDailyString.map(item => new Date(item));
        const bookedMonthlyString = getBookedMonthlyString(monthlyBookings);
        const bookedMonthly = bookedMonthlyString.map(item => new Date(item));
        return [[...getDisabledDates(place?.available), ...bookedDaysDaily, ...bookedMonthly], 
        [...getDisabledDatesString(place?.available), ...bookedDaysDailyString, ...bookedMonthlyString]];
    }, [JSON.stringify(place?.available), JSON.stringify(dailyBookings), JSON.stringify(monthlyBookings)]);
       
    const bookedDates = React.useMemo(() => {
        const bookedDaysDailyString = getBookedDaysDailyString(dailyBookings);
        const bookedDaysDaily = bookedDaysDailyString.map(item => new Date(item));
        const bookedMonthlyString = getBookedMonthlyString(monthlyBookings);
        const bookedMonthly = bookedMonthlyString.map(item => new Date(item));
        return [...bookedDaysDaily, ...bookedMonthly]
    }, [JSON.stringify(dailyBookings), JSON.stringify(monthlyBookings)]);

    const handleClearCalendar = () => {
        dispatch(setTypeOfDate(null));
        setDateState({
            type: null,
            data: null,
            time: null,
            months: null,
        });
        dispatch(
            setDate({
                type: null,
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                number_months: null,
                dateKey: null,
            })
        );
    }

    const disabledTabs = [item.price_per_hour, item.price_per_day, item.price_per_month];
    const length = disabledTabs?.filter(item => item)?.length;
    const tabsNames = ['hourly', 'daily', 'monthly'];
    const tabType = tabsNames[disabledTabs.findIndex(item => item)];
    
    const applyFunc = () => {
        let tempCart = cart;
        let tempCheckId = check;
        let checkdate = data;
        if(tempCheckId.includes(item.id + "-" + checkdate.dateKey)===false){
            item.date = checkdate;
            tempCart[item.id + "-" + checkdate.dateKey]=({...item});
            tempCheckId.push(item.id + "-" + checkdate.dateKey);
            dispatch(setCheck(tempCheckId));
            updateLsCheck();
            dispatch(setCart(tempCart));
            role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
            updateLsCart();
        }
        closeCalendar();
    }

    
   

    return (
        <div className={styles.outerWrapper}>
            {calendarOpen &&
                (isMobile ? (
                    <div className={styles.overlayDate}>
                        <Dropdown
                            setDateState={setDateState}
                            closeHandler={closeCalendar}
                            startState={dateState}
                            className={styles.calendar}
                            disabledDates={disabledDates[0]}
                            available={place.available}
                            disabledDatesString={disabledDates[1]}
                            countOfMonthCalendar={1}
                            applyDateChanges={applyDateChanges}
                            clearStorage={handleClearCalendar}
                            disabledTabs={disabledTabs}
                            length={length}
                            tabTypeSpace={tabType}
                            applyHandler={applyFunc}
                            applyText="Add to cart"
                            bookedDates={bookedDates}
                            hourlyBookings={hourlyBookings}
                        >
                            {/* <PriceBlock place={item} disabledDatesString={disabledDatesString} date={data} /> */}
                        </Dropdown>
                    </div>
                ) : (
                    <Dropdown
                        setDateState={setDateState}
                        closeHandler={closeCalendar}
                        startState={dateState}
                        className={styles.calendar}
                        disabledDates={disabledDates[0]}
                        available={place.available}
                        disabledDatesString={disabledDates[1]}
                        countOfMonthCalendar={1}
                        applyDateChanges={applyDateChanges}
                        clearStorage={handleClearCalendar}
                        disabledTabs={disabledTabs}
                        length={length}
                        tabTypeSpace={tabType}
                        applyHandler={applyFunc}
                        applyText="Add to cart"
                        bookedDates={bookedDates}
                        hourlyBookings={hourlyBookings}
                    >
                        {/* <PriceBlock place={item} disabledDatesString={disabledDatesString} date={data} /> */}
                    </Dropdown>
                ))}
            <div
                onClick={() => {
                    history.replace(location.pathname);
                    history.push(`/place/${item.id}`);
                }}
                className={ClassNames([styles.container, { [styles.container_highlighted]: highlight }])}
            >
                <div className={styles.top__title} ref={ref}>
                    <div className={styles.name__logo}>
                        <ImageLazy
                            placeholderWidth={600}
                            placeholderHeight="180"
                            borderRadius={16}
                            className={styles.logo}
                            src={!isPreview ? createImageUrl(item.coverUrl !== '0' ? item.coverUrl : logotype, 500) : (item.coverUrl || logotype)}
                            alt="cover url"
                        />
                    </div>
                    <Typography variant="span" className={styles.name}>
                        {item.name}
                    </Typography>
                    {/* <img src={dropdown} alt="dropdown" className={ClassNames(styles.dropdown, { [styles.dropdown__rotate]: showAbout })}/> */}
                </div>
                {/* <div className={ClassNames(styles.about, {[styles.about__show]: showAbout})}>
                    <Gallery gallery={item.gallery} id={item.id}/>
                    <Typography variant="span" className={styles.about__title}>About</Typography>
                    <Typography variant="span" className={styles.about__text}>{item.about}</Typography>
                    <div className={styles.occupancy__square__area}>
                        <OccupancyAndSquare occupancy={item.occupancy} square={item.square}/>
                    </div>
                    <div>
                        <Typography variant="span" className={styles.amenities__title}>Amenities</Typography>
                        <div className={styles.amenity__area}>
                            {amenitiesListFiltered?.map((amenity, index) => (
                                <div className={styles.amenity} key={index}>
                                    {amenity?.img}
                                    <p>{amenity?.title}</p>
                                </div>
                            ))}
                            {item.amenities_other !== "" &&
                                otherAmenities?.map((am, i) => (
                                <div className={styles.amenity} key={i}>
                                    <AsteriskIcon/>
                                    <p className={styles.other__amenities__text}>{am}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <div className={styles.bottom__side}>
                    <div className={styles.price__quantity}>
                        {typeOfData === null && item.price_per_hour ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_hour / 100)}/ hour
                            </Typography>
                        ) : typeOfData == null && item.price_per_day ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_day / 100)}/ day
                            </Typography>
                        ) : typeOfData == null && item.price_per_month ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_month / 100)}/ month
                            </Typography>
                        ) : (
                            ''
                        )}
                        {typeOfData === 'daily' && item.price_per_day ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_day / 100)}/ day
                            </Typography>
                        ) : (
                            ''
                        )}

                        {typeOfData === 'hourly' && item.price_per_hour ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_hour / 100)}/ hour
                            </Typography>
                        ) : (
                            ''
                        )}

                        {typeOfData === 'monthly' && item.price_per_month ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_month / 100)}/ month
                            </Typography>
                        ) : (
                            ''
                        )}
                    </div>
                    {role !== 'host' && (
                        <div
                            ref={btnRef}
                            onClick={onHandleAdd}
                            className={ClassNames(styles.add__btn, {
                                [styles.add__btn__active]: check.includes(item.id + '-' + data.dateKey),
                            })}
                        >
                            {/* {check.includes(item.id+'-'+data.dateKey) ?
                            <CheckArrowIcon className={styles.plus} stroke="white"/>:
                            <BigPlusIcon className={styles.plus}/>} */}
                            {/* <ShoppingCart/> */}
                            {/* {check.includes(item.id+'-'+data.dateKey) ? "Added" : "Add"} */}
                            {!data?.type || indCalendarOpen ? (
                                <>
                                    <Calendar className={styles.calendarIcon} />
                                    Select date
                                </>
                            ) : (
                                <>
                                    {check.includes(item.id + '-' + data.dateKey) ? (
                                        <>
                                            <CheckArrowIcon className={styles.plus} stroke="white" />
                                            Added
                                        </>
                                    ) : (
                                        <>
                                            <ShoppingCart className={styles.calendarIcon} /> Add
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}







import { Field } from 'formik';
import React from 'react';
import NativeInput from '../../../native-input/NativeInput';
import styles from '../../busines-details.module.sass';
import ClassNames from 'classnames';
import { FloatControl, SelectControl } from '../../../../../components/ui/input-control';
import { STATES } from '../../../../../utils/states';

const HomeAddress = (props) => {
    const { editVerificationFlow } = props;


    const kindOfCountry = [
        {
            value: 'USA',
            label: 'USA',
        },
    ];
    return (
        <>
            <span className={styles.businessTitle}>Home address</span>
            <div>
                <div className={styles.addressInputBox}>
                    <div className={styles.addressInputWrap}>
                        <Field
                            className={ClassNames(styles.addressInput)}
                            label="Country"
                            name="countryPerson"
                            message
                            placeholder="USA"
                            data_attr="countryVerifyStep"
                            component={SelectControl}
                            // value={kindOfCountry[0].value}
                            options={kindOfCountry}
                            editVerificationFlow={editVerificationFlow}
                        />

                        <Field
                            className={ClassNames(styles.addressInput)}
                            component={SelectControl}
                            placeholder="Select your state"
                            label="State"
                            name="statePerson"
                            options={STATES}
                            message
                            data_attr="stateVerifyStep"
                            editVerificationFlow={editVerificationFlow}
                            isSearchable
                        />
                    </div>
                    <div className={styles.addressInputWrap}>
                        <Field
                            className={ClassNames(styles.addressInput)}
                            label="City"
                            name="cityPerson"
                            message
                            placeholder="Enter your city"
                            data_attr="cityVerifyStep"
                            component={NativeInput}
                            editVerificationFlow={editVerificationFlow}
                        />
                        <Field
                            className={ClassNames(styles.addressInputZip)}
                            label="Zip code"
                            name="zipCodePerson"
                            message
                            placeholder="Enter zip code"
                            data_attr="zipCodeVerifyStep"
                            component={NativeInput}
                            maxlength="9"
                            editVerificationFlow={editVerificationFlow}
                            decimalSeparator={false}
                        />
                    </div>
                    <Field
                        className={ClassNames(styles.verifyInput)}
                        label="Address line 1"
                        name="addressPerson1"
                        message
                        placeholder="Enter address line 1"
                        data_attr="address1VerifyStep"
                        component={NativeInput}
                        editVerificationFlow={editVerificationFlow}
                    />
                    <Field
                        className={ClassNames(styles.verifyInput)}
                        label="Address line 2"
                        name="addressPerson2"
                        message
                        placeholder="Enter address line 2"
                        data_attr="address2VerifyStep"
                        component={NativeInput}
                        editVerificationFlow={editVerificationFlow}
                    />
                </div>
            </div>
        </>
    );
};

export default HomeAddress;

import React from 'react';
import { useSelector } from 'react-redux';
import { roleSelector } from '../store/reducers/auth';
import Authorization from './Authorization';

export default function Role({ role, ...props }) {
    const userRole = useSelector(roleSelector);
    if (role !== userRole) return null;
    return <Authorization {...props} auth/>;
}

import { all } from 'redux-saga/effects';
import { sagas as search } from '../reducers/search';
import { sagas as home } from '../reducers/home-page';
import { sagas as auth } from '../reducers/auth';
import { sagas as place } from '../reducers/place';
import { sagas as user } from '../reducers/user';
import { sagas as account } from '../reducers/account';
import { sagas as payment } from '../reducers/payment';
import { sagas as createPlace } from '../reducers/create-place';
import { sagas as updatePlace } from '../reducers/update-place';
import { sagas as homeSagas } from '../reducers/home';
import { sagas as transactionsSagas } from '../reducers/transactions';
import { sagas as dealHistorySagas } from '../reducers/dealHistory';
import { sagas as editBooking } from '../reducers/edit-booking';
import { sagas as nativeVerification } from '../reducers/nativeVerification';
import { sagas as popup } from '../reducers/popupReduser';
import { sagas as footer } from '../reducers/footer';
import { sagas as sitemap } from '../reducers/sitemap';
import { sagas as singUp } from '../reducers/singUp';
import { sagas as metaTags } from '../reducers/metaTags';
import { sagas as bookingRequest } from '../reducers/bookingRequest';

export default function* sagas() {
    yield all([
        search(),
        home(),
        auth(),
        place(),
        user(),
        account(),
        payment(),
        createPlace(),
        homeSagas(),
        updatePlace(),
        transactionsSagas(),
        dealHistorySagas(),
        editBooking(),
        popup(),
        nativeVerification(),
        footer(),
        sitemap(),
        singUp(),
        metaTags(),
        bookingRequest(),
    ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styles from './typography.module.sass';

const Typography = ({ variant, children, fontVariant, className, indented, component: Component, ...rest }) => {
    const Tag = Component ? Component : variant;
    const classes = ClassNames(
        {
            [styles.root]: fontVariant === 'main',
            [styles.heavy]: fontVariant === 'heavy',
            [styles.subtext]: fontVariant === 'subtext',
            [styles.subtext__mini]: fontVariant === 'subtextMini',
            [styles.semiBold]: fontVariant === 'bold',
            [styles.light]: fontVariant === 'light',
            [styles.title]: fontVariant === 'title',
            [styles.indented]: indented
        },
        className
    );

    return (
        <Tag className={classes} style={rest.font ? { fontFamily: rest.font } : null} {...rest}>
            {children}
        </Tag>
    );
};
Typography.propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    fontVariant: PropTypes.string,
    /** The component used for the root node. <br>
     * Either a string to use a DOM element or a component. <br>
     * */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};
Typography.defaultProps = {
    variant: 'p',
    children: null,
    className: null,
    fontVariant: 'main',
};
export default Typography;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './checkbox.module.sass';

function Switcher({ label, className, width, height, checked, data, onChange, ...props }) {
    const htmlFor = props.id ? props.id : `checkbox-${Math.random()}`;
    return (
        <>
            <input
                className={styles.switcher}
                type="checkbox"
                id={htmlFor}
                checked={checked}
                onChange={onChange}
                {...props}
                data={data}
            />
            <label className={classnames(styles.switcher__label, className)} htmlFor={htmlFor}>
                {label}
            </label>
        </>
    );
}

Switcher.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};
Switcher.defaultProps = {
    label: '',
    className: '',
    width: '',
    height: '',
    checked: false,
    data: '',
    onChange: () => {},
};

export default Switcher;

import React from 'react';
import { popupSelector, popupClose, popupLoading } from '../../../store/reducers/popupReduser';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../button';
import styles from './popup.module.sass';
import close from '../../assets/images/Close.svg';
import ClassNames from 'classnames';
import { push } from 'connected-react-router';
import { cancelSubscription } from '../../../store/reducers/bookingRequest';

const Popup = () => {
    const popup = useSelector(popupSelector);
    const dispatch = useDispatch();

    function handleClose(link) {
        if (link?.startsWith('/') && popup.relocateOnClose) {
            dispatch(push(link));
        }
        dispatch(popupClose());
    }

    function handleSubmit(link) {
        dispatch(push(link));
        dispatch(popupClose());
    }

    function submit() {
        if (popup?.declineInfo) {
            dispatch(cancelSubscription({ booking: popup?.declineInfo[0], space: popup?.declineInfo[1] }));
        }
        dispatch(popupClose());
    }

    if (!popup.isOpen) {
        return null;
    }

    let mainBtnHandle = () => handleSubmit(popup.btn_link);
    if (popup?.declineInfo) {
        mainBtnHandle = submit;
    }
    if (popup.closeOnMainButton) {
        mainBtnHandle = () => dispatch(popupClose());
    }

    return (
        <div
            className={styles.area}
            onClick={popup.disableClose ? null : () => handleClose(popup.openBtnLinkOnOverlay ? popup.btn_link : null)}
        >
            <div className={styles.box} onClick={(e) => e.stopPropagation()}>
                {!popup.disableClose && (
                    <img className={styles.close} src={close} alt="" onClick={() => handleClose(popup.btn_link)} />
                )}
                {popup.showImg ? <img className={styles.image} src={popup.image} alt="" /> : ''}
                {popup.loading ? <img className={styles.imageSpin} src={popup.image} alt="" /> : ''}
                <h2 className={styles.title}>{popup.h2}</h2>
                <p className={styles.text}>{popup.message}</p>
                <div className={styles.btn_area}>
                    {popup.showSecondBtn && (
                        <Button
                            className={ClassNames(styles.btn, styles.btn_transparent)}
                            onClick={() => handleSubmit(popup.btn_link2)}
                        >
                            {popup.secondBtnText}
                        </Button>
                    )}

                    {!popup.loading && (
                        <Button className={styles.btn} onClick={mainBtnHandle}>
                            {popup.mainBtnText}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Popup;

import React from 'react';
import styles from './native-checkbox.module.sass';
import ClassNames from 'classnames';

const NativeRadio = ({ name, value, onChange, label, type, ...props }) => {
    return (
        <label className={ClassNames(styles.radioWrapper, { [styles.labelChecked]: type === value })}>
            <input
                className={styles.radioInput}
                type="radio"
                name={props.field.name}
                value={value}
                onChange={onChange}
            />
            <span className={ClassNames(styles.radioCheck, { [styles.radioChecked]: type === value })}></span>
            {label}
        </label>
    );
};
export default NativeRadio;

import React, { useEffect, useRef, useState } from 'react';
import styles from './subspaceWithCounter.module.sass';
import Container from '../../../../../../components/ui/container';
import Typography from '../../../../../../components/ui/typography';
import dropdown from '../../../../assets/Dropdown.svg';
import minus from '../../../../assets/Minus.svg';
import plus from '../../../../assets/Plus.svg';
import ClassNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import Gallery from '../gallery';
import { useDispatch, useSelector } from 'react-redux';
import {
    bookingSelector,
    setActiveTabCalendar,
    setCart,
    setCheck,
    setDate,
    setTypeOfDate,
    saveCart,
    setIndCalendar
} from '../../../../../../store/reducers/bookingRequest';
import logotype from '../../../../assets/Logotype.svg';
import { useMedia } from 'use-media';
import { prettifyPrice } from '../../../../../../utils/helpers/helper';
import { createImageUrl } from '../../../../../../utils/imageUrl';
import ImageLazy from '../../../../../../components/ui/image/ImageLazy';
import {BigPlusIcon, Calendar, ShoppingCart} from "../../../../../../assets/svg";
import {convertTo24Format, getDatesInRange, blockTime, getDisabledDates, getDisabledDatesString, getCheckDate} from '../../../../../../utils/helpers/timeHelpers';
import { countMinusMonthlyDesk, countMinusDailyDesk, countMinusHourlyDesk  } from './countQuantityHelpers';
import { Link } from 'react-router-dom';
import Dropdown from '../../../../../../components/home-search/date-select/CalendarDropdown';
import { ca } from 'date-fns/locale';
import PriceBlock from '../PriceBlockCalendar';
import { useLocation } from 'react-router-dom';


export default function SubspaceWithCounter({ item, setShowCalendar, type, parentCoverUrl, role, place, applyDateChanges, highlight }) {
    const [showAbout, setShowAbout] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { cart, check, typeOfData, data, indCalendarOpen } = useSelector(bookingSelector);
    let tempCart = cart;
    let tempCheckId = check;
    let checkdate = data;
    const isMobile = useMedia({ maxWidth: '768px' });
    const params = useParams();
    const ref = useRef();
    const location = useLocation();
    const isPreview = location.pathname.includes('preview');


    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const closeCalendar = () => {
        setCalendarOpen(false);
        dispatch(setIndCalendar(false));
        dispatch(setTypeOfDate(null));
        dispatch(
            setDate({
                type: null,
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                number_months: null,
                dateKey: null,
            })
        );
    };

    useEffect(() => {
        if (Number(params?.childId) === item.id) {
            if(item?.kind !== 'membership passes'){
                setShowAbout(true);
            }   
            ref?.current && ref.current.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        return history.listen(() => {
            setShowAbout(false);
            item.qty = 0;
            item.date = null;
        });
    }, [history]);

    useEffect(() => {
        if (tempCart[item.id + '-' + checkdate.dateKey]) {
            item = tempCart[item.id + '-' + checkdate.dateKey];
        }
    }, [checkdate]);

    const updateLsCart = () => {
        let lsCart = JSON.stringify(cart);
        let lsIndexCart = item.parent_id + '-Cart';
        localStorage.setItem(lsIndexCart, lsCart);
    };

    const updateLsCheck = () => {
        let lsCheck = JSON.stringify(check);
        let lsIndexCheck = item.parent_id + '-Check';
        localStorage.setItem(lsIndexCheck, lsCheck);
    };

    const hourlyBookings = item?.booked?.filter((item) => item.type_of_date === 'hourly');
    const dailyBookings = item?.booked?.filter((item) => item.type_of_date === 'daily');
    const monthlyBookings = item?.booked?.filter((item) => item.type_of_date === 'monthly');
    const minusMonthlyQtyPasses = monthlyBookings.reduce((acc, item) => acc + item.qty, 0);
    let minusDailyQtyPasses = 0;

    if (data.type === 'daily') {
        const chosenDates = getDatesInRange(data?.startDate, data?.endDate);
        for (let booking of dailyBookings) {
            const start = new Date(booking.date_details.split('-')[0]).getTime();
            const end = new Date(booking.date_details.split('-')[1]).getTime();
            const inRange = chosenDates.some((item) => new Date(item) >= start && new Date(item) <= end);
            if (inRange) {
                minusDailyQtyPasses += booking.qty;
            }
        }
    }
    const availableDailyPasses = item.available_day_quantity - minusDailyQtyPasses;
    const availableMonthlyPasses = item.available_month_quantity - minusMonthlyQtyPasses;

    const minusMonthlyDesk = countMinusMonthlyDesk(data, monthlyBookings, dailyBookings, hourlyBookings);
    const minusDailyDesk = countMinusDailyDesk(data, monthlyBookings, dailyBookings, hourlyBookings);
    const minusHourlyDesk = countMinusHourlyDesk(data, monthlyBookings, dailyBookings, hourlyBookings);

    let availableHotDeskQty = item.available_day_quantity;
    if (data.type === 'hourly') {
        availableHotDeskQty -= minusHourlyDesk;
    }
    if (data.type === 'daily') {
        availableHotDeskQty -= minusDailyDesk;
    }
    if (data.type === 'monthly') {
        availableHotDeskQty -= minusMonthlyDesk;
    }
 
    const onHandlePlus = (e) => {
        e.stopPropagation();
        e.preventDefault();
        item = tempCart[item.id + '-' + checkdate.dateKey];
        if (typeOfData === 'monthly' && type === 'membership passes' && item.qty === availableMonthlyPasses) {
            item.qty = availableMonthlyPasses;
            item.date = checkdate;
        }else if (typeOfData !== 'monthly' && type === 'membership passes' && item.qty === availableDailyPasses) {
            item.qty = availableDailyPasses;
            item.date = checkdate;
        } else if (type === 'hot desk' && item.qty === availableHotDeskQty) {
            item.qty = availableHotDeskQty;
            item.date = checkdate;
        } else {
            item = tempCart[item.id + '-' + checkdate.dateKey];
            item.qty += 1;
            item.date = checkdate;
            tempCart[item.id + '-' + checkdate.dateKey] = { ...item };
            dispatch(setCart(tempCart));
            role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
            updateLsCart();
        }
    };

    const onHandleMinus = (e) => {
        e.stopPropagation();
        e.preventDefault();
        item = tempCart[item.id + '-' + checkdate.dateKey];
        if (item.qty === 1) {
            const indexOfId = tempCheckId.indexOf(item.id + '-' + checkdate.dateKey);
            if (indexOfId !== false) {
                tempCheckId.splice(indexOfId, 1);
                dispatch(setCheck(tempCheckId));
                updateLsCheck();
                delete tempCart[item.id + '-' + checkdate.dateKey];
                dispatch(setCart(tempCart));
                role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
                updateLsCart();
            }
        } else {
            item.qty -= 1;
            tempCart[item.id + '-' + checkdate.dateKey] = { ...item };
            dispatch(setCart(tempCart));
            role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
            updateLsCart();
        }
    };

    const onHandleAdd = (e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(setActiveTabCalendar({
            hourly: item.price_per_hour>0,
            daily: item.price_per_day>0,
            monthly: item.price_per_month>0,
        }))

        if (data.type === null) {
            setShowCalendar(true);

            let calendarToScrollTop = document.getElementById('calendarToScroll').offsetTop;
            if (!isMobile) {
                window.scrollTo({
                    top: calendarToScrollTop + 600,
                    left: 0,
                    behavior: 'smooth',
                });
            }
            if (isMobile) {
                window.scrollTo({
                    top: calendarToScrollTop + 1600,
                    left: 0,
                    behavior: 'smooth',
                });
            }
            return;
        } else {
            setShowCalendar(false);
        }

        if (tempCheckId.includes(item.id + '-' + checkdate.dateKey) === false) {
            item.qty = 1;
            item.date = checkdate;
            tempCart[item.id + '-' + checkdate.dateKey] = { ...item };
            tempCheckId.push(item.id + '-' + checkdate.dateKey);
            dispatch(setCheck(tempCheckId));
            updateLsCheck();
            dispatch(setCart(tempCart));
            role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
            updateLsCart();
        }
    };

    const showSubspace = () => {
        if (item.about) {
            if (!showAbout) {
                history.push(`/place/${params.id}/${params.kind}/${item.id}`);
                setShowAbout(true);
            } else {
                history.push(`/place/${params.id}/${params.kind}`);
                setShowAbout(false);
            }
        }
    };

    const btnRef = React.useRef();
    const openCalendar = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(btnRef?.current){
            btnRef.current.scrollIntoView({
                behavior: 'smooth',
            })
        }
        setCalendarOpen(!calendarOpen);
        dispatch(setIndCalendar(!calendarOpen));
    };

    const [qty, setQty] = React.useState(1);

    const [dateState, setDateState] = React.useState({
        type: null,
        data: null,
        time: null,
        months: null,
    });

    const disabledDates = React.useMemo(() => {
        return getDisabledDates(place?.available);
    }, [place?.available]);
    const disabledDatesString = React.useMemo(() => {
        return getDisabledDatesString(place?.available);
    }, [place?.available]);

    const handleClearCalendar = () => {
        dispatch(setTypeOfDate(null));
        setDateState({
            type: null,
            data: null,
            time: null,
            months: null,
        });
        dispatch(
            setDate({
                type: null,
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                number_months: null,
                dateKey: null,
            })
        );
    }
    const disabledTabs = [item.price_per_hour, item.price_per_day, item.price_per_month];
    const length = disabledTabs?.filter(item => item)?.length;
    const tabsNames = ['hourly', 'daily', 'monthly'];
    const tabType = tabsNames[disabledTabs.findIndex(item => item)];

    const applyFunc = () => {
        if (tempCheckId.includes(item.id + '-' + checkdate.dateKey) === false) {
            item.qty = qty;
            item.date = checkdate;
            tempCart[item.id + '-' + checkdate.dateKey] = { ...item };
            tempCheckId.push(item.id + '-' + checkdate.dateKey);
            dispatch(setCheck(tempCheckId));
            updateLsCheck();
            dispatch(setCart(tempCart));
            role === 'tenant' && dispatch(saveCart({key: `${item?.parent_id}-Cart`, cart: tempCart}));
            updateLsCart();
        }
        closeCalendar();
    }

    return (
        <div className={styles.outerWrapper}>
            {calendarOpen &&
                (isMobile ? (
                    <div className={styles.overlayDate}>
                        <Dropdown
                            setDateState={setDateState}
                            startState={dateState}
                            closeHandler={closeCalendar}
                            className={styles.calendar}
                            disabledDates={disabledDates}
                            available={place.available}
                            disabledDatesString={disabledDatesString}
                            countOfMonthCalendar={2}
                            clearStorage={handleClearCalendar}
                            disabledTabs={disabledTabs}
                            length={length}
                            tabTypeSpace={tabType}
                            applyDateChanges={applyDateChanges}
                            applyHandler={applyFunc}
                            applyText="Add to cart"
                            qtyInput={
                                <PriceBlock
                                    withCounter
                                    qty={qty}
                                    setQty={setQty}
                                    typeOfData={typeOfData}
                                    type={type}
                                    availableDailyPasses={availableDailyPasses}
                                    availableMonthlyPasses={availableMonthlyPasses}
                                    availableHotDeskQty={availableHotDeskQty}
                                    place={item}
                                    disabledDatesString={disabledDatesString}
                                    date={data}
                                />
                            }
                        />
                    </div>
                ) : (
                    <Dropdown
                        setDateState={setDateState}
                        startState={dateState}
                        closeHandler={closeCalendar}
                        className={styles.calendar}
                        disabledDates={disabledDates}
                        available={place.available}
                        disabledDatesString={disabledDatesString}
                        countOfMonthCalendar={2}
                        clearStorage={handleClearCalendar}
                        disabledTabs={disabledTabs}
                        length={length}
                        tabTypeSpace={tabType}
                        applyDateChanges={applyDateChanges}
                        applyHandler={applyFunc}
                        applyText="Add to cart"
                        qtyInput={
                            <PriceBlock
                                withCounter
                                qty={qty}
                                setQty={setQty}
                                typeOfData={typeOfData}
                                type={type}
                                availableDailyPasses={availableDailyPasses}
                                availableMonthlyPasses={availableMonthlyPasses}
                                availableHotDeskQty={availableHotDeskQty}
                                place={item}
                                disabledDatesString={disabledDatesString}
                                date={data}
                            />
                        }
                    />
                ))}
            <div
                className={ClassNames([styles.container, { [styles.container_highlighted]: highlight }])}
                onClick={() => {
                    history.replace(location.pathname);
                    history.push(`/place/${item.id}`);
                }}
            >
                <div className={ClassNames(styles.top__title, { [styles.cursor__pointer]: item.about })} ref={ref}>
                    <div className={styles.name__logo}>
                        <ImageLazy
                            placeholderWidth={600}
                            placeholderHeight="180"
                            borderRadius={16}
                            src={
                                type === 'membership passes'
                                    ? !isPreview ? createImageUrl(parentCoverUrl, 500) : parentCoverUrl
                                    : !isPreview ? createImageUrl(item.coverUrl ? item.coverUrl : logotype, 500) : (item.coverUrl || logotype)
                            }
                            className={styles.logo}
                            alt="cover url"
                        />
                    </div>
                    <Typography variant="span" className={styles.name}>
                        {item.name}
                    </Typography>
                    {/* {item.about && (
                    <img
                        src={dropdown}
                        alt="dropdown"
                        className={ClassNames(styles.dropdown, { [styles.dropdown__rotate]: showAbout })}
                    />
                )} */}
                </div>
                {/* <div className={ClassNames(styles.about, { [styles.about__show]: showAbout })}>
                <Gallery gallery={item.gallery} id={item.id} />
                <Typography variant="span" className={styles.about__title}>
                    About
                </Typography>
                <Typography variant="span" className={styles.about__text}>
                    {item.about}
                </Typography>
            </div> */}
                <div className={styles.bottom__side}>
                    <div className={styles.price__quantity}>
                        {typeOfData === null && item.price_per_hour ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_hour / 100)}/ hour
                            </Typography>
                        ) : typeOfData == null && item.price_per_day ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_day / 100)}/ day
                            </Typography>
                        ) : typeOfData == null && item.price_per_month ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_month / 100)}/ month
                            </Typography>
                        ) : (
                            ''
                        )}
                        {typeOfData === 'daily' && item.price_per_day ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_day / 100)}/ day
                            </Typography>
                        ) : (
                            ''
                        )}

                        {typeOfData === 'hourly' && item.price_per_hour ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_hour / 100)}/ hour
                            </Typography>
                        ) : (
                            ''
                        )}

                        {typeOfData === 'monthly' && item.price_per_month ? (
                            <Typography variant="span" className={styles.price}>
                                $ {prettifyPrice(item.price_per_month / 100)}/ month
                            </Typography>
                        ) : (
                            ''
                        )}
                        {type === 'membership passes' && (
                            <Typography variant="span" className={styles.quantity}>
                                Quantity available:{' '}
                                {typeOfData === 'monthly' || item.price_per_day === 0
                                    ? availableMonthlyPasses
                                    : availableDailyPasses}
                            </Typography>
                        )}
                        {type === 'hot desk' && (
                            <Typography variant="span" className={styles.quantity}>
                                Quantity available: {availableHotDeskQty}
                            </Typography>
                        )}
                    </div>
                    {role !== 'host' && (
                        <>
                            {!data?.type || indCalendarOpen ? (
                                <button className={styles.add__btn} ref={btnRef} onClick={openCalendar}>
                                    <Calendar className={styles.calendarIcon} />
                                    Select date
                                </button>
                            ) : (
                                <>
                                    {!check.includes(item.id + '-' + data.dateKey) ? (
                                        <div onClick={onHandleAdd} className={styles.add__btn}>
                                            <ShoppingCart className={styles.plus}/>
                                            Add
                                        </div>
                                    ) : (
                                        <div className={styles.counter} onClick={(e) => e.preventDefault()}>
                                            <img src={minus} alt="" onClick={onHandleMinus} />
                                            <Typography variant="span" className={styles.counter__num}>
                                                {tempCart[item.id + '-' + checkdate.dateKey].qty
                                                    ? tempCart[item.id + '-' + checkdate.dateKey].qty
                                                    : item.qty}
                                            </Typography>
                                            <img src={plus} alt="" onClick={onHandlePlus} />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}



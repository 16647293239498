/* eslint-disable camelcase */
import React, { useState } from 'react';
import ClassNames from 'classnames';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/user';
import imgVerification from '../assets/images/VerificationBanner.svg';
import styles from './widget.module.sass';
import { CloseBtn } from '../../assets/svg';
import { Link } from 'react-router-dom';

const StripeWidget = () => {
    const user = useSelector(userSelector);
    const [isClose, setIsClose] = useState(false);
    const history = useHistory();

    const isAvoidScreens = ['/verification', '/summary', '/verification-step', '/edit-verification-info'].includes(
        // eslint-disable-next-line no-undef
        window.location.pathname
    );

    React.useEffect(() => {
        // reset popup state when user has logged in
        setIsClose(false);
    }, [user?.id]);

    if (!user || isAvoidScreens) {
        return null;
    }

    const closeWidget = () => {
        setIsClose(true);
    };

    // eslint-disable-next-line no-unused-vars
    const navigateToVerification = () => {
        setIsClose(true);
        history.push('/verification');
    };

    const { payouts_enabled, details_submitted, charges_enabled, business_type, id } = user?.account ?? {};
    // const { currently_due = [], past_due = [], eventually_due = [] } = user?.account?.requirements ?? {};

    // const unionDueErrors = union(eventually_due, currently_due, past_due);
    const isVerified = !!business_type && payouts_enabled && details_submitted && charges_enabled;

    // eslint-disable-next-line no-unused-vars
    const showWidget = !!id && !isVerified && user.role === 'host'; // unionDueErrors.length ||

    // user.account.capabilities.card_payments === 'inactive' || +user.account.external_accounts.total_count !== 1
    return (
        <>
            {showWidget ? (
                <div className={ClassNames(styles.wrapper, isClose ? styles.display_wrapper : '')}>
                    <CloseBtn className={styles.svg_close} onClick={closeWidget} stroke="white" />
                    <img className={styles.img} src={imgVerification} alt="" />
                    <div className={styles.container}>
                        <span className={styles.title}>Please verify your account</span>

                        <div className={styles.description}>
                            In order to list your spaces and receive payouts for space bookings, you must complete the
                            full verification process.
                        </div>
                        <div className={styles.btns_area}>
                            <button className={styles.main_btn} onClick={navigateToVerification} type="button">
                                Verify Account
                            </button>

                            {/* {user.accountLink?.url && (
                                <a className={styles.main_btn} href={user.accountLink?.url} onClick={closeWidget}>
                                    Verify Account
                                </a>
                            )} */}
                            <Link className={styles.btn_transparent} to="/faq">
                                What is this?
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default StripeWidget;

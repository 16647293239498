import { useEffect } from 'react';

export const useScrollUp = (dependency) => {
    useEffect(() => {
        const coworkingPage = subspaces.some(item => dependency?.pathname?.includes(item));
        if(!coworkingPage){
            window.scrollTo(0, 0);
        }  
    }, [dependency]);
};

const subspaces = ['hot-desk', 'private-office', 'meeting-rooms', 'event-space', 'membership-passes']

import { ErrorMessage } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { industryeSelector, setIndustry } from '../../../../store/reducers/nativeVerification';
import styles from '../busines-details.module.sass';
import IndustryCategories from '../../assets/categories.json';
import mcc from '../../assets/mcc.json';
import ClassNames from 'classnames';
import { userSelector } from '../../../../store/reducers/user';

const IndustrySelect = (props) => {
    const dispatch = useDispatch();
    const { form, field, label, editVerificationFlow } = props;

    const handleChange = (e) => {
        dispatch(setIndustry(e));
        form.setFieldValue(field.name, e.value);
    };
    const handleBlur = (e) => {
        if (editVerificationFlow) {
            form.validateField(field.name);
        }
        form.setFieldTouched(field.name);
    };
    const erorStyle = form ? form.errors && form.errors[field.name] !== undefined : false;
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                // color: '#ffffff',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                // fontSize: location.pathname.includes('search') ? '20px' : '20px',
                lineHeight: '28px',
                // color: location.pathname.includes('search') ? '#000C2F' : '#000C2F',
                whiteSpace: 'nowrap',
            };
        },
        control: (defaultStyles) => {
            return {
                ...defaultStyles,
                border:
                    erorStyle && form.touched[field.name]
                        ? '1.5px solid #D1291B'
                        : field.value === ''
                        ? '1.5px solid #666D82'
                        : '1.5px solid #000C2F',
                boxShadow: 'none',
                height: '50px',
                borderRadius: '16px',
                padding: '0px 24px',
                marginBottom: '5px',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                position: 'relative',
                '&:hover': {
                    border: erorStyle && form.touched[field.name] ? '1.5px solid #D1291B' : '1.5px solid #000C2F',
                },
            };
        },
        valueContainer: (defaultStyles) => {
            return {
                ...defaultStyles,
                padding: '0px ',
            };
        },
        menu: (defaultStyles) => {
            return {
                ...defaultStyles,
                // width: location.pathname.includes('search') ? '230px' : '250px',
                borderRadius: '16px',
                // top: location.pathname.includes('search') ? '16px' : '30px',
                // left: location.pathname.includes('search') ? '-40px' : '0px',
                //marginTop: '40px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '28px',
                zIndex: '100',
                overflow: 'hidden',
                padding: '8px 0',
                // paddingLeft: searchedCities ? '16px' : '0px',
            };
        },
        menuList: (defaultStyles) => {
            return {
                ...defaultStyles,
                '&::-webkit-scrollbar-track': {
                    borderRadius: '10px',
                    backgroundColor: '#ffffff',
                },
                '&::-webkit-scrollbar': {
                    width: '6px',
                    backgroundColor: '#F5F5F5',
                },
                '&::-webkit-scrollbar-thumb': {
                    orderRadius: '10px',
                    backgroundColor: '#2e1cc4',
                },
            };
        },
        NoOptionsMessage: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '28px',
                color: '#000C2F',
            };
        },
        option: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: '$Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '2.8rem',
                color: '#515970',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '4px 16px 4px',
                marginBottom: '4px',
                backgroundColor: 'transparent',
                '&:hover': {
                    cursor: 'pointer',
                    color: '#000C2F',
                    borderRadius: '24px',
                    backgroundColor: '#f0effb',
                    // width: location.pathname.includes('search') ? '186px' : '210px',
                },
            };
        },
        singleValue: (defaultStyles) => {
            return {
                ...defaultStyles,
                // fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                // fontSize: '30px',
                lineHeight: '24px',
                color: '#000C2F',
                border: 'none',
                borderColor: 'none',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
            };
        },
        input: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#000C2F',
                border: 'none',
                borderColor: 'none',
            };
        },
    };

    const industry = useSelector(industryeSelector);
    const { account } = useSelector(userSelector);

    useEffect(() => {
        if (editVerificationFlow) {
            const searchedMCC = mcc.find((e) => {
                return e.value == account?.business_profile.mcc;
            });
            if (searchedMCC) {
                dispatch(setIndustry({ label: searchedMCC.name, value: searchedMCC.value }));
                form.setFieldValue(field.name, { label: searchedMCC.name, value: searchedMCC.value });
            }
        }
    }, []);

    const prepareIndustryCategories = (props) => {
        const { names, values, categories, categoriesAsObj } = IndustryCategories;
        // return Object.keys(categoriesAsObj).map((key) => {
        //     return {
        //         label: categoriesAsObj[key].defaultMessage,
        //         options: values[key].map((element) => {
        //             return { label: names[element].defaultMessage, value: key + '__' + element };
        //         }),
        //     };
        // });Î
        return mcc.map((e) => {
            return { label: e.name, value: e.value };
        });
    };
    const categories = prepareIndustryCategories();
    return (
        <div className={styles.industry}>
            <span
                className={ClassNames(
                    erorStyle && form.touched[field.name] ? styles.industry__label__error : styles.industry__label,
                    erorStyle && editVerificationFlow ? styles.industry__label__error : styles.industry__label,
                    props.field.value !== '' && styles.industry__label__filled
                )}
            >
                {label}
            </span>
            <Select
                {...props}
                value={industry}
                styles={customStyles}
                options={categories}
                onChange={(e) => handleChange(e)}
                onBlur={() => handleBlur()}
            />
            {!editVerificationFlow && (
                <span className={styles.industry__error}>
                    {field.name && erorStyle && <ErrorMessage name={field.name} />}
                </span>
            )}
            {editVerificationFlow && <span className={styles.industry__error}>{form.errors[field.name]}</span>}
        </div>
    );
};

export default IndustrySelect;

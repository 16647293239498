/* eslint-disable camelcase */
import React from 'react';

const RenderMessage = ({ message }) => {
    if (typeof message === 'string') return message;

    return (
        <span>
            {message.map((m, i) =>
                typeof m === 'string' ? (
                    <React.Fragment key={i}>{m}</React.Fragment>
                ) : (
                    <a key={i} href={m.link} target="_blank" rel="noopener noreferrer">
                        {m.text}
                    </a>
                )
            )}
        </span>
    );
};

export default RenderMessage;

const useAddClassConditionally = (condition, className, selector = 'body') => {
    const isClassName = document.querySelector(`.${className}`);
    const bodyHTML = document.querySelector(selector);

    if (condition && !isClassName && bodyHTML) {
        bodyHTML.classList.add(className);

        return () => {
            bodyHTML.classList.remove(className);
        };
    }

    if (isClassName && !condition && bodyHTML) {
        bodyHTML.classList.remove(className);
    }
};

export default useAddClassConditionally;

import { format, isPast } from 'date-fns';
import { logoutAction } from '../../store/reducers/auth';

let store = null;
export const setStore = s => store = s;
export default class Http {
    propertyName = null;
    token = null;
    refreshToken = null;
    refreshProgress = null;
    refreshUrl = null;
    refreshCallback = null;

    static async request(url, method = 'GET', data = null, headers = null) {
        const config = { url, data, headers, method };
        try {
            return await request(config);
        } catch (e) {
            throw e;
        }
    }

    setToken(token) {
        this.token = token;
    }

    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
    }

    setOptions = ({ propertyName, refreshUrl, refreshCallback, logout }) => {
        this.propertyName = propertyName;
        this.refreshUrl = refreshUrl;
        this.refreshCallback = refreshCallback;
        this.logout = logout;
    };

    validateTokenAndRefresh = () => {
        const tokens = localStorage.getItem('token');
        if (tokens && !this.refreshProgress) {
            const {
                token,
                //  refreshToken
            } = JSON.parse(tokens);
            // this.setRefreshToken(refreshToken);
            if (token) {
                const { exp } = JSON.parse(atob(token.split('.')[1]));
                // this.userId = sub;
                if (isPast(new Date(exp * 1000), new Date())) {
                    const date = format(new Date(exp * 1000), 'dd/MM/yyyy hh:mm');
                    this.refreshProgress = this.refreshQuery(token);
                }
            }
        }
    };

    refreshQuery = async (token) => {
        this.retry = true;
        try {
            const response = await request({
                url: this.refreshUrl,
                method: 'post',
                data: token,
            });
            if (response) {
                const { access_token } = response;
                if (typeof this.refreshCallback === 'function') {
                    this.refreshCallback(access_token);
                } else {
                    localStorage.setItem('token', JSON.stringify({ token: access_token }));
                }
            }
            this.refreshProgress = null;
            this.retry = false;
        } catch (err) {
            this.refreshProgress = null;
            if (this.retry) {
                this.retry = false;
                this.logout();
            }
            // console.log(err);
        }
    };

    get = async (url, data = null, headers = null) => {
        try {
            await this.validateTokenAndRefresh();

            if (this.refreshProgress) {
                await this.refreshProgress;
            }
            return await request({ url, method: 'GET', data, headers });
        } catch (e) {
            throw e;
        }
    };
    post = async (url, data = null, headers = null) => {
        try {
            await this.validateTokenAndRefresh();

            if (this.refreshProgress) {
                await this.refreshProgress;
            }
            return await request({ url, method: 'POST', data, headers });
        } catch (e) {
            throw e;
        }
    };
    put = async (url, data = null, headers = null) => {
        try {
            await this.validateTokenAndRefresh();

            if (this.refreshProgress) {
                await this.refreshProgress;
            }
            return await request({ url, method: 'PUT', data, headers });
        } catch (e) {
            throw e;
        }
    };
    delete = async (url, data = null, headers = null) => {
        try {
            await this.validateTokenAndRefresh();

            if (this.refreshProgress) {
                await this.refreshProgress;
            }
            return await request({ url, method: 'DELETE', data, headers });
        } catch (e) {
            throw e;
        }
    };
}

const createdURLParams = (method, data, url) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (method === 'GET' || method === 'DELETE') {
        let body = null;
        if (data) {
            const params = Object.keys(data)
                .map((param) => `${param}=${data[param]}`)
                .join('&');
            if (baseUrl) {
                return { URL: `${baseUrl}${url}?${params}`, body };
            }
            return { URL: `${url}?${params}`, body };
        }
    }
    if (baseUrl) {
        return { URL: baseUrl + url, body: data || null };
    }
    return { URL: url, body: data || null };
};

async function request(props) {
    const { URL, body } = createdURLParams(props.method, props.data, props.url);

    let headers;
    const config = {
        method: props.method,
        headers,
    };
    if (body) {
        if (body instanceof FormData) {
            config.body = body;
            config.headers = props.headers;
        } else {
            config.body = JSON.stringify(body);
            config.headers = props.headers || {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
            };
        }
    }
    try {
        const tokens = localStorage.getItem('token');
        if (tokens) {
            const { token } = JSON.parse(tokens);
            const autorize = { Authorization: `Bearer ${token}` } || {};
            config.headers = { ...config.headers, ...autorize };
        }
        const response = await fetch(URL, config);
        const result = await response.json();
                 
        if (response.status >= 400 && props.url === '/user' && props.method === 'GET') {
            store.dispatch(logoutAction());
        }

        if (!response.ok) {
            throw new Error(JSON.stringify(result));
        }
        return result;
    } catch (err) {
        throw err;
    }
}


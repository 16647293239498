import { Field } from 'formik';
import React from 'react';
import NativeInput from '../../../native-input/NativeInput';
import styles from '../../busines-details.module.sass';
import ClassNames from 'classnames';
import InputIndificatorControl from '../../../../../components/ui/input-control/InputIndificatorControl';
import { InputCodeControl, PhoneControl } from '../../../../../components/ui/input-control';
import IndustrySelect from '../IndustrySelect';
import VerificationPhoneControl from '../VerificationPhoneControl';
import { InfoIcon } from '../../../../../assets/svg';

const LegalBusiness = (props) => {
    const { phone, editVerificationFlow, employer } = props; // form Is include phone fiels, do not forgot add validatiom to schema

    return (
        <>
            <span className={styles.businessTitle}>Legal Business name</span>
            <div className={styles.inputBox}>
                <Field
                    name="companyName"
                    label="Company name"
                    component={NativeInput}
                    placeholder="Enter your company name"
                    className={ClassNames(styles.verifyInput)}
                    data="companyName"
                    message
                    editVerificationFlow={editVerificationFlow}
                    // info=" The combination of your name and Employer Identification Number (EIN) must
                    //     exactly match the one listed on your IRS documents (e.g., Letter 147C or SS-4
                    //     Confirmation letter), including capitalization and punctuation."
                />
                <div className={styles.inputModalSvgBox}>
                    <InfoIcon />
                </div>
                <div className={styles.inputModal}>
                    <span className={styles.inputModalText}>
                        The combination of your name and Employer Identification Number (EIN) must exactly match the one
                        listed on your IRS documents (e.g., Letter 147C or SS-4 Confirmation letter), including
                        capitalization and punctuation.
                    </span>
                </div>
                {!!employer && (
                    <Field
                        name="employer"
                        label="Employer Identification Number (EIN)"
                        className={styles.verifyInput}
                        data_attr="employerVerify"
                        component={InputIndificatorControl}
                        variant={InputCodeControl}
                        mask="XX-XXXXXXX"
                        errors
                        editVerificationFlow={editVerificationFlow}
                    />
                )}
                <div style={{marginTop: '10px'}}>
                    <Field
                        name="industry"
                        label="Industry"
                        component={IndustrySelect}
                        placeholder="Please select your industry"
                        data_attr="industryVerify"
                        editVerificationFlow={editVerificationFlow}
                    />
                </div>
                {phone && (
                    <Field
                        className={styles.verifyInput}
                        label="Business phone number"
                        name="phoneNumberBusiness"
                        placeholder="+1 (XXX) XXX-XXXX"
                        message
                        type="text"
                        component={editVerificationFlow ? VerificationPhoneControl : PhoneControl}
                        data_atr="business_phone_number"
                        editVerificationFlow={editVerificationFlow}
                    />
                )}
            </div>
        </>
    );
};

export default LegalBusiness;

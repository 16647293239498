import { createAction, handleActions } from 'redux-actions';
import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { eachDayOfInterval } from 'date-fns';
import { placeSelecor } from './place';
import { resource } from '../../service/http';
import { dangerMessage } from '../../components/ui/alert/alertReducer';
import { push } from 'connected-react-router';
import {popupWarning} from "./popupReduser";

const namespace = 'payment';

const SET_RANGE = `${namespace}/SET_RANGE`;
const SET_PERSON = `${namespace}/SET_PERSON`;
const SET_ACTIVITY = `${namespace}/SET_ACTIVITY`;
const SET_CURRENT_ACTIVITY = `${namespace}/SET_CURRENT_ACTIVITY`;

const SET_PRICE = `${namespace}/SET_PRICE`;
const SET_FINAL_PRICE = `${namespace}/SET_FINAL_PRICE`;

const RESET_PAYMENT = `${namespace}/RESET_PAYMENT`;

const PAYMENT_SUBMIT = `${namespace}/PAYMENT_SUBMIT`;

const SET_LOAD = `${namespace}/SET_LOAD`;

export const setPerson = createAction(SET_PERSON);
export const setActivity = createAction(SET_ACTIVITY);
export const setCurrentActivity = createAction(SET_CURRENT_ACTIVITY);
export const setRange = createAction(SET_RANGE);
export const setPrice = createAction(SET_PRICE);
export const setFinalPrice = createAction(SET_FINAL_PRICE);
export const setLoad = createAction(SET_LOAD);

export const paymentSubmit = createAction(PAYMENT_SUBMIT);
export const resetPayment = createAction(RESET_PAYMENT);

const initialState = {
    range: [],
    person: null,
    activity: null,
    price: 0,
    finalPrice: 0,
    load: false,
};

export default handleActions(
    {
        [SET_RANGE]: (state, { payload }) => ({ ...state, range: [...payload] }),
        [SET_PERSON]: (state, { payload }) => ({ ...state, person: payload }),

        [SET_PRICE]: (state, { payload }) => ({ ...state, price: payload }),
        [SET_FINAL_PRICE]: (state, { payload }) => ({ ...state, finalPrice: payload }),

        [SET_ACTIVITY]: (state, { payload }) => ({ ...state, activity: payload }),
        [SET_CURRENT_ACTIVITY]: (state, { payload }) => ({ ...state, activity: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),

        [RESET_PAYMENT]: () => initialState,
    },
    initialState
);

export const paymentSelector = (state) => state[namespace];
export const requestLoader = (state) => state[namespace].load;

export function* paymentSubmitRequest() {
    while (true) {
        const {
            payload: { form, setSubmitting },
        } = yield take(PAYMENT_SUBMIT);
        const { amount, ...values } = form;

        if (values.range.length === 1) {
            values.range.push(values.range[0]); // if user select only one date we create range with same date
        }
        try {
            yield put(setLoad(true));
            const response = yield call(resource.post, '/booking-request', { ...values, amount: amount });
            if (response) {
                setSubmitting(false);
                yield put(
                    push({
                        pathname: '/thanks',
                        state: {
                            title: 'Thank you for your booking request!',
                            description: 'One of our agents will be in touch shortly. Please call for urgent requests.',
                            buttonTitle: 'Go to my account',
                            buttonLink: '/account/requests',
                            // phone: `${response.user.phoneNumber}`,
                        },
                    })
                );
                yield put(resetPayment());
            }
        } catch (err) {
            setSubmitting(false);
            // console.log(err);
            yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));;
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* createPrice() {
    const { range, activity } = yield select(paymentSelector);
    const { placeActivities, place } = yield select(placeSelecor);

    const pricePerDay =
        placeActivities.length && activity ? placeActivities.find((e) => e.id === activity.value)?.price : 0;
    const count = range.length ? eachDayOfInterval({ start: range[0], end: range[range.length - 1] }).length : 1;

    const result = Math.abs((+pricePerDay * count) / 100).toFixed(2);
    const resultSum = Number(result);

    const serviceFee = (result * 0.095).toFixed(2);
    const serviceFeeSum = Number(serviceFee);

    const securityDeposit = (place.capturePrice / 100).toFixed(2);
    const securityDepositSum = Number(securityDeposit);

    const totalSum = Math.abs(resultSum + serviceFeeSum + securityDepositSum).toFixed(2);

    yield put(setFinalPrice(totalSum));
    yield put(setPrice(pricePerDay));
}

export function* createPriceActivity() {
    yield fork(createPrice);
}

export function* createPriceRange() {
    yield fork(createPrice);
}

export function* sagas() {
    yield all([
        takeLatest(SET_RANGE, createPriceRange),
        takeLatest(SET_ACTIVITY, createPriceActivity),
        paymentSubmitRequest(),
    ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import InputControl from './InputControl';
import ClassNames from 'classnames';
import styles from './input-control.module.sass';
import image from './close.svg';
import Image from '../image';

const PasswordControl = ({ className, data, autocomplete, touched, ...props }) => {
    const [visible, setVisible] = React.useState(false);
    const classes = ClassNames(styles.password, className);
    const { field, form, ...attrs } = props;
    const onHandleClick = () => {
        setVisible(!visible);
    };
    return (
        <InputControl
            className={classes}
            type={visible ? 'text' : 'password'}
            autocomplete={autocomplete}
            {...attrs}
            field={field}
            form={form}
            data={data}
        >
            {field.value && <Image src={image} className={styles.password__image} onClick={onHandleClick} />}
        </InputControl>
    );
};

PasswordControl.propTypes = {
    className: PropTypes.string,
    data: PropTypes.string,
};
PasswordControl.defaultProps = {
    className: '',
    data: '',
};

export default PasswordControl;

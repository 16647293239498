import React from 'react';
import NumberFormat from 'react-number-format';
import InputControl from './InputControl';

const FloatControl = (props) => {
    const { form, field, ...attrs } = props;
    const { name, value } = props.field ? props.field : props;
    const setFieldValue = props.form ? props.form.setFieldValue : null;

    const handleChange = (e) => {
        const {
            target: { value, name },
        } = e;
        let prepareValue = value.replace(/\D/g, '');
        if (props.valueLength) {
            prepareValue = prepareValue.slice(0, props.valueLength);
        }
        if (setFieldValue) {
            setFieldValue(name, prepareValue);
        } else {
            props.onChange(e);
        }
    };

    return (
        <InputControl
            {...attrs}
            value={value}
            name={name}
            onChange={handleChange}
            variant={NumberFormat}
            form={form}
            field={field}
            // thousandSeparator="'" //FIXME:
        />
    );
};

export default FloatControl;

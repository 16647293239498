import { handleActions, createAction } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { activitiesSelector } from './search';
import {subscribeAction} from "./footer";
import {popupSuccess, popupWarning} from "./popupReduser";

const namespace = 'home';

const INIT_EXPLORE_CATEGORIES = `${namespace}/INIT_EXPLORE_CATEGORIES`;
const SET_CATEGORIES = `${namespace}/SET_CATEGORIES`;
const SET_SELECTED_CATEGORIES = `${namespace}/SET_SELECTED_CATEGORIES`;
const SET_CATEGORY_ITEMS = `${namespace}/SET_CATEGORy_ITEMS`;
const GET_CATEGORY_ITEMS = `${namespace}/GET_CATEGORy_ITEMS`;
const SEND_CTA_FORM = `${namespace}/SEND_CTA_FORM`;

const SET_LOAD = `${namespace}/SET_LOAD`;

export const setSelectedCategories = createAction(SET_SELECTED_CATEGORIES);
export const initExploreCategories = createAction(INIT_EXPLORE_CATEGORIES);
export const setCategories = createAction(SET_CATEGORIES);
export const setCategoryItems = createAction(SET_CATEGORY_ITEMS);
export const sendCTAFormAction = createAction(SEND_CTA_FORM);

export const setLoad = createAction(SET_LOAD);

const initialState = {
    categories: null,
    selectedCategory: null,
    categoryItems: null,
    load: false,
    error: null,
};

export default handleActions(
    {
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_CATEGORIES]: (state, { payload }) => ({ ...state, categories: payload }),
        [SET_CATEGORY_ITEMS]: (state, { payload }) => ({ ...state, categoryItems: payload }),
        [SET_SELECTED_CATEGORIES]: (state, { payload }) => ({ ...state, selectedCategory: payload }),
    },
    initialState
);

export const categoriesSelector = (state) => state[namespace].categories;
export const selectedCategorySelector = (state) => state[namespace].selectedCategory;
export const currentCategoryItems = (state) => state[namespace].categoryItems;
export const homeLoaderSelector = state =>state[namespace].load

function* getCategoryItemsRequest() {
    const id = yield select(selectedCategorySelector);
    const items = { ...(yield select(currentCategoryItems)) };
    if (!items[id]) {
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, `/activities/locations/${id}`);
            items[id] = [...response];
            yield put(setCategoryItems({ ...items }));
        } catch (err) {
            // console.log(err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* init() {
    while (true) {
        const { payload } = yield take(INIT_EXPLORE_CATEGORIES);
        if (payload.length) {
            yield put(setSelectedCategories(payload[0].id));
            yield put(setCategories(payload));
        }
    }
}

export function* sendCTAForm() {
    while (true) {
        const {
            payload: {
                values,
                actions: { setSubmitting, resetForm },
            },
        } = yield take(SEND_CTA_FORM);
        setSubmitting(true);
        try {
            const response = yield call(resource.post, '/form/cta', { ...values });
            if (response.status === 'success') {
                resetForm();
                yield put(popupSuccess({ h2: 'Success!', message: 'You will be notified when new spaces are available near you.' }));
            }
        } catch (err) {
            yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
        } finally {
            setSubmitting(false);
        }
    }
}

export const sagas = function* () {
    yield all([init(), takeLatest(SET_SELECTED_CATEGORIES, getCategoryItemsRequest), sendCTAForm()]);
};

import { Field, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import React from 'react';
import NativeInput from '../../../native-input/NativeInput';
import styles from '../../busines-details.module.sass';
import ClassNames from 'classnames';
import { BigBinIcon, Plus } from '../../../../../assets/svg';
import { deleteDataFromStripe } from '../../../../../store/reducers/nativeVerification';

const BusinessOwnershipInformation = ({ editVerificationFlow }) => {
    const dispatch = useDispatch();

    const addMemberHandler = (form) => {
        const { setValues, values } = form;
        const members = [...form.values.members];
        members.push({
            namePerson: '',
            emailPerson: '',
            lastNamePerson: '',
        });
        setValues({ ...values, members });
    };

    const removememberHandler = (form, idx) => {
        const { setValues, values } = form;
        const members = [...form.values.members].filter((_, index) => {
            return index !== idx;
        });
        setValues({ ...values, members });
    };

    const onRemoveActionHandler = (person_id, form, idx) => {
        dispatch(
            deleteDataFromStripe({
                values: { person_id },
                updateType: 'delete_member',
                successCB() {
                    removememberHandler(form, idx);
                },
            })
        );
    };

    return (
        <>
            {!editVerificationFlow && (
                <div className={ClassNames(styles.businessTitleBox)}>
                    <h1 className={styles.businessMainTitle}>Business Ownership Information</h1>
                    <span className={styles.businessSubtitle}>
                        Due to regulatory guidelines, we’re required to collect information on anyone who has
                        significant ownership of your business.
                    </span>
                    <span className={styles.businessSubtitle}>
                        Please add any individual who owns 25% or more of the company.
                    </span>
                </div>
            )}
            <div className={styles.descriptionBox}>
                {!editVerificationFlow && (
                    <div className={styles.descriptionTitleBox}>
                        <span className={styles.descriptionTitle}>Information collected for business owner:</span>
                    </div>
                )}
                <FieldArray name="members">
                    {({ form }) =>
                        form.values.members?.map((member, i) => {
                            return (
                                <React.Fragment key={member.id ?? i}>
                                    <div className={styles.ownerCardBox}>
                                        <div className={styles.headerOwner}>
                                            <span className={styles.descriptionTitle}>Business Owner</span>
                                            {i == 0 && form.values.members.length == 1 ? null : (
                                                <BigBinIcon
                                                    onClick={() =>
                                                        member.id
                                                            ? onRemoveActionHandler(member.id, form, i)
                                                            : removememberHandler(form, i)
                                                    }
                                                />
                                            )}
                                        </div>
                                        <div key={i}>
                                            <Field
                                                name={`members.${i}.namePerson`}
                                                label="First name"
                                                component={NativeInput}
                                                placeholder="Enter your first name"
                                                className={styles.verifyInput}
                                                data_attr="nameVerifyStepOwner"
                                                message
                                            />
                                            <Field
                                                name={`members.${i}.lastNamePerson`}
                                                label="Last name"
                                                component={NativeInput}
                                                placeholder="Enter your last name"
                                                className={styles.verifyInput}
                                                data_attr="lastNameVerifyStepOwner"
                                                message
                                            />
                                            <Field
                                                className={ClassNames(styles.verifyInput)}
                                                label="Email address"
                                                name={`members.${i}.emailPerson`}
                                                message
                                                type="email"
                                                placeholder="you@example.com"
                                                data_atr="nativeVerificationEmail"
                                                component={NativeInput}
                                                data_attr="emailVerifyStep"
                                                errors
                                            />
                                        </div>
                                    </div>
                                    {i == form.values.members.length - 1 && (
                                        <button
                                            className={styles.btnOwner}
                                            type="button"
                                            onClick={() => addMemberHandler(form)}
                                        >
                                            <Plus className={styles.btnSvg} width="14" height="14" />
                                            Add a business owner
                                        </button>
                                    )}
                                </React.Fragment>
                            );
                        })
                    }
                </FieldArray>
            </div>
        </>
    );
};
export default BusinessOwnershipInformation;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { typeSelector } from '../../../store/reducers/nativeVerification';
import IndividualForm from './forms/individual';
import CompanyForm from './forms/company';
import { push } from 'connected-react-router';
import NoProfitForm from './forms/nonprofit';

const FormSwitcher = () => {
    const type = useSelector(typeSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!type) {
            dispatch(push('/verification'));
        }
    }, []);

    switch (type) {
        case 'individual':
            return <IndividualForm />;
            break;
        case 'company':
            return <CompanyForm />;
            break;
        case 'non_profit':
            return <NoProfitForm />;
            break;
        default:
            return null;
    }
};

export default FormSwitcher;

import { Field } from 'formik';
import React from 'react';
import ClassNames from 'classnames';
import NativeInput from '../../../native-input/NativeInput';
import styles from '../../busines-details.module.sass';
import { InputCodeControl, PhoneControl } from '../../../../../components/ui/input-control';
import InputIndificatorControl from '../../../../../components/ui/input-control/InputIndificatorControl';
import DateInput from '../../../../../components/ui/input-control/DateInput';
import { CalendarNewIcon } from '../../../../../assets/svg';
import VerificationPhoneControl from '../VerificationPhoneControl';

const LegalNameOfPerson = ({ SSN, jobTitle, editVerificationFlow, title }) => {
    return (
        <>
            <div>
                <span className={styles.businessTitle}>{title ?? 'Legal name of person'}</span>
                <div className={styles.businessMainInputBox}>
                    <Field
                        name="namePerson"
                        editVerificationFlow={editVerificationFlow}
                        label="First name"
                        component={NativeInput}
                        placeholder="Enter your first name"
                        className={styles.verifyInput}
                        data_attr="nameVerifyStep"
                        message
                    />
                    <Field
                        name="lastNamePerson"
                        editVerificationFlow={editVerificationFlow}
                        label="Last name"
                        component={NativeInput}
                        placeholder="Enter your last name"
                        className={styles.verifyInput}
                        data_attr="lastNameVerifyStep"
                        message
                    />
                    <Field
                        className={ClassNames(styles.verifyInput)}
                        label="Email address"
                        name="emailPerson"
                        editVerificationFlow={editVerificationFlow}
                        message
                        type="email"
                        placeholder="you@example.com"
                        data_atr="nativeVerificationEmail"
                        component={NativeInput}
                        data_attr="emailVerifyStep"
                    />
                    {SSN && (
                        <Field
                            name="socialSecurityNumber"
                            editVerificationFlow={editVerificationFlow}
                            label="Social Security Number"
                            className={styles.verifyInput}
                            data_attr="securityNumber"
                            component={InputIndificatorControl}
                            variant={InputCodeControl}
                            mask="XXX-XX-XXXX"
                            errors
                        />
                    )}
                    {jobTitle && (
                        <Field
                            name="jobTitle"
                            editVerificationFlow={editVerificationFlow}
                            label="Job title"
                            component={NativeInput}
                            placeholder="Enter your job title"
                            className={styles.verifyInput}
                            data_attr="jobTitle"
                            message
                        />
                    )}
                    <div className={styles.calendarBox}>
                        <Field
                            name="datePerson"
                            editVerificationFlow={editVerificationFlow}
                            label="Date of birth"
                            component={DateInput}
                            className={ClassNames(styles.verifyInput)}
                            data_attr="dateVerifyStep"
                            message
                            errors
                        />
                        <div className={styles.calendarIcon}>
                            <CalendarNewIcon />
                        </div>
                    </div>
                    <Field
                        className={styles.verifyInput}
                        label="Phone number"
                        name="phoneNumberPerson"
                        editVerificationFlow={editVerificationFlow}
                        placeholder="+1 (XXX) XXX-XXXX"
                        message
                        type="text"
                        component={editVerificationFlow ? VerificationPhoneControl : PhoneControl}
                        data_attr="SingUpPhoneNumberVerifyStep"
                    />
                </div>
            </div>
        </>
    );
};

export default LegalNameOfPerson;

import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { reducers } from './store/reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configStore, history } from './store';
import createMiddlewares, { sagaWatch } from './store/middlewares';
import Load from './components/ui/loader';
import * as Sentry from "@sentry/react";
import posthog from 'posthog-js';
import { setStore } from '../src/service/http/Http';
import ErrorBoundary from'./components/error-boundary/ErrorBoundary';

export const store = configStore(reducers, createMiddlewares());
sagaWatch();

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost') && !window.location.host.includes('dev')) {
    posthog.init('phc_wrZfTFJxI4NWjAya6dBa1e6LsHVgfGK9bpL0gzXbnVA', { api_host: 'https://app.posthog.com' });

    Sentry.init({
        dsn: 'https://b7447137fe504716a410ec8cd6624b69@o1190681.ingest.sentry.io/6437454',
        integrations: [new posthog.SentryIntegration(posthog, 'office-exchange', '6437454')],
        tracesSampleRate: 1.0,
    });
}

setStore(store);
// const Loader = ()=><ContentLoader/>

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Load />}>
            <ErrorBoundary>
                <App history={history} />
            </ErrorBoundary>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { ReactComponent as Filters } from './filters-icon.svg';
import styles from './more-filters.module.sass';
import { useOnClickOutside } from './../../utils/useOnClickOutside';
import { useBlockBodyScroll } from '../../pages/create-place/useBlockBodySrcoll';
import Image from '../../components/ui/image/Image';
import closeSvg from './close.svg';
import PriceInput from '../ui/input-control/PriceInput';
import { Field, Form, Formik, FieldArray } from 'formik';
import { commercial, amenitiesList, coworking, coworkingFirstItem } from './utils';
import { useSelector, useDispatch } from 'react-redux';
import ClassNames from 'classnames';
import { useQuery } from '../hooks/useQuery';
import { push } from 'connected-react-router';
import { quartersToMonths } from 'date-fns';
import { filtersSelctor } from '../../store/reducers/search';
import 'rheostat/initialize';
import Rheostat from 'rheostat';
import './rheostat.sass';
import NumberFormat from 'react-number-format';
import { useMedia } from 'use-media';
import { useBlockBodyScrollSearch } from '../../pages/create-place/useBlockBodySrcoll';
import { SeverityLevels } from '@sentry/types';

const findFiltersLength = (a, k, s) => {
    let count = 0;
    if (a.length) count += a.length;
    if (k.length) count += k.length;
    if (s) count += 1;
    return count;
};
const MoreFilters = ({ amenities, kind, squareMin, squareMax }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const isMobile = useMedia({maxWidth: '550px'});

    useBlockBodyScrollSearch(modal);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    useBlockBodyScroll(modal);
    const filterLength = findFiltersLength(amenities, kind, squareMin, squareMax);

    const showIndicator = React.useMemo(() => {
        return amenities.length || kind.length || squareMin || squareMax
    }, [amenities, kind, squareMin, squareMax])
    return (
        <>
            {modal && (
                <Modal
                    setModal={setModal}
                    amenities={amenities}
                    kind={kind}
                    squareMin={squareMin}
                    squareMax={squareMax}
                />
            )}
            <button
                className={styles.button}
                type="button"
                onClick={() => setModal(true)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {isMobile && showIndicator && <div className={styles.indicator}><div className={styles.inner}/></div>}
                <Filters
                    fill={isHovered ? 'white' : 'black'}
                    style={{ marginRight: isMobile ? '0' : '8px', transition: '0.25s all ease' }}
                />
                {isMobile ? '' : filterLength ? `More filters(${filterLength})` : 'More filters'}
            </button>
        </>
    );
};

export default MoreFilters;

const Modal = ({ setModal, amenities, kind, squareMin, squareMax }) => {
    const ref = React.useRef();
    const [coworkingSelect, setCoworkingSelect] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({
        categories: [],
        amenities: [],
        squareMin: '',
        squareMax: '',
    });

    const testCoworking = coworking.map(item => item.name);
    React.useEffect(() => {
        setInitialValues({
            amenities,
            squareMin,
            squareMax,
            categories: kind,
        });
        if(kind.filter(item => testCoworking?.includes(item)).length === 5) {
            setCoworkingSelect(true);
        }
        
    }, []);

    const filters = useSelector(filtersSelctor);

    const filtersSelected = initialValues?.categories?.length || initialValues?.amenities?.length || initialValues?.squareMin || initialValues?.squareMax;

    const query = useQuery();
    useOnClickOutside(ref, () => setModal(false));
    const { pathname } = useSelector(({ router: { location } }) => location);
    const dispatch = useDispatch();
    const applyFilters = (values) => {
        amenitiesList.map((item) => item.name).forEach((item) => query.delete(item));
        if (values.amenities.length) {
            values.amenities.forEach((item) => {
                query.set(item, 'true');
            });
        }
        if (values.categories.length) {
            query.set('kind', values.categories.toString());
        } else {
            query.delete('kind');
        }
        if((min == filters.minsquare || min < filters.minsquare) && (max == filters.maxsquare || max > filters.maxsquare)) {
            query.delete('squareMin');
            query.delete('squareMax');
        }else if (min !== filters.minsquare || max !== filters.maxsquare) {
            if(!min || min < filters?.minsquare){
                query.set('squareMin', filters?.minsquare); 
            }else{
                query.set('squareMin', min);
            } 
            if(max > filters?.maxsquare){
                query.set('squareMax', filters?.maxsquare);
            }else{
                query.set('squareMax', max);
            }   
        } 
        query.delete('page');
        dispatch(push({ pathname, search: query.toString() }));
        setModal(false);
    };

    const clearAll = (setFieldValue) => {
        setCoworkingSelect(false);
        query.delete('squareMin');
        query.delete('squareMax');
        query.delete('kind');
        amenitiesList.map((item) => item.name).forEach((item) => query.delete(item));
        setMin(filters?.minsquare);
        setMax(filters?.maxsquare);
        setInitialValues({
            categories: [],
            amenities: [],
            squareMin: '',
            squareMax: '',
        });
        setFieldValue('categories', []);
        setFieldValue('amenities', []);
        setFieldValue('squareMin', '');
        setFieldValue('squareMax', '');      
        dispatch(push({ pathname, search: query.toString()  }));

    }

    const [min, setMin] = React.useState(squareMin ? squareMin : filters?.minsquare);
    const [max, setMax] = React.useState(squareMax ? squareMax : filters?.maxsquare);


    const chooseCoworkingItems = (item, helpers, values) => {
            if (!values?.categories?.includes(item.name)) {
                helpers.push(item.name);
            } else {
                const index = values?.categories?.findIndex(
                    (i) => i === item.name
                );
                helpers.remove(index); 
                setCoworkingSelect(false); 
            }
    }

    const chooseCoworkingAll = (helpers, values, setFieldValue) => {
        if (!coworkingSelect) {
            setCoworkingSelect(true);
            coworking.forEach((item) => helpers.push(item.name));
        } else {
            setCoworkingSelect(false);
            const categories = values?.categories?.filter(item => !testCoworking.includes(item));
            setFieldValue('categories', categories);
        }
    };


    return (
        <div className={styles.overlay}>
            <div className={styles.wrapper} ref={ref}>
                <button className={styles.closeBtn} onClick={() => setModal(false)}>
                    <Image src={closeSvg} />
                </button>
                <div className={styles.titleWrapper}>
                    <h3 className={styles.title}>More filters</h3>
                </div>
                <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={applyFilters}>
                    {({ values, setFieldValue, ...props }) => {
                        return (
                            <Form>
                                <div className={styles.block}>
                                    <p className={styles.blockTitle}>Find Flexible Office and Coworking Spaces</p>
                                    <p className={styles.blockSubtitle}>Commercial Spaces</p>
                                    <div className={styles.itemsWrapper}>
                                        <FieldArray
                                            name="categories"
                                            render={(helpers) => (
                                                <>
                                                    {commercial.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            className={ClassNames(styles.item, {
                                                                [styles.itemActive]: values?.categories?.includes(
                                                                    item.name
                                                                ),
                                                            })}
                                                            onClick={() => {
                                                                if (!values?.categories?.includes(item.name)) {
                                                                    helpers.push(item.name);
                                                                } else {
                                                                    const index = values?.categories?.findIndex(
                                                                        (i) => i === item.name
                                                                    );
                                                                    helpers.remove(index);
                                                                }
                                                            }}
                                                        >
                                                            <div className={styles.image}>{item.img}</div>
                                                            {item.title}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <p className={ClassNames(styles.blockSubtitle, styles.topMargin)}>
                                        Coworking Spaces
                                    </p>
                                    <div className={styles.itemsWrapper}>
                                        <FieldArray
                                            name="categories"
                                            render={(helpers) => (
                                                <>
                                                    <div
                                                        key={coworkingFirstItem.name}
                                                        className={ClassNames(styles.item, {
                                                            [styles.itemActive]: coworkingSelect,
                                                        })}
                                                        onClick={() => chooseCoworkingAll(helpers, values, setFieldValue)}
                                                    >
                                                        <div className={styles.image}>{coworkingFirstItem.img}</div>
                                                        {coworkingFirstItem.title}
                                                    </div>
                                                    {coworking.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            className={ClassNames(styles.item, {
                                                                [styles.itemActive]: values?.categories?.includes(
                                                                    item.name
                                                                ),
                                                            })}
                                                            onClick={() => chooseCoworkingItems(item, helpers, values)}
                                                        >
                                                            <div className={styles.image}>{item.img}</div>
                                                            {item.title}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.block}>
                                    <p className={styles.blockTitle}>Amenities</p>
                                    <div className={styles.itemsWrapper}>
                                        <FieldArray
                                            name="amenities"
                                            render={(helpers) => (
                                                <>
                                                    {amenitiesList.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            className={ClassNames(styles.item, {
                                                                [styles.itemActive]: values?.amenities?.includes(
                                                                    item.name
                                                                ),
                                                            })}
                                                            onClick={() => {
                                                                if (!values?.amenities?.includes(item.name)) {
                                                                    helpers.push(item.name);
                                                                } else {
                                                                    const index = values?.amenities?.findIndex(
                                                                        (i) => i === item.name
                                                                    );
                                                                    helpers.remove(index);
                                                                }
                                                            }}
                                                        >
                                                            <div className={styles.image}>{item.img}</div>
                                                            {item.title}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        ></FieldArray>
                                    </div>
                                </div>
                                <div className={styles.block}>
                                    <p className={styles.blockTitle}>What square footage do you need for your place?</p>
                                    <div className="rheostat-square">
                                        <Rheostat
                                            min={filters?.minsquare}
                                            max={filters?.maxsquare}
                                            values={[min, max]}
                                            onValuesUpdated={(e) => {
                                                setMin(e.values[0]);
                                                setMax(e.values[1]);
                                            }}
                                        />
                                    </div>
                                    <div className={styles.price__inputs}>
                                        <div className={styles.input__wrapper}>
                                            <label className={styles.label} htmlFor="minimum">
                                                Minimum
                                            </label>
                                            <NumberFormat
                                                className={ClassNames(styles.input, styles.topInput)}
                                                value={min}
                                                allowNegative={false}
                                                allowEmptyFormatting={true}
                                                isNumericString={true}
                                                suffix=" sqft."
                                                onValueChange={(values, sourceInfo) => {
                                                    if(values.value > filters?.maxsquare){
                                                        return;
                                                    }
                                                    setMin(values.value)}}
                                            />
                                        </div>
                                        <div className={styles.input__wrapper}>
                                            <label className={styles.label} htmlFor="maximum">
                                                Maximum
                                            </label>
                                            <NumberFormat
                                                className={styles.input}
                                                value={max}
                                                allowNegative={false}
                                                allowEmptyFormatting={true}
                                                suffix=" sqft."
                                                isNumericString={true}
                                                onValueChange={(values, sourceInfo) => {setMax(values.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.btnWrapper}>
                                    <button className={styles.clear} onClick={() => clearAll(setFieldValue)}>
                                        Clear All
                                    </button>
                                    <button
                                        className={ClassNames(styles.apply, {
                                            [styles.applyActive]:
                                                (max - min) > 1 && (min !== filters?.minsquare ||
                                                max !== filters?.maxsquare ||
                                                values.amenities?.length ||
                                                values.categories?.length ||
                                                filtersSelected),
                                        })}
                                        type="submit"
                                        disabled={
                                            (!(min !== filters?.minsquare) &&
                                            !(max !== filters?.maxsquare) &&
                                            !values.amenities?.length &&
                                            !values.categories?.length &&
                                            !filtersSelected) ||
                                            (max - min) < 1
                                        }
                                    >
                                        Apply
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

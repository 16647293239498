import { all, call, put, take } from '@redux-saga/core/effects';
import { createAction, handleActions } from 'redux-actions';
import { resource } from '../../service/http';
import { format } from 'date-fns';

const namespace = 'dealHistory';

const GET_DEAL_HISTORY = `${namespace}/GET_DEAL_HISTORY`;
const SET_DEAL_HISTORY_MESSAGES = `${namespace}/SET_DEAL_HISTORY_MESSAGES`;
const SET_DEAL_HISTORY_TENANT = `${namespace}/SET_DEAL_HISTORY_TENANT`;
const SET_DEAL_HISTORY_BOOKING = `${namespace}/SET_DEAL_HISTORY_BOOKING`;
const SET_DEAL_HISTORY_ACTIVITIES = `${namespace}/SET_DEAL_HISTORY_ACTIVITIES`;
const SET_DEAL_HISTORY_SPACE = `${namespace}/SET_DEAL_HISTORY_SPACE`;
const SET_DEAL_HISTORY_OWNER = `${namespace}/SET_DEAL_HISTORY_OWNER`;

// const APPROVE_REQUEST = `${namespace}/APPROVE_REQUEST`;
// const DECLINE_REQUEST = `${namespace}/APPROVE_REQUEST`;

const SET_DEAL_HISTORY_APPROVE = `${namespace}/SET_DEAL_HISTORY_APPROVE`;

// export const approveRequest = createAction(APPROVE_REQUEST);
// export const declineRequest = createAction(DECLINE_REQUEST);

const SEND_MESSAGE = `${namespace}/SEND_MESSAGE`;

export const getDealHistory = createAction(GET_DEAL_HISTORY);
export const setDealHistoryMessage = createAction(SET_DEAL_HISTORY_MESSAGES);
export const setDealHistoryTenant = createAction(SET_DEAL_HISTORY_TENANT);
export const setDealHistoryBooking = createAction(SET_DEAL_HISTORY_BOOKING);
export const setDealHistoryActivities = createAction(SET_DEAL_HISTORY_ACTIVITIES);
export const setDealHistorySpace = createAction(SET_DEAL_HISTORY_SPACE);
export const setDealHistoryOwner = createAction(SET_DEAL_HISTORY_OWNER);

// export const setDealHistoryApprove = createAction(SET_DEAL_HISTORY_APPROVE);

export const sendMessage = createAction(SEND_MESSAGE);

const initialState = {
    messages: null,
    tenant: null,
    booking: null,
    activities: null,
    space: null,
    owner: null
    // data: null
};

export default handleActions(
    {
        [SET_DEAL_HISTORY_MESSAGES]: (state, { payload }) => ({ ...state, messages: payload }),
        [SET_DEAL_HISTORY_TENANT]: (state, { payload }) => ({ ...state, tenant: payload }),
        [SET_DEAL_HISTORY_BOOKING]: (state, { payload }) => ({ ...state, booking: payload }),
        [SET_DEAL_HISTORY_ACTIVITIES]: (state, { payload }) => ({ ...state, activities: payload }),
        [SET_DEAL_HISTORY_SPACE]: (state, { payload }) => ({ ...state, space: payload }),
        [SET_DEAL_HISTORY_OWNER]: (state, { payload }) => ({ ...state, owner: payload }),
        // [SET_DEAL_HISTORY_APPROVE]: (state, { payload }) => ({ ...state, data: payload }),
    },
    initialState
);

export const dealHistorySelector = (state) => state[namespace];

function* getDealHistoryrequest() {
    while (true) {
        const { payload: id } = yield take(GET_DEAL_HISTORY);

        try {
            const { tenant, messages, booking, activities, space, owner, data } = yield call(
                resource.get,
                `/user/messages-by-request-id?requestId=${id}&timezone=${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                }`
            );
            yield put(setDealHistoryMessage(messages));
            yield put(setDealHistoryTenant(tenant));
            yield put(setDealHistoryBooking(booking));
            yield put(setDealHistoryActivities(activities));
            yield put(setDealHistorySpace(space));
            yield put(setDealHistoryOwner(owner));
            // yield put(setDealHistoryApprove(data));
        } catch (error) {}
    }
}

function* sendMessageRequest() {
    while (true) {
        const { payload } = yield take(SEND_MESSAGE);
        const { resetForm, ...form } = payload;

        // const created_at = format(new Date(), 'yyyy-MM-dd kk:mm:ss');
        // form.created_at = created_at
        try {
            const response = yield call(resource.post, `/user/post-message`, form);
            if (response) {
                yield put(getDealHistory(form.bookingId));
                resetForm();
            }
        } catch (err) {
            // console.log(err);
        }
    }
}

export function* sagas() {
    yield all([getDealHistoryrequest(), sendMessageRequest()]);
}

import React from 'react';
import { Field } from 'formik';
import styles from '../../busines-details.module.sass';
import NativeInput from '../../../native-input/NativeInput';

const AddPaymentMethod = () => {
    return (
        <>
            {' '}
            <div className={styles.businessTitleBox}>
                <h1 className={styles.businessMainTitle}>Add your payment method</h1>
                <span className={styles.businessSubtitle}>
                    In order to receive payouts, please add your bank information.
                </span>
            </div>
            <div className={styles.businessInput}>
                <div className={styles.row}>
                    <Field
                        className={styles.verifyInput}
                        component={NativeInput}
                        placeholder="Enter your first name"
                        label="First name"
                        name="first_name"
                        message
                    />
                </div>
                <div className={styles.row}>
                    <Field
                        className={styles.verifyInput}
                        component={NativeInput}
                        placeholder="Enter your last name"
                        label="Last name"
                        name="last_name"
                        message
                    />
                </div>
                {/* <div className={styles.row}>
                    <Field
                        className={styles.verifyInput}
                        component={NativeInput}
                        placeholder="Enter your name of bank"
                        label="Name of bank"
                        name="nameOfBank"
                        data_attr="nameOfBank"
                        message
                    />
                </div> */}
                {/*<div className={styles.row}>*/}
                {/*    <Field*/}
                {/*        className={styles.verifyInput}*/}
                {/*        component={NativeInput}*/}
                {/*        placeholder="Enter your address of bank 1"*/}
                {/*        label="Address of Bank 1"*/}
                {/*        name="addressOfBank1"*/}
                {/*        data_attr="addressOfBank1"*/}
                {/*        message*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className={styles.row}>*/}
                {/*    <Field*/}
                {/*        className={styles.verifyInput}*/}
                {/*        component={NativeInput}*/}
                {/*        placeholder="Enter your address of bank 2"*/}
                {/*        label="Address of Bank 2"*/}
                {/*        name="addressOfBank2"*/}
                {/*        data_attr="addressOfBank2"*/}
                {/*        message*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className={styles.row}>*/}
                {/*    <div className={styles.addressInputWrapPayment}>*/}
                {/*        <Field*/}
                {/*            className={styles.addressInput}*/}
                {/*            component={SelectControl}*/}
                {/*            placeholder="Enter your state"*/}
                {/*            label="State"*/}
                {/*            name="stateVerifyPayment"*/}
                {/*            data_attr="stateVerifyPayment"*/}
                {/*            options={STATES}*/}
                {/*            message*/}
                {/*        />*/}
                {/*        <Field*/}
                {/*            className={styles.addressInput}*/}
                {/*            component={NativeInput}*/}
                {/*            placeholder="Enter your city"*/}
                {/*            label="City"*/}
                {/*            name="cityVerifyPayment"*/}
                {/*            data_attr="cityVerifyPayment"*/}
                {/*            message*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={styles.row}>
                    <Field
                        className={styles.verifyInput}
                        component={NativeInput}
                        placeholder="Enter your checking account number"
                        label="Checking account number"
                        name="accountNumberVerifyPayment"
                        data_attr="accountNumberVerifyPayment"
                        message
                    />
                </div>
                <div className={styles.row}>
                    <Field
                        className={styles.verifyInput}
                        component={NativeInput}
                        placeholder="Enter your confirm checking account"
                        label="Confirm checking account"
                        name="accountNumberConfirmVerifyPayment"
                        data_attr="accountNumberConfirmVerifyPayment"
                        message
                    />
                </div>
                <div className={styles.row}>
                    <Field
                        className={styles.verifyInput}
                        component={NativeInput}
                        placeholder="Enter your routing number"
                        label="Routing number"
                        name="routingNumberVerifyPayment"
                        data_attr="routingNumberVerifyPayment"
                        message
                        maxlength="9"
                    />
                </div>
            </div>
        </>
    );
};

export default AddPaymentMethod;

import React from 'react';
import styles from './error-boundary.module.sass';

// const retryPageLoading = () => {
//     const pageHasAlreadyBeenForceRefreshed = JSON.parse(
//       localStorage.getItem('PageHasAlreadyBeenForceRefreshed') || "false"
//     );
//     if (!pageHasAlreadyBeenForceRefreshed) {
//       localStorage.setItem('PageHasAlreadyBeenForceRefreshed', "true");
//       return window.location.reload();
//     } else {
//       localStorage.setItem('PageHasAlreadyBeenForceRefreshed', "false");
//     }
//   };

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
        this.reset = this.reset.bind(this);
        this.reload = this.reload.bind(this);
    }

    static getDerivedStateFromError(error) {
        return { error };
    }
    reset() {
        this.setState({ error: null });
    }
    reload() {
        window.location.reload();
    }
    componentDidCatch(error, errorInfo) {
        console.log(error.message);
        console.log(errorInfo);
    }

    render() {
        console.log(this.state?.error?.message, 'error message')
        if (this.state.error) {
            return (
                <div className={styles.error}>
                    {this.state?.error?.message?.includes('chunk') ? (
                        <>
                            <h2 className={styles.errorTitle}>We just made some updates. Try reloading the page</h2>
                            <button className={styles.button} onClick={this.reload}>
                                Reload
                            </button>
                        </>
                    ) : (
                        <>
                            <h2 className={styles.errorTitle}>Sorry, something went wrong</h2>
                            <button className={styles.button} onClick={this.reset}>
                                Reset
                            </button>
                        </>
                    )}
                </div>
            );
        }
        return this.props.children;
    }
}
export default ErrorBoundary;

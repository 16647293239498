import InputControl from './InputControl';
import PasswordControl from './PasswordControl';
import RadioButtonControl from './RadioButtonControl';
import PhoneControl from './PhoneControl';
import FloatControl from './FloatControl';
import MaskInputControl from './MackInputControl';
import InputCodeControl from './InputCodeControl';
import SelectControl from './select/SelectControl';
import PaymentControl from './PaymentControl';

export {
    PasswordControl,
    RadioButtonControl,
    PhoneControl,
    FloatControl,
    MaskInputControl,
    InputCodeControl,
    SelectControl,
    PaymentControl,
};

export default InputControl;

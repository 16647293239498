import { push } from 'connected-react-router';
import { createAction, handleActions } from 'redux-actions';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { popupWarning } from './popupReduser';
import { setCities } from './search';

const namespace = 'createPlace';

const CREATE_ACTIVITIES_FIELDS = `${namespace}/CREATE_ACTIVITIES_FIELDS`;
const CREATE_PRICE_FIELDS = `${namespace}/CREATE_PRICE_FIELDS`;
const SET_ACTIVITIES_FIELDS = `${namespace}/SET_ACTIVITIES_FIELDS`;
const SET_PRICE_FIELDS = `${namespace}/SET_PRICE_FIELDS`;
const SET_PRICE_ACTIVITY = `${namespace}/SET_PRICE_ACTIVITY`;

const SET_IMAGES = `${namespace}/SET_IMAGES`;
const UPLOAD_IMAGE = `${namespace}/UPLOAD_IMAGE`;
const DELETE_IMAGE = `${namespace}/DELETE_IMAGE`;

const SUBMIT_FORM = `${namespace}/SUBMIT_FORM`;

const RESET = `${namespace}/RESET`;
const GET_DEPOSIT = `${namespace}/GET_DEPOSIT`;
const SET_LOAD = `${namespace}/SET_LOAD`;

const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_DEPOSIT_ERROR = `${namespace}/SET_DEPOSIT_ERROR`;
const SET_PHOTO_ERROR = `${namespace}/SET_PHOTO_ERROR`;

const CREATE_NEW_CITY = `${namespace}/CREATE_NEW_CITY`;
const SET_CITY_ID = `${namespace}/SET_CITY_ID`;
const GET_ALL_PLACES = `${namespace}/GET_ALL_PLACES`;
const SET_ALL_PLACES = `${namespace}/SET_ALL_PLACES`;
const GET_DUPLICATED_DATA = `${namespace}/GET_DUPLICATED_DATA`;
const SET_DUPLICATED_DATA = `${namespace}/SET_DUPLICATED_DATA`;
const SET_SORTED_IMAGES = `${namespace}/SET_SORTED_IMAGES`;
const SAVE_AND_PREVIEW = `${namespace}/SAVE_AND_PREVIEW`;
const CLEAR_IMAGES = `${namespace}/CLEAR_IMAGES`;
const GET_SPACES_TO_DUPLICATE = `${namespace}/GET_SPACES_TO_DUPLICATE`;
const SET_SPACES_TO_DUPLICATE = `${namespace}/SET_SPACES_TO_DUPLICATE`;
const GET_PLACES_TO_CHECK_NAME = `${namespace}/GET_PLACES_TO_CHECK_NAME`;
const SET_PLACES_TO_CHECK_NAME = `${namespace}/SET_PLACES_TO_CHECK_NAME`;
const SET_ALREADY_CREATED = `${namespace}/SET_ALREADY_CREATED`;
const SET_ALREADY_CREATED_NAME = `${namespace}/SET_ALREADY_CREATED_NAME`;
const SUBMIT_FORM_COWORKING_PARENT = `${namespace}/SUBMIT_FORM_COWORKING_PARENT`;
const SET_PARENT_ID = `${namespace}/SET_PARENT_ID`;
const CREATE_SUB_SPACE = `${namespace}/CREATE_SUB_SPACE`;
const SET_COWORKING_PARENT = `${namespace}/SET_COWORKING_PARENT`;
const SET_SUBSPACES = `${namespace}/SET_SUBSPACES`;
const DELETE_SPACE = `${namespace}/DELETE_SPACE`;
const GET_SUBSPACES_TO_DUPLICATE = `${namespace}/GET_SUBSPACES_TO_DUPLICATE`;
const SET_SUBSPACES_TO_DUPLICATE = `${namespace}/SET_SUBSPACES_TO_DUPLICATE`;
const GET_SUBSPACES = `${namespace}/GET_SUBSPACES`;
const GET_SUBSPACES_NAMES = `${namespace}/GET_SUBSPACES_NAMES`;
const SET_SUBSPACES_NAMES = `${namespace}/SET_SUBSPACES_NAMES`;
const UPLOAD_LOGO = `${namespace}/UPLOAD_LOGO`;

export const createActivitiesFields = createAction(CREATE_ACTIVITIES_FIELDS);
export const createPriceFields = createAction(CREATE_PRICE_FIELDS);
export const setActivitiesFields = createAction(SET_ACTIVITIES_FIELDS);
export const setPriceFields = createAction(SET_PRICE_FIELDS);
export const setPriceActivity = createAction(SET_PRICE_ACTIVITY);

export const setImages = createAction(SET_IMAGES);
export const uploadImages = createAction(UPLOAD_IMAGE);
export const deleteImage = createAction(DELETE_IMAGE);

export const getDeposit = createAction(GET_DEPOSIT);

export const submitForm = createAction(SUBMIT_FORM);
export const resetForm = createAction(RESET);

export const setError = createAction(SET_ERROR);
export const setLoad = createAction(SET_LOAD);
export const setDepositError = createAction(SET_DEPOSIT_ERROR);
export const setPhotoError = createAction(SET_PHOTO_ERROR);
export const createNewCity = createAction(CREATE_NEW_CITY);
export const setCityId = createAction(SET_CITY_ID);
export const getAllPlaces = createAction(GET_ALL_PLACES);
export const setAllPlaces = createAction(SET_ALL_PLACES);
export const getDuplicatedData = createAction(GET_DUPLICATED_DATA);
export const setDuplicatedData = createAction(SET_DUPLICATED_DATA);
export const setSortedImages = createAction(SET_SORTED_IMAGES);
export const saveAndPreview = createAction(SAVE_AND_PREVIEW);
export const clearImages = createAction(CLEAR_IMAGES);
export const getSpacesToDuplicate = createAction(GET_SPACES_TO_DUPLICATE);
export const setSpacesToDuplicate = createAction(SET_SPACES_TO_DUPLICATE);
export const getPlacesToCheckName = createAction(GET_PLACES_TO_CHECK_NAME);
export const setPlacesToCheckName = createAction(SET_PLACES_TO_CHECK_NAME);
export const setAlreadyCreated = createAction(SET_ALREADY_CREATED);
export const setAlreadyCreatedName = createAction(SET_ALREADY_CREATED_NAME);
export const createParentSpace = createAction(SUBMIT_FORM_COWORKING_PARENT);
export const setParentId = createAction(SET_PARENT_ID);
export const createSubSpace = createAction(CREATE_SUB_SPACE);
export const setCoworkingParent = createAction(SET_COWORKING_PARENT);
export const setSubspaces = createAction(SET_SUBSPACES);
export const deleteSpace = createAction(DELETE_SPACE);
export const getSubspaces = createAction(GET_SUBSPACES);
export const getSubspacesToDuplicate = createAction(GET_SUBSPACES_TO_DUPLICATE);
export const setSubspacesToDuplicate = createAction(SET_SUBSPACES_TO_DUPLICATE);
export const getSubspacesNames = createAction(GET_SUBSPACES_NAMES);
export const setSubspacesNames = createAction(SET_SUBSPACES_NAMES);
export const uploadLogo = createAction(UPLOAD_LOGO);

const initialState = {
    activitiesFields: null,
    priceFields: null,
    priceActivity: null,
    images: null,
    load: false,
    depositValue: '',
    error: null,
    depositError: null,
    photoError: null,
    cityId: null,
    allPlaces: null,
    duplicatedData: null,
    spacesToDuplicate: null,
    spacesCheckName: null,
    alreadyCreated: null,
    alreadyCreatedName: null,
    parentId: null,
    coworkingParent: null,
    subspaces: null,
    subspacesToDuplicate: null,
    subspacesNames: null
};
export default handleActions(
    {
        [SET_ACTIVITIES_FIELDS]: (state, { payload }) => ({ ...state, activitiesFields: payload }),
        [SET_IMAGES]: (state, { payload }) => ({ ...state, images: payload }),
        [RESET]: () => initialState,
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [GET_DEPOSIT]: (state, { payload }) => ({ ...state, depositValue: payload }),
        [SET_DEPOSIT_ERROR]: (state, { payload }) => ({ ...state, depositError: payload }),
        [SET_PHOTO_ERROR]: (state, { payload }) => ({ ...state, photoError: payload }),
        [SET_CITY_ID]: (state, { payload }) => ({ ...state, cityId: payload }),
        [SET_ALL_PLACES]: (state, { payload }) => ({ ...state, allPlaces: payload }),
        [SET_DUPLICATED_DATA]: (state, { payload }) => ({ ...state, duplicatedData: payload }),
        [SET_SPACES_TO_DUPLICATE]: (state, { payload }) => ({ ...state, spacesToDuplicate: payload }),
        [SET_PLACES_TO_CHECK_NAME]: (state, { payload }) => ({ ...state, spacesCheckName: payload }),
        [SET_ALREADY_CREATED]: (state, { payload }) => ({ ...state, alreadyCreated: payload }),
        [SET_ALREADY_CREATED_NAME]: (state, { payload }) => ({ ...state, alreadyCreatedName: payload }),
        [SET_PARENT_ID]: (state, { payload }) => ({ ...state, parentId: payload }),
        [SET_COWORKING_PARENT]: (state, { payload }) => ({ ...state, coworkingParent: payload }),
        [SET_SUBSPACES]: (state, { payload }) => ({ ...state, subspaces: payload }),
        [SET_SUBSPACES_TO_DUPLICATE]: (state, { payload }) => ({ ...state, subspacesToDuplicate: payload }),
        [SET_SUBSPACES_NAMES]: (state, { payload }) => ({ ...state, subspacesNames: payload }),
    },
    initialState
);

export const fieldsActivitiesSelector = (state) => state[namespace].activitiesFields;
export const fieldsFeaturesSelector = (state) => state[namespace].featuresFields;
export const fieldsPriceActivitiesSelector = (state) => state[namespace].priceActivity;
export const fieldsPriceSelector = (state) => state[namespace].priceFields;
export const fieldsImagesSelector = (state) => state[namespace].images;
export const createPlaceLoad = (state) => state[namespace].load;
export const securityDeposit = (state) => state[namespace].depositValue;
export const depositErrorSelector = (state) => state[namespace].depositError;
export const photoErrorSelector = (state) => state[namespace].photoError;
export const cityIdSelector = (state) => state[namespace].cityId;
export const allPlacesSelector = (state) => state[namespace].allPlaces;
export const duplicatedDataSelector = (state) => state[namespace].duplicatedData;
export const spacesToDuplicateSelector = (state) => state[namespace].spacesToDuplicate;
export const spacesToCheckNameSelector = (state) => state[namespace].spacesCheckName;
export const alreadyCreatedSelector = (state) => state[namespace].alreadyCreated;
export const alreadyCreatedNameSelector = (state) => state[namespace].alreadyCreatedName;
export const parentIdSelector = (state) => state[namespace].parentId;
export const coworkingParentSelector = (state) => state[namespace].coworkingParent;
export const subspacesSelector = (state) => state[namespace].subspaces;
export const subspacesToDuplicateSelector = (state) => state[namespace].subspacesToDuplicate;
export const subspacesNamesSelector = (state) => state[namespace].subspacesNames;

function* createActivities() {
    while (true) {
        const { payload } = yield take(CREATE_ACTIVITIES_FIELDS);
        const result = payload.reduce((acc, activity) => {
            acc[activity.slug] = false;
            return acc;
        }, {});
        yield put(setActivitiesFields(result));
    }
}

export function* getPlaces({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.get, `/user/spaces-short?page=${payload}`);
        yield put(setAllPlaces(response));
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* deletePlace({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/space/${payload}/delete`);
        if (response === 'success') {
            const places = yield call(resource.get, `/user/spaces-short?page=1`);
            yield put(setAllPlaces(places));
        }
    } catch (err) {
        // console.log(err);
        yield put(popupWarning({ h2: 'Something went wrong', message: '' }));
    } finally {
        yield put(setLoad(false));
    }
}

function* getPlacesCheckName() {
    try {
        const response = yield call(resource.get, `/user/allspaces`);
        yield put(setPlacesToCheckName(response));
    } catch (err) {
        // console.log(err);
    }
}

function* getSpacesForDuplicate({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.get, `/user/search-by-spaces-short`, { ...payload });
        yield put(setSpacesToDuplicate(response));
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* duplicate({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/space/${payload}/copy`);
        yield put(setDuplicatedData(response));
        yield put(setImages(response.gallery));
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* clearImagesHandler() {
    try {
        yield call(resource.post, `/form/clear-cache`);
        yield put(setImages(null));
    } catch (err) {
        // console.log(err);
    }
}

function* uploadImageRequest({ payload }) {
    const { images, setFieldValue } = payload;
    if (images !== null) {
        yield put(setLoad(true));
        const data = new FormData();
        images.forEach((e) => {
            data.append('file[]', e);
        });
        try {
            const response = yield call(resource.post, '/form/images', data);
            if (response && Array.isArray(response) && response?.length) {
                const data = response.map((e) => e.id);
                setFieldValue('file', [...data]);
                yield put(setImages([...response]));
            }
        } catch (err) {
            yield put(popupWarning({ h2: 'You need to upload a photo in the format .jpg, .png, .jpeg, .jpe, .webp or .HEIC' }));
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* uploadLogoHandler({ payload }) {
    const { image, setFieldValue } = payload;
    if (image !== null) {
        yield put(setLoad(true));
        const data = new FormData();
        data.append('file', image);
        try {
            const response = yield call(resource.post, '/form/logo', data);
            setFieldValue('logo', response);
        } catch (err) {
            // console.log('err :>> ', err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* sortImages({ payload }) {
    const { sorted, setFieldValue } = payload;
    yield put(setLoad(true));
    //Array.isArray(response) && response?.length
    try {
        const response = yield call(resource.post, '/form/images-sorted', { sorted });
        if (response && response[1]) {
            const res = Object.keys(response).map((item) => response[item]);
            const data = res.map((e) => e.id);
            setFieldValue('file', [...data]);
            yield put(setImages([...res]));
        }
    } catch (err) {
        yield put(popupWarning({ h2: 'Something went wrong', message: '' }));
    } finally {
        yield put(setLoad(false));
    }
}

function* deleteImageRequest() {
    while (true) {
        const {
            payload: { id, setFieldValue },
        } = yield take(DELETE_IMAGE);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.delete, `/form/images/${id}`);
            if (response && Array.isArray(response) && response?.length) {
                const data = response.map((e) => e.id);
                setFieldValue('file', [...data]);
                yield put(setImages([...response]));
            } else {
                setFieldValue('file', []);
                yield put(setImages([]));
            }
        } catch (err) {
            // console.log('err :>> ', err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* submitFormRequest() {
    while (true) {
        const { payload } = yield take(SUBMIT_FORM);

        try {
            // const convertToStr = payload.floors
            //     .sort(function (a, b) {
            //         return a - b;
            //     })
            //     .join(', '); ///// Convert array of floors to string
            // payload.floors = convertToStr;

            // here remove .00 from price for correct work
            // for (var key in payload.activities) {
            //     if (typeof payload.activities[key] == string' && payload.activities[key].includes('.')) {
            //         let temp = payload.activities[key].slice(0, payload.activities[key].indexOf('.'));
            //         payload.activities[key] = temp;
            //     }
            // }
            const response = yield call(resource.post, '/form', payload);
            if (response) {
                yield put(resetForm());
                yield put(setAlreadyCreated(null));
                yield put(setAlreadyCreatedName(null));
                yield put(
                    push({
                        pathname: '/finish-listing',
                        state: {
                            title: 'Thank you for listing your space.',
                            buttonTitle: 'Go to my account',
                            buttonLink: '/account/spaces',
                        },
                    })
                );
            }
        } catch (err) {
            // console.log('err :>> ', err);
            yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
        }
    }
}

function* submitFormCoworkingParent({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, '/form', payload);
        if (response) {
            yield put(setParentId(response.id));
            yield put(setCoworkingParent(response));
        }
    } catch (err) {
        // console.log('err :>> ', err);
        yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
    } finally {
        yield put(setLoad(false));
    }
}

function* submitFormSubspace({ payload }) {
    // yield put(setLoad(true));
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, '/form', payload);
        const allSubspaces = yield call(resource.get, `/user/get-childs-coworking/${payload?.parent_id}`);
        if (response) {
            yield put(setSubspaces(allSubspaces));
            const parent = yield select(coworkingParentSelector);
            if (parent?.name) {
                yield put(setLoad(false));
                yield put(
                    push({
                        pathname: '/list-space/coworking',
                    })
                );
            } else {
                yield put(setLoad(false));
                yield put(setSubspaces(null));
                yield put(setParentId(null));
                yield put(setCoworkingParent(null));
                yield put(setImages(null));
                yield put(
                    push({
                        pathname: '/finish-listing',
                    })
                );
            }
        }
    } catch (err) {
        // console.log('err :>> ', err);
        yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
    } finally {
        setLoad(false);
    }
}

function* getSubspacesToDuplicateSaga({ payload }) {
    const { name, parentId } = payload;
    try {
        const allSubspaces = yield call(
            resource.get,
            `/user/get-childs-coworking/${parentId}`,
            name ? { ...name } : null
        );
        yield put(setSubspacesToDuplicate(allSubspaces));
    } catch (err) {
        // console.log(err);
    }
}

function* getSubspacesWithPage({ payload }) {
    const { parentId, page } = payload;
    yield put(setLoad(true));
    try {
        const allSubspaces = yield call(resource.get, `/user/get-childs-coworking/${parentId}?page=${page}`);
        yield put(setSubspaces(allSubspaces));
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* saveWithPreview({ payload }) {
    yield put(setLoad(true));
    let win = window.open('/load', '_blank');
    const { values, alreadyCreated } = payload;
    try {
        let response = null;
        if (alreadyCreated) {
            response = yield call(resource.post, `/form-edit/${alreadyCreated}`, values);
        } else {
            response = yield call(resource.post, '/form', values);
        }
        if (response) {
            yield put(setAlreadyCreated(response.id));
            yield put(setAlreadyCreatedName(response.name));
            //window.open(`/preview/${response.id}`, '_blank');
            win.location.href = `/preview/${response.id}`;
        }
    } catch (err) {
        // console.log('err :>> ', err);
        yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
    } finally {
        yield put(setLoad(false));
    }
}

export function* setSecurityDeposit() {
    while (true) {
        const payload = yield take(GET_DEPOSIT);
    }
}

export function* createCity({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, '/addcity', { city: payload });
        const cityId = response.find((item) => item.title === payload).id;
        yield put(setCityId(cityId));
        yield put(setCities(response));
    } catch (err) {
        console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* checkSubspaceName({ payload }) {
    const { parentId } = payload;
    try {
        const response = yield call(resource.get, `/space/check-childs-name/${parentId}`);
        if (response) {
            yield put(setSubspacesNames(response));
        }
    } catch (err) {
        // console.log(err);
    }
}

export function* sagas() {
    yield all([
        createActivities(),
        takeEvery(UPLOAD_IMAGE, uploadImageRequest),
        takeEvery(CREATE_NEW_CITY, createCity),
        takeEvery(GET_ALL_PLACES, getPlaces),
        takeEvery(GET_DUPLICATED_DATA, duplicate),
        takeEvery(SET_SORTED_IMAGES, sortImages),
        takeEvery(CLEAR_IMAGES, clearImagesHandler),
        takeEvery(GET_PLACES_TO_CHECK_NAME, getPlacesCheckName),
        takeLatest(GET_SPACES_TO_DUPLICATE, getSpacesForDuplicate),
        deleteImageRequest(),
        submitFormRequest(),
        setSecurityDeposit(),
        takeEvery(SAVE_AND_PREVIEW, saveWithPreview),
        takeEvery(SUBMIT_FORM_COWORKING_PARENT, submitFormCoworkingParent),
        takeEvery(CREATE_SUB_SPACE, submitFormSubspace),
        takeEvery(DELETE_SPACE, deletePlace),
        takeEvery(GET_SUBSPACES_TO_DUPLICATE, getSubspacesToDuplicateSaga),
        takeEvery(GET_SUBSPACES, getSubspacesWithPage),
        takeEvery(GET_SUBSPACES_NAMES, checkSubspaceName),
        takeEvery(UPLOAD_LOGO, uploadLogoHandler),
    ]);
}

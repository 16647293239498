import { createAction, handleActions } from 'redux-actions';
import { all, call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { activitiesSelector } from './search';
import { push } from 'connected-react-router';
import { popupWarning } from './popupReduser';
import { setAlreadyCreated, setAlreadyCreatedName, setCoworkingParent, setImages, setLoad, setParentId, getPlaces } from './create-place';
import { setCustomAmenities } from './user';

const namespace = 'updatePlace';

const GET_UPDATE_FORM_DATA = `${namespace}/GET_UPDATE_FORM_DATA`;
const SET_UPDATE_FORM_DATA = `${namespace}/SET_UPDATE_FORM_DATA`;

const UPLOAD_IMAGE_UPDATE = `${namespace}/UPLOAD_IMAGE_UPDATE`;
const SET_UPLOAD_IMAGES = `${namespace}/SET_UPLOAD_IMAGES`;
const DELETE_UPLOAD_IMAGE = `${namespace}/DELETE_UPLOAD_IMAGE`;
const SET_COVER_IMAGE = `${namespace}/SET_COVER_IMAGE`;

const SET_LOAD = `${namespace}/SET_LOAD`;

const SUBMIT_UPDATE_FORM = `${namespace}/SUBMIT_UPDATE_FORM`;

const RESET_PLACE_DATA = `${namespace}/RESET_PLACE_DATA`;

const SET_ACTIVITY_FIELDS = `${namespace}/SET_ACTIVITY_FIELDS`;
const SET_PRICE_FIELDS = `${namespace}/SET_PRICE_FIELDS`;
const EDIT_SPACE = `${namespace}/EDIT_SPACE`;
const GET_EDIT_SPACE = `${namespace}/GET_EDIT_SPACE`;
const SORT_IMAGES_EDIT = `${namespace}/SORT_IMAGES_EDIT`;
const EDIT_SPACE_EDIT_PAGE = `${namespace}/EDIT_SPACE_EDIT_PAGE`;
const SET_SAVE_INDICATOR = `${namespace}/SET_SAVE_INDICATOR`;
const EDIT_WITH_PREVIEW = `${namespace}/EDIT_WITH_PREVIEW`;
const CONTINUE_CREATING = `${namespace}/CONTINUE_CREATING`;
const CONTINUE_CREATING_SUBSPACE = `${namespace}/CONTINUE_CREATING_SUBSPACE`;
const APPROVE_FOR_REVIEW = `${namespace}/APPROVE_FOR_REVIEW`;

export const getUpdateFormData = createAction(GET_UPDATE_FORM_DATA);
export const setUpdateFormData = createAction(SET_UPDATE_FORM_DATA);

export const uploadImagesUpdate = createAction(UPLOAD_IMAGE_UPDATE);
export const setUploadImages = createAction(SET_UPLOAD_IMAGES);
export const deleteUploadImage = createAction(DELETE_UPLOAD_IMAGE);
export const setCoverImage = createAction(SET_COVER_IMAGE);

//export const setLoad = createAction(SET_LOAD);
export const submitUpdateForm = createAction(SUBMIT_UPDATE_FORM);
export const resetPlaceData = createAction(RESET_PLACE_DATA);

export const setActivityFields = createAction(SET_ACTIVITY_FIELDS);
export const setPriceFields = createAction(SET_PRICE_FIELDS);
export const setEditSpace = createAction(EDIT_SPACE);
export const setEditEditPage = createAction(EDIT_SPACE_EDIT_PAGE);
export const getEditSpace = createAction(GET_EDIT_SPACE);
export const sortImagesEdit = createAction(SORT_IMAGES_EDIT);
export const setSaveIndicator = createAction(SET_SAVE_INDICATOR);
export const setEditWithPreview = createAction(EDIT_WITH_PREVIEW);
export const setContinueCreating = createAction(CONTINUE_CREATING);
export const setContinueCreatingSubspace = createAction(CONTINUE_CREATING_SUBSPACE);
export const setApproveForReview = createAction(APPROVE_FOR_REVIEW);

const initialState = {
    placeData: null,
    activityFields: null,
    priceFields: null,
    load: false,
    saveIndicator: false,
    continueCreatingStep: null
};

export default handleActions(
    {
        [SET_UPDATE_FORM_DATA]: (state, { payload }) => ({ ...state, placeData: payload }),
        [SET_UPLOAD_IMAGES]: (state, { payload }) => ({ ...state, placeData: { ...state.placeData, photos: payload } }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_PRICE_FIELDS]: (state, { payload }) => ({ ...state, priceFields: payload }),
        [SET_ACTIVITY_FIELDS]: (state, { payload }) => ({ ...state, activityFields: payload }),
        [RESET_PLACE_DATA]: () => initialState,
        [SET_SAVE_INDICATOR]: (state, { payload }) => ({ ...state, saveIndicator: payload }),
        [CONTINUE_CREATING]: (state, { payload }) => ({ ...state, continueCreatingStep: payload }),
    },
    initialState
);

export const updatePlaceDataSelector = (state) => state[namespace];

function* getUpdateFormDataRequest({ payload }) {
    try {
        yield put(setLoad(true));
        const response = yield call(resource.get, `/form-edit/${payload}`);
        const imagesFieldArr = response[0]?.map(item => item.id);
        yield put(setUpdateFormData({...response[1], imagesFieldArr}));
        yield put(setImages(response[0]));
    } catch (err) {
        yield put(popupWarning({ h2: 'Something went wrong', message: '' }));
    } finally {
        yield put(setLoad(false));
    }
}

export function* getDataToEdit({ payload }) {
    const { placeId } = payload;
    try {
        const response = yield call(resource.get, `/form-edit/${placeId}`);
        if (response) {
            //yield put(setUpdateFormData(response));
            yield put(setImages(response[0]));
        }
    } catch (error) {
        // console.log(error);
    }
}

function* editSpace({ payload }) {
    const { values, placeId, noRedirect, editDraft } = payload;
    try {
        yield put(setLoad(true));
        const response = yield call(resource.post, `/form-edit/${placeId}`, { ...values });
        if (response && !noRedirect) {
            yield put(setAlreadyCreated(null));
            yield put(setAlreadyCreatedName(null));
            yield put(setCoworkingParent(null));
            yield put(setParentId(null));
            yield put(setUpdateFormData(null));
            yield put(setContinueCreating(null));
            yield put(
                push({
                    pathname: '/finish-listing',
                })
            );
        } else if(response){
            yield put(setUpdateFormData(response));
            if(editDraft) {
                yield put(setParentId(response.id));
                yield put(setCoworkingParent(response));
                yield put(setContinueCreating(null));
                yield put(setUpdateFormData(null));
            }
        }
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* editSpaceWithPreview({ payload }) {
    const { values, placeId, noRedirect } = payload;
    let win = window.open('/load', '_blank');
    try {
        const response = yield call(resource.post, `/form-edit/${placeId}`, { ...values });
        if (response) {
            win.location.href = `/preview/${response.id}`;
        }
    } catch (err) {
        // console.log(err);
    }
}

function* editSpaceEdit({ payload }) {
    const { values, placeId, setSubmitting } = payload;
    yield put(setLoad(true));
    yield put(setCustomAmenities(JSON.stringify(values.custom_amenities)));
    try {
        const response = yield call(resource.post, `/form-edit/${placeId}`, { ...values });
        if (response) {
            const res = yield call(resource.get, `/form-edit/${placeId}`);
            yield put(setUpdateFormData(res[1]));
            yield put(setImages(res[0]));
            setSubmitting(false);
            yield put(setLoad(false));
            yield put(setSaveIndicator(true));
            yield delay(3000);
            yield put(setSaveIndicator(false));
        }
    } catch (err) {
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* continueCreatingSubspace({ payload }) {
    const { values, placeId, setSubmitting } = payload;
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/form-edit/${placeId}`, { ...values });
        if (response) {
            setSubmitting(false);
            yield put(setLoad(false));
            yield put(setParentId(null));
            yield put(setCoworkingParent(null));
            yield put(setImages(null));
            yield put(setContinueCreating(null));
            yield put(setUpdateFormData(null));
            yield put(push({
                pathname: '/finish-listing',
            }));
            
        }
    } catch (err) {}
}

function* cancelUpdatePlace() {
    while (true) {
        const { payload } = yield take(RESET_PLACE_DATA);
        try {
            yield put(setLoad(true));
            const response = yield call(resource.get, `/space-form/${payload}`);
            yield put(setUpdateFormData(response));
        } catch (err) {
            // console.log(err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* uploadImageRequest({ payload }) {
    const { images, setFieldValue, placeId } = payload;
    yield put(setLoad(true));
    const data = new FormData();
    images.forEach((e) => {
        data.append('file[]', e);
    });
    try {
        const response = yield call(resource.post, `/form-edit/images/${placeId}`, data);
        if (response && Array.isArray(response) && response?.length) {
            const data = response.map((e) => e.id);
            setFieldValue('file', [...data]);
            // setFieldValue('photos', 1);
            //yield put(setUploadImages(response));
            yield put(setImages([...response]));
            yield put(setLoad(false));
            yield put(setSaveIndicator(true));
            yield delay(3000);
            yield put(setSaveIndicator(false));
        }
    } catch (err) {
        // console.log('err :>> ', err);
        // yield put(setLoad(false));
    } finally {
        yield put(setLoad(false));
    }
}

function* deleteImages({ payload }) {
    const { id, setFieldValue, placeId } = payload;
    yield put(setLoad(true));
    try {
        const response = yield call(resource.delete, `/form-edit/images/${placeId}/${id}`);
        if (response && Array.isArray(response) && response?.length) {
            const data = response.map((e) => e.id);
            setFieldValue('file', [...data]);
            yield put(setImages([...response]));
        } else {
            setFieldValue('file', []);
            yield put(setImages([]));
        }
    } catch (error) {
        // console.log(error);
    } finally {
        yield put(setLoad(false));
        yield put(setSaveIndicator(true));
        yield delay(3000);
        yield put(setSaveIndicator(false));
    }
}

function* sortImages({ payload }) {
    const { sorted, setFieldValue, placeId } = payload;
    yield put(setLoad(true));
    //Array.isArray(response) && response?.length
    try {
        const response = yield call(resource.post, `/form-edit/images-sorted/${placeId}`, { sorted });
        if (response && response[1]) {
            const res = Object.keys(response).map((item) => response[item]);
            const data = res.map((e) => e.id);
            setFieldValue('file', [...data]);
            yield put(setImages([...res]));
        }
    } catch (err) {
        //yield put(popupWarning({ h2: 'Something went wrong', message: '' }));
    } finally {
        yield put(setLoad(false));
        yield put(setSaveIndicator(true));
        yield delay(3000);
        yield put(setSaveIndicator(false));
    }
}

function* approveForReview({payload}) {
    const { placeId } = payload;
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/space/${placeId}/approve`);
        if(response) {
            yield call(getPlaces, {page: 1});
        }
    } catch (err) {
        
    } finally {
        yield put(setLoad(false));
    }
}

// function* deleteImageRequest() {
//     while (true) {
//         const {
//             payload: { id, setFieldValue },
//         } = yield take(DELETE_UPLOAD_IMAGE);
//         yield put(setLoad(true));
//         try {
//             const response = yield call(resource.post, `/space-form/images/delete/${id}`);

//             if (response && Array.isArray(response) && response?.length) {
//                 // const data = response.map((e) => e.id);
//                 yield put(setUploadImages(response));
//             } else {
//                 setFieldValue('photos', 0)
//                 yield put(setUploadImages(response));
//             }
//         } catch (err) {
//             console.log('err :>> ', err);
//         } finally {
//             yield put(setLoad(false));
//         }
//     }
// }

function* setCoverImageRequest() {
    while (true) {
        const { payload } = yield take(SET_COVER_IMAGE);
        const { id, space } = payload;

        yield put(setLoad(true));
        try {
            const response = yield call(resource.post, `/space-form/images/${space}/setMain/${id}`);

            if (response && Array.isArray(response) && response?.length) {
                yield put(getUpdateFormData(space));
            }
        } catch (err) {
            // console.log('err :>> ', err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* submit() {
    while (true) {
        const { payload } = yield take(SUBMIT_UPDATE_FORM);
        const { values, placeId, setSubmitting, setTouched } = payload;
        yield put(setLoad(true));

        // here remove .00 from price for correct work
        for (var key in values.activities) {
            if (typeof values.activities[key] == 'string' && values.activities[key].includes('.')) {
                let temp = values.activities[key].slice(0, values.activities[key].indexOf('.'));
                values.activities[key] = temp;
            }
        }

        try {
            if (Array.isArray(payload.values.floors)) {
                const convertToStr = payload.values.floors
                    .sort(function (a, b) {
                        return a - b;
                    })
                    .join(', '); ///// Convert array of floors to string
                payload.values.floors = convertToStr;
            }
            const response = yield call(resource.post, '/form', {
                ...values,
                placeId,
            });
            if (typeof response.floor == 'string') {
                response.floor = response.floor
                    .replace(/\s+/g, '')
                    .split(',')
                    .map((item) => +item);
            }
            yield put(getUpdateFormData(placeId));
            yield put(
                push({
                    pathname: '/thanks',
                    state: {
                        title: 'Thank you! Your revised listing has been submitted and is pending approval.',
                        //description: 'One of our agents will be in touch shortly. Please call for urgent requests.',
                        buttonTitle: 'Go to my locations',
                        buttonLink: '/account/spaces',
                        // phone: `${response.user.phoneNumber}`,
                    },
                })
            );
        } catch (err) {
            yield put(popupWarning({h2: 'Invalid Data', message: 'Something went wrong. Please try again.', showImg: true}));
            // console.log(err);
        } finally {
            setTouched({});
            setSubmitting(false);
            yield put(setLoad(false));
        }
    }
}

function* createFilds(payload) {
    const activities = yield select(activitiesSelector);
    const ids = payload?.activities?.map((e) => e.id);
    const activityFields = activities.reduce((acc, e) => {
        const result = ids.some((p) => e.id === p);
        const type = result && payload?.activities?.find((a) => a.id === e.id);
        acc = { ...acc, [e.slug]: type.type ? type.type : result };
        return acc;
    }, {});
    const priceFields = ids.reduce((acc, e) => {
        const activity = payload?.activities?.find((i) => i.id === +e);
        return { ...acc, [e]: activity ? activity.price : 0 };
    }, {});

    yield put(setActivityFields(activityFields));
    yield put(setPriceFields(priceFields));
    yield put(setUpdateFormData(payload));
}

export function* sagas() {
    return yield all([
        takeEvery(GET_UPDATE_FORM_DATA, getUpdateFormDataRequest),
        takeEvery(UPLOAD_IMAGE_UPDATE, uploadImageRequest),
        //deleteImageRequest(),
        takeEvery(DELETE_UPLOAD_IMAGE, deleteImages),
        submit(),
        cancelUpdatePlace(),
        setCoverImageRequest(),
        takeEvery(EDIT_SPACE, editSpace),
        takeEvery(GET_EDIT_SPACE, getDataToEdit),
        takeEvery(SORT_IMAGES_EDIT, sortImages),
        takeEvery(EDIT_SPACE_EDIT_PAGE, editSpaceEdit),
        takeEvery(EDIT_WITH_PREVIEW, editSpaceWithPreview),
        takeEvery(CONTINUE_CREATING_SUBSPACE, continueCreatingSubspace),
        takeEvery(APPROVE_FOR_REVIEW, approveForReview)
    ]);
}

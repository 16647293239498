import getDaysInMonth from 'date-fns/getDaysInMonth';
import { format } from 'date-fns';

export const convertTo24Format = (s) => {
    if (!s) return;
    const hr = s.split(' ')[0].split(':')[0]
    const min = s.split(' ')[0].split(':')[1]
    const am = s.split(' ')[1]
    if (am === 'AM' && hr === '12') {
        return `00:${min}`;
    }
    if (am === 'PM' && hr === '12') {
        return `12:${min}`;
    }
    if (am === 'PM') {
        return `${+hr + 12}:${min}`;
    }
    return `${hr}:${min}`;
};

export const convertToAmFormat = (s) => {
    if (!s) return;
    const hr = s.split(':')[0]
    const min = s.split(':')[1]
    if (hr === '00') {
        return `12:${min} AM`;
    }
    if (hr === '12') {
        return `12:${min} PM`;
    }
    if (Number(hr) < 12) {
        return `${hr}:${min} AM`;
    }
    if (Number(hr) > 12 && Number(hr) < 22) {
        return `0${hr - 12}:${min} PM`;
    }
    if (Number(hr) >= 22) {
        return `${hr - 12}:${min} PM`;
    }
};

export const addMonthsToDate = (start, months) => {
    const year = start.getFullYear();
    const month = start.getMonth();
    const day = start.getDate();
    const noOfMonths = parseInt(months);
    const endMonthDate = new Date(year, month + noOfMonths);
    const days = getDaysInMonth(endMonthDate);
    if ((day === 31 || day === 30 || day === 29) && (days === 28 || days === 29 || days === 30)) {
        return new Date(year, month + noOfMonths, days);
    } else {
        return new Date(year, month + noOfMonths, day);
    }
};

export const getMinutesFrom24Format = (s) => {
    if (!s) return;
    const hr = s.split(':')[0];
    const minutes = s.split(':')[1];
    return hr * 60 + +minutes;
}

export const checkTime = (startTime, endTime) => {
    if (
        startTime &&
        endTime &&
        getMinutesFrom24Format(convertTo24Format(endTime)) -  getMinutesFrom24Format(convertTo24Format(startTime)) <=
            0
    ) {
        if (endTime.includes('AM') && endTime.startsWith('12')) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

export const lessThenHour = (startTime, endTime) => {
    const startMinutes = getMinutesFrom24Format(convertTo24Format(startTime));
    const endMinutes = endTime === '12:00 AM' ? 24 * 60 : getMinutesFrom24Format(convertTo24Format(endTime))
    return endMinutes - startMinutes < 60;
}

export const getHoursFromQuery = (hours) => {
    if(!hours) return '';
    return `${hours.slice(0,2)}:${hours.slice(2)}`
}

export const getTimeState = (state) => {
    const hours = state ? state?.split(' ')[0]?.split(':')[0] : '';
    const minutes = state ? state?.split(' ')[0]?.split(':')[1] : '';
    const am = state ? state?.split(' ')[1] : 'AM';
    return {hours, minutes, am};
}

export const getTime = (time) => {
    if (!time[0] && !time[1]) {
        return 'closed';
    } else if (time[0] === '00:00' && time[1] === '00:00') {
        return '24 hours';
    } else {
        return `${convertToAmFormat(time[0])} - ${convertToAmFormat(time[1])}`;
    }
};

export function getDisabledDates(available) {
    const av = JSON.parse(available);
    const blockDays = [];
    for (let i = 0; i < av.length; i++) {
        if (av[i][0] == null && av[i][1] == null) {
            blockDays.push(i);
        }
    }
    let res = [];
    for (const day of blockDays) {
        res.push(returnBlock(day));
    }

    return res.flat();
}


function returnBlock(day) {
    let d = new Date(),
        year = d.getYear(),
        days = [];

    d.setDate(1);
    while (d.getDay() !== day) {
        d.setDate(d.getDate() + 1);
    }

    while (d.getYear() <= year + 3) {
        var pushDate = new Date(d.getTime());
        days.push(new Date(pushDate.getMonth() + 1 + '/' + pushDate.getDate() + '/' + pushDate.getFullYear()));
        d.setDate(d.getDate() + 7);
    }
    return days;
}

export function getDisabledDatesString(available) {
    const av = JSON.parse(available);
    const blockDays = [];
    for (let i = 0; i < av.length; i++) {
        if (av[i][0] == null && av[i][1] == null) {
            blockDays.push(i);
        }
    }
    let res = [];
    for (const day of blockDays) {
        res.push(returnBlockString(day));
    }

    return res.flat();
}

function pad(s){ return ('00' + s).slice(-2)};

function returnBlockString(day) {
    let d = new Date(),
        year = d.getYear(),
        days = [];

    d.setDate(1);
    while (d.getDay() !== day) {
        d.setDate(d.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (d.getYear() <= year + 3) {
        var pushDate = new Date(d.getTime());
        days.push(pad(pushDate.getMonth() + 1) + '/' + pad(pushDate.getDate()) + '/' + pushDate.getFullYear());
        d.setDate(d.getDate() + 7);
    }
    return days;
}

export function findFirstWorkingDay(weekendsArray) {
    if(!weekendsArray){
        return new Date();
    }
    const arr = [];
    for(let i = 0; i < 7; i++) {
        let day = new Date();
        day.setDate(day.getDate() + i);
        arr.push(day)
    }
    const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', year: 'numeric', month: '2-digit' });
    for(let date of arr){
        if(!weekendsArray?.includes(formatter.format(date))) {
            return date;
        }
    }   
    return new Date();
}

export function blockTime(date, startAmFormat, endAmFormat, available) {
    if (date && startAmFormat && endAmFormat && available) {
        const start = convertTo24Format(startAmFormat);
        let end = convertTo24Format(endAmFormat);
        let startMinutes = Number(start.split(':')[0]) * 60 + Number(start.split(':')[1]);
        let endMinutes = Number(end.split(':')[0]) * 60 + Number(end.split(':')[1]);
        if (Number(end.split(':')[0]) === 0) {
            endMinutes = 24 * 60;
        }
        const day = date.getDay();
        const av = JSON.parse(available)?.[day];
        if (av[0] === '00:00' && av[1] === '00:00') {
            return false;
        }
        const startWork = Number(av[0].split(':')[0]) * 60 + Number(av[0].split(':')[1]);
        let endWork = Number(av[1].split(':')[0]) * 60 + Number(av[1].split(':')[1]);
        if (Number(av[1].split(':')[0]) === 0) {
            endWork = 24 * 60;
        }
        if (startMinutes < startWork || startMinutes > endWork - 1 || endMinutes > endWork) {
            return true;
        } else {
            return false;
        }
    }
}

export const getCheckDate = (date) => {
    const month = date?.split('-')[1].startsWith('0') ? date?.split('-')[1].slice(0, 1) : date?.split('-')[1];
    const day = date?.split('-')[0].startsWith('0') ? date?.split('-')[0].slice(0, 1) : date?.split('-')[0];
    const year = date?.split('-')[2];
    return `${month}/${day}/${year}`;
};

export function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate);
    const end = new Date(endDate);

    const dates = [];
    function pad(s){ return ('00' + s).slice(-2)};

    while (date <= end) {
        dates.push(pad(date.getMonth() + 1) + '/' + pad(date.getDate()) + '/' + date.getFullYear());
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

export function diff(start, end) {
    if (start && end) {
        const startAm = start.split(' ')[1];
        const endAm = end.split(' ')[1];
        start = parseInt(start?.slice(0, 2));
        end = parseInt(end?.slice(0, 2));
        if (startAm === 'AM') {
            if (start === 12) {
                start = 0;
            }
        } else if (startAm === 'PM') {
            if (start === 12) {
                start = 12;
            } else {
                start += 12;
            }
        }
        if (endAm === 'AM') {
            if (end === 12) {
                end = 24;
            }
        } else if (endAm === 'PM') {
            if (end === 12) {
                end = 12;
            } else {
                end += 12;
            }
        }
        return end - start;
    }
}

export const addColonToAmDate = (date, am) => {
    return `${date?.slice(0,2)}:${date?.slice(2)} ${am}`
}

export const getArrayOfDates = (start, end) => {
    let D = new Date(start)
    let Till = new Date(end)
    let result = []
;

function pad(s){ return ('00' + s).slice(-2)}

while( D.getTime() <= Till.getTime()) {
  result.push( '' + pad(D.getMonth()+1) + '/' + pad(D.getDate()) + '/' + D.getFullYear());
  D.setDate( D.getDate()+1);
}
return result;
}

export const dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
  }

export const getBookedDaysDailyString = (bookings) => {
    let dates = [];
    bookings.forEach(item => {
        const start = item.date_details.split('-')[0]
        const end = item.date_details.split('-')[1]
        let res = getDatesInRange(start, end)
        dates = dates.concat(res)
    })
    return dates;
}

export const getBookedMonthlyString = (bookings) => {
    let dates = [];
    bookings.forEach(item => {
        const start = item?.start_date?.split(' ')[0];
        const end = item?.end_date?.split(' ')[0];
        const startFormatted = `${start.split('-')[1]}/${start.split('-')[2]}/${start.split('-')[0]}`;
        const endFormatted = `${end.split('-')[1]}/${end.split('-')[2]}/${end.split('-')[0]}`;
        let res = getDatesInRange(startFormatted, endFormatted)
        dates = dates.concat(res)
    })
    return dates;
}

export const convertToMiliseconds = (time) => {
    const timeParts = time.split(":");
    return (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000);
}

export const checkIsAvailableHourly = (hourlyBookings, date, startTime, endTime) => {
    const dateOfBooking = format(date, 'MM/dd/yyyy');
    const formattedBookings = hourlyBookings.map(item => {
        const date = item.date_details.split(' ')[1];
        const time = item.date_details.split(' ')[0];
        const start = time.split('-')[0];
        const end = time.split('-')[1];
        const startTime = convertTo24Format(`${start.slice(0,2)}:${start.slice(2,4)} ${start.slice(4,6)}`);
        const endTime = convertTo24Format(`${end.slice(0,2)}:${end.slice(2,4)} ${end.slice(4,6)}`);
        return {
            date, startTime, endTime
        }
    });
    const dayBookings = formattedBookings.filter(item => item.date === dateOfBooking);
    if(!dayBookings?.length) {
        return true;
    }
    const chosenStart = convertToMiliseconds(convertTo24Format(startTime));
    const chosenEnd = convertToMiliseconds(convertTo24Format(endTime));

    for(let booking of dayBookings) {
        const startMs = convertToMiliseconds(booking.startTime);
        const endMs = convertToMiliseconds(booking.endTime);
        if(!(chosenStart >= endMs || chosenEnd <= startMs)) {
            return false;
        }
    }
    return true;
}
import { countServiceFee } from '../../../utils/helpers/helper';
import { diff, getArrayOfDates } from '../../../utils/helpers/timeHelpers';
import { format } from 'date-fns';

export const countIndividual = (place, dateState, disabledDates, quantity=1) => {
   
    //hourly
   const numberOfHours = Math.abs(diff(dateState?.time?.startTime, dateState?.time?.endTime));
   const hourlyTimelineCents = place?.price_per_hour * numberOfHours * quantity;
   const hourlyTimeline = hourlyTimelineCents / 100;
   const hourlyTotalCents = hourlyTimelineCents + countServiceFee(hourlyTimelineCents);
   const hourlyTotal = (hourlyTimelineCents + countServiceFee(hourlyTimelineCents)) / 100;

   //daily
   const days = dateState?.type === 'daily' ? getArrayOfDates(dateState?.data?.[0]?.startDate, dateState?.data?.[0]?.endDate) : [];
   const workingDays = days.filter(day => !disabledDates?.includes(day));
   const numberOfDays = workingDays.length;
      // Math.ceil((dateState?.data?.[0]?.endDate.getTime() - dateState?.data?.[0]?.startDate.getTime()) / (1000 * 3600 * 24)) + 1;
   const dailyTimelineCents = place.price_per_day * numberOfDays * quantity;
   const dailyTimeline = dailyTimelineCents / 100;
   const dailyTotalCents = dailyTimelineCents + countServiceFee(dailyTimelineCents);
   const dailyTotal = (dailyTimelineCents + countServiceFee(dailyTimelineCents)) / 100;

   //monthly
   const monthyTimelineCents = place?.price_per_month * quantity;
   const monthlyTimeline = monthyTimelineCents / 100;
   const monthlyDepositCents = place?.capturePrice * quantity;
   const monthlyDeposit = monthlyDepositCents / 100;
   const monthlyTotal = (monthyTimelineCents + monthlyDepositCents + countServiceFee(monthyTimelineCents)) / 100;
   const monthlyTotalCents = monthyTimelineCents + monthlyDepositCents + countServiceFee(monthyTimelineCents);
   const monthlyRecurring = (monthyTimelineCents + countServiceFee(monthyTimelineCents)) / 100;

   const monthlyServiceFee = countServiceFee(monthyTimelineCents) / 100;
   const dailyServiceFee = countServiceFee(dailyTimelineCents) / 100;
   const hourlyServiceFee = countServiceFee(hourlyTimelineCents) / 100;

   if(dateState.type === 'hourly') return {timeline: hourlyTimeline, totalCents: hourlyTotalCents, total: hourlyTotal, serviceFee: hourlyServiceFee, number: numberOfHours};
   if(dateState.type === 'daily') return {timeline: dailyTimeline, totalCents: dailyTotalCents, total: dailyTotal, serviceFee: dailyServiceFee, number: numberOfDays};
   if(dateState.type === 'monthly') return {timeline: monthlyTimeline, deposit: monthlyDeposit, totalCents: monthlyTotalCents, total: monthlyTotal, recurring: monthlyRecurring, serviceFee: monthlyServiceFee};
   return {timeline: 0, deposit: 0, totalCents: 0, total: 0, recurring: 0, serviceFee: 0}
}


//calendarState - Date object, dailyState and monthlyState array with object [{startDate: Date, endDate: Date}], startAmFormat, endAmFormat '11:45 AM'
export const setSpaceToLocalStorage = (id, type, calendarState, startAmFormat, endAmFormat, dailyState, monthlyState, inputValue) => {
    if(type === 'hourly') {
    localStorage.setItem(
        id,
        JSON.stringify({
            pricingType: 'hourly',
            hourlyDateVal: format(calendarState, 'MM/dd/yyyy'),
            hourlyStartTime: startAmFormat.split(' ')[0].replace(':', ''),
            hourlyEndTime: endAmFormat.split(' ')[0].replace(':', ''),
            startAM: startAmFormat.split(' ')[1],
            endAM: endAmFormat.split(' ')[1],
        })
    );
    }
    if(type === 'daily') {
        localStorage.setItem(
            id,
            JSON.stringify({
                pricingType: 'daily',
                dailyStartVal: format(dailyState[0].startDate, 'MM/dd/yyyy'),
                dailyEndVal: format(dailyState[0].endDate, 'MM/dd/yyyy'),
            })
        );
    }
    if(type === 'monthly') {
        localStorage.setItem(
            id,
            JSON.stringify({
                pricingType: 'monthly',
                monthlyStartVal: format(monthlyState, 'MM/dd/yyyy'),
                numberOfMonths: inputValue,
            })
        );
    }

}
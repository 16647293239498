import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styles from './input-control.module.sass';

/**
 *
 *  The  params with Formik https://formik.org/docs/api/
 */

const RadioButtonControl = ({
    className,
    label,
    checked,
    activeClassName,
    labelClassName,
    value,
    onClick,
    ...props
}) => {
    const htmlFor = props.id ? props.id : `radio-${Math.random()}`;
    const attrs = props.field
        ? { onChange: props.field.onChange, onBlur: props.field.onBlur, name: props.field.name }
        : { ...props };
    const selected = props.form ? props.form.values[attrs.name] && value === props.form.values[attrs.name] : checked;

    return (
        <div
            className={ClassNames(styles.radio__wrapper, className, { [activeClassName]: selected })}
            onClick={onClick}
        >
            <input id={htmlFor} className={styles.radio} {...attrs} type="radio" checked={selected} value={value} />
            <label htmlFor={htmlFor} className={ClassNames(styles.radio__label, labelClassName)}>
                {label}
            </label>
        </div>
    );
};
RadioButtonControl.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    activeClassName: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.string,
};
RadioButtonControl.defaultProps = {
    className: '',
    checked: false,
    labelClassName: '',
    onClick: () => {},
    value: '',
};
export default RadioButtonControl;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './button.module.sass';

const Button = React.forwardRef(({ children, onClick, className, disabled, active, ...attrs }, ref) => {
    const onClickAction = (e) => {
        if (disabled) {
            e.preventDefault();
        } else {
            return onClick(e);
        }
    };

    const classes = classNames(styles.btn,className, active );
    const Tag = attrs.href ? 'a' : 'button';

    return (
        <Tag ref={ref} className={classes} disabled={disabled} onClick={onClickAction} {...attrs}>
            {children}
        </Tag>
    );
});

Button.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
};

Button.defaultProps = {
    children: 'Default button',
    onClick: () => {},
    className: '',
    disabled: false,
    active: false,
};

export default Button;

import Http from './Http';
import { store } from '../../';
import { setToken } from '../../store/reducers/auth';
import { logoutAction } from '../../store/reducers/auth';

export default Http;

export const resource = new Http();

function getStore(payload) {
    if (store) {
        const { dispatch } = store;
        dispatch(setToken(payload));
    }
}
function logout() {
    if (store) {
        const { dispatch } = store;
        dispatch(logoutAction());
    }
}
resource.setOptions({
    refreshUrl: '/refresh',
    refreshCallback: (payload) => getStore(payload),
    logout,
});

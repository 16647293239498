import React from 'react';
import styles from './input-control.module.sass';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import Image from '../image';
import infoIcon from './info.svg';
import { useMedia } from 'use-media';
import mobileInfo from './mobile-info.svg';

const PriceInput = ({ label, placeholder, className, square, info, setDepositError, depositError, hourly, daily, monthly, hotDesk, ...props }) => {
    const isMobile = useMedia({ maxWidth: '768px' });
    
    const onChange = (e) => {
        if (square) {
            props.form.setFieldValue(props.field.name, e.target.value.replace(' sqft.', ''));
        } else {
            props.form.setFieldValue(props.field.name, e.target.value.replace('$', ''));
        }
    };
    const [infoOpen, setInfoOpen] = React.useState(false);
    React.useEffect(() => {
        if (infoOpen) {
            document.body.style.overflow = 'hidden';        
        } else {
            document.body.style.overflow = '';       
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [infoOpen]);
    React.useEffect(() => {
        if (props.field.name === 'capturePrice') {
            if (props?.field?.value > props?.form?.values?.pricing?.monthly * 1.5) {
                setDepositError('The security deposit cannot be greater than 1.5 times the cost per month to book.');
            } else {
                setDepositError('');
            }
        }
    }, [props?.field?.value, props?.form?.values?.pricing?.monthly]);
    
    React.useEffect(() => {
        if (!hourly && props?.field?.name === 'pricing.hourly') {
            props.form.setFieldValue(props.field.name, '');
        }
        if (
            !daily &&
            (props?.field?.name === 'pricing.daily' ||
            props?.field?.name === 'price_per_day' ||
            props?.field?.name === 'available_day_quantity' && !hotDesk)
        ) {
            props.form.setFieldValue(props.field.name, '');       
        }
        
        if (
            !monthly &&
            (props?.field?.name === 'pricing.monthly' ||
                props?.field?.name === 'price_per_month' ||
                props?.field?.name === 'available_month_quantity')
        ) {
            props.form.setFieldValue(props.field.name, '');
            props.form.setFieldValue('capturePrice', '');
        }
    }, [hourly, daily, monthly]);
    return (
        <div className={classNames(styles.price, className)}>
            {infoOpen && <MobileInfo setInfoOpen={setInfoOpen} />}
            {info && (
                <div
                    className={styles.info}
                    onClick={() => {
                        if (isMobile) {
                            setInfoOpen(true);
                        }
                    }}
                >
                    <Image src={infoIcon} />
                    <div className={styles.infoPopup}>{info}</div>
                </div>
            )}
            <label
                className={classNames(styles.price__label, {
                    [styles.label__filled]: props?.field?.value,
                    [styles.label__error]: depositError,
                })}
            >
                {label}
            </label>
            <NumberFormat
                prefix={(square || hotDesk ) ? '' : '$'}
                suffix={square ? ' sqft.' : ''}
                allowNegative={false}
                decimalScale={(square || hotDesk) ? 0 : 2}
                className={classNames(styles.price__input, {
                    [styles.active]: props?.field?.value,
                    [styles.price__error]: depositError,
                })}
                placeholder={placeholder}
                name={props?.field?.name}
                value={props?.field?.value}
                onChange={onChange}
            />
            {depositError && <p className={styles.depositError}>{depositError}</p>}
        </div>
    );
};

export default PriceInput;

const MobileInfo = ({setInfoOpen}) => {
    return (
        <>
        <div className={styles.overlay} onClick={() => setInfoOpen(false)}/>
            <div className={styles.mobileInfo}><img className={styles.mobileIcon} src={mobileInfo}/><p className={styles.mobileInfoText}>Only monthly bookings can have a set security deposit. The deposit amount cannot exceed 1.5x the price per month.</p></div>
            </>
    )
}

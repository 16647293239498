import React from 'react';
import { ReactComponent as Wifi } from "./assets/amenities/wifi.svg";
import { ReactComponent as Access } from "./assets/amenities/access.svg";
import { ReactComponent as Drinks } from "./assets/amenities/drinks.svg";
import { ReactComponent as WhiteBoard } from "./assets/amenities/whiteboard.svg";
import { ReactComponent as Kitchen } from "./assets/amenities/kitchen.svg";
import { ReactComponent as Parking } from "./assets/amenities/parking.svg";
import { ReactComponent as PetFriendly } from "./assets/amenities/petFriendly.svg";
import { ReactComponent as Printer } from "./assets/amenities/printer.svg";
import { ReactComponent as Bathrooms } from "./assets/amenities/bathrooms.svg";
import { ReactComponent as NursingRoom } from "./assets/amenities/nursingRoom.svg";
import { ReactComponent as Security } from "./assets/amenities/security.svg";
import { ReactComponent as Furnished } from "./assets/amenities/furnished.svg";
import { ReactComponent as Keyless } from "./assets/amenities/keyless.svg";
import { ReactComponent as Desk } from "./assets/amenities/desk.svg";
import { ReactComponent as Mail } from "./assets/amenities/mail.svg";
import { ReactComponent as Monitor } from "./assets/amenities/monitor.svg";
import { ReactComponent as Video } from "./assets/amenities/video.svg";
import { ReactComponent as Other } from "./assets/amenities/other.svg";
import { ReactComponent as Fully } from "./assets/amenities/fully.svg";
import { ReactComponent as Projector } from "./assets/amenities/projector.svg";
import { ReactComponent as Stage } from "./assets/amenities/stage.svg";
import { ReactComponent as Chairs } from "./assets/amenities/chairs.svg";
import styles from './create-place.module.sass';
export const kindOfSpace = [
    {
        value: 'Warehouse',
        label: 'Warehouse',
    },
    {
        value: 'Corporate office',
        label: 'Corporate office',
    },
];
export const maximumOccupancy = [
    { label: '1-10', value: '1-10' },
    { label: '11-25', value: '11-25' },
    { label: '26-50', value: '26-50' },
    { label: '51-99', value: '51-99' },
    { label: '100+', value: '100+' },
];

export const floors = new Array(100).fill().map((e, i) => {
    return { label: i + 1, value: i + 1 };
});

export const amenities = [
    'featuresKitchen',
    'featuresFloors',
    'featuresElevators',
    'wifi',
    'hvac',
    'fullyFurnished',
    'parking',
    'privetMeetingRooms',
    'privateFloor',
    'concierge',
    'security',
    'bathrooms',
    'access',
    'petFriendly',
];

export const amenitiesList = [
    {title: 'WiFi', img: <Wifi className={styles.icon}/>, name: "wifi"},
     {title: 'Accessibility', img: <Access className={styles.icon}/>, name: "access"},
     {title: 'Coffee/Tea/Water', img: <Drinks className={styles.icon}/>, name: "office_drinks"},
    {title: 'Reception desk', img: <Desk className={styles.icon}/>, name: "reception_desk"},
     {title: 'Kitchen', img: <Kitchen className={styles.icon}/>, name: "kitchen"},
    {title: 'Parking', img: <Parking className={styles.icon}/>, name: "parking"},
    {title: 'Pet friendly', img: <PetFriendly className={styles.icon}/>, name: "petFriendly"},
    {title: 'TV Monitor', img: <Monitor className={styles.icon}/>, name: "tv_monitor"},
    {title: 'Nursing room', img: <NursingRoom className={styles.icon}/>, name: "nursing_room"},
    {title: 'Security', img: <Security className={styles.icon}/>, name: "security"},
     {title: 'Receive mail', img: <Mail className={styles.icon}/>, name: "mail"},
     {title: 'Keyless entry', img: <Keyless className={styles.icon}/>, name: "keyless"},
     {title: 'Fully furnished', img: <Furnished className={styles.icon}/>, name: "fullyFurnished"},
     {title: 'Bathrooms', img: <Bathrooms className={styles.icon}/>, name: "bathrooms"},
     {title: 'White board', img: <WhiteBoard className={styles.icon}/>, name: "white_board"},
    {title: 'Print/Scan/Copy', img: <Printer className={styles.icon}/>, name: "printer"},    
    {title: 'Video/Voice Conferencing', img: <Video className={styles.icon}/>, name: "video"},
]
export const childAmenities = [
    {title: 'Fully furnished', img: <Furnished className={styles.icon}/>, name: "fullyFurnished"},
    {title: 'Video/Voice Conferencing', img: <Video className={styles.icon}/>, name: "video"},
    {title: 'TV Monitor', img: <Monitor className={styles.icon}/>, name: "tv_monitor"},
    {title: 'White board', img: <WhiteBoard className={styles.icon}/>, name: "white_board"},
    {title: 'Accessibility', img: <Access className={styles.icon}/>, name: "access"},
]


export const days = [
    { full: 'Sunday', short: 'sun' },
    { full: 'Monday', short: 'mon' },
    { full: 'Tuesday', short: 'tue' },
    { full: 'Wednesday', short: 'wed' },
    { full: 'Thursday', short: 'thu' },
    { full: 'Friday', short: 'fri' },
    { full: 'Saturday', short: 'sat' },
];

const convertToAMPMFormat = (s) => {
    
    if (!s) return;

    const res = s.split(':');
    if (res[0] === '00') {
        return '12:00 AM';
    }
    if (res[0] === '12') {
        return '12:00 PM';
    }
   
    // if (res[0][0] === '0') {
    //     return res[0][1] + ':00 AM';
    // }
    if (res[0] < 12) {
        return res[0] + ':00 AM';
    }
    if(res[0] > 21) {
        return res[0] - 12 + ':00 PM';
    }
    if (res[0] > 12) {
        return '0' + (res[0] - 12) + ':00 PM';
    }
};
// export const getTime = (time) => {
//     if (!time[0] && !time[1]) {
//         return 'closed';
//     } else if (time[0] === '00:00' && time[1] === '00:00') {
//         return '24 hours';
//     } else {
//         return `${convertToAMPMFormat(time[0])} - ${convertToAMPMFormat(time[1])}`;
//     }
// };

export const initialFields = {
    capturePrice: '',
    spaceName: '',
    logo: '',
    spaceDescription: '',
    policy: false,
    kind: '',
    square: '',
    occupancy: '',
    floors: '',
    featuresFloors: '',
    featuresElevators: false,
    wifi: false,
    access: false,
    office_drinks: false,
    reception_desk: false,
    kitchen: false,
    parking: false,
    petFriendly: false,
    printer: false,
    bathrooms: false,
    nursing_room: false,
    security: false,
    mail: false,
    keyless: false,
    fullyFurnished: false,
    video: false,
    tv_monitor: false,
    white_board: false,
    hvac: false,
    privetMeetingRooms: false,
    privateFloor: false,
    concierge: false,
    sorted: null,
    is_parent: 0,
    availability: {
        sun: '',
        mon: '',
        tue: '',
        wed: '',
        thu: '',
        fri: '',
        sat: '',
    },
    pricing: {
        hourly: '',
        daily: '',
        monthly: '',
    },
    coverIndex: 1,
    file: [],
    address: {
        zipCode: '',
        street: '',
        cityId: '',
        streetNumber: '',
        city: '',
        state: '',
        country: 'United States',
        streetAddress: '',
        apt: '',
    },
    amenities: [],
    custom_amenities: [],
}

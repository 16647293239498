import React, { useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import ClassNames from 'classnames';
import Webcam from 'react-webcam';
import { useSelector } from 'react-redux';
import NativeInput from '../../../native-input/NativeInput';
import styles from '../../busines-details.module.sass';
import { PhoneControl } from '../../../../../components/ui/input-control';
import { loadSelector } from '../../../../../store/reducers/nativeVerification';
import style from '../../../camera/camera.module.sass';
import DropdownImageVerificationFlow from '../../../../../components/ui/dropdown-image/VerificationFlow';
import Load from '../../../../../components/ui/loader';
import { CloseBtn, PlusWithBorder } from '../../../../../assets/svg';
import Checkbox from '../../../../../components/ui/checkbox';
import useAddClassConditionally from '../../../../../components/hooks/useAddClassConditionally';
import RenderMessage from '../RenderMessage';

const VerifyId = ({ title, description, checkboxes = [], requirementsDescription }) => {
    const { setFieldValue } = useFormikContext();
    const [checkboxesState, setCheckboxesState] = React.useState(
        checkboxes.map((label) => ({ label, checked: false }))
    );
    const updateCheckbox = (label) => {
        setCheckboxesState((state) => {
            return state.map((c) => (c.label === label ? { ...c, checked: !c.checked } : c));
        });
    };
    React.useEffect(() => {
        setFieldValue('isUserAgreed', !checkboxesState.some((v) => !v.checked), [checkboxesState]);
    }, [checkboxesState]);

    const load = useSelector(loadSelector);

    const [images, setImages] = React.useState(null);
    const [mode, setMode] = React.useState(null);

    const onChangeImages = (files) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = () => setImages([{ originalFile: files[0], serverUrl: reader.result, id: 0 }]);
    };

    const onDeleteImage = (e) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }
        setImages(null);
    };

    const compareIfDisabled = (compareStr) => images !== compareStr && images?.length;
    const getClassName = (compareStr) => (mode === compareStr ? styles.radio_selected : styles.radio);

    React.useEffect(() => {
        setFieldValue('file', images?.map(({ originalFile }) => originalFile) ?? []);
    }, [images]);

    return (
        <>
            {load && <Load />}

            <div className={styles.idVerifyConteiner}>
                <div className={styles.idVerifyTitleBox}>
                    <div className={styles.idVerifyTitleBox}>
                        <span className={styles.businessMainTitle}>{title}</span>
                        <span className={styles.businessSubtitle}>
                            <RenderMessage message={description} />
                        </span>
                    </div>
                    <div className={styles.radioContainer}>
                        {/* <button
                            type="button"
                            className={getClassName('phone')}
                            onClick={() => setMode('phone')}
                            disabled={compareIfDisabled('phone')}
                        >
                            <PlusWithBorder />
                            <span className={styles.radioTitle}>Take a picture with your phone</span>
                        </button> */}
                        <button
                            type="button"
                            className={getClassName('webcam')}
                            onClick={() => setMode('webcam')}
                            disabled={compareIfDisabled('webcam')}
                        >
                            <PlusWithBorder />
                            <span className={styles.radioTitle}>Take a picture with your webcam</span>
                        </button>

                        <button
                            type="button"
                            className={getClassName('files')}
                            onClick={() => setMode('files')}
                            disabled={compareIfDisabled('files')}
                        >
                            <PlusWithBorder />
                            <span className={styles.radioTitle}>Upload a file</span>
                        </button>
                    </div>
                    {mode === 'phone' && <TakeAPictureWithYourPhone />}

                    {mode === 'webcam' && (
                        <TakeAPictureWithYouWebcam
                            mode={mode}
                            setMode={setMode}
                            images={images}
                            onChangeImages={onChangeImages}
                            onDeleteImage={onDeleteImage}
                        />
                    )}

                    {mode === 'files' && (
                        <UploadAFile images={images} onChangeImages={onChangeImages} onDeleteImage={onDeleteImage} />
                    )}
                    {!!requirementsDescription && (
                        <div className={styles.idVerifyTitleBox}>
                            <span className={styles.businessSubtitle} style={{ margin: '16px 0 0 0' }}>
                                <RenderMessage message={requirementsDescription} />
                            </span>
                        </div>
                    )}
                    {checkboxesState.map(({ label, checked }) => (
                        <div key={label} className={styles.verifyCheckboxWrapper}>
                            <Checkbox
                                width={24}
                                height={24}
                                label={label}
                                id={label}
                                onChange={() => {
                                    updateCheckbox(label);
                                }}
                                checked={checked}
                                className={styles.verifyCheckbox}
                            />
                            <label htmlFor={label} className={styles.verifyCheckboxLabel}>
                                {label}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const TakeAPictureWithYourPhone = () => {
    const [mode, setMode] = React.useState('SMS');

    const kindOfDocType = ['SMS', 'Email', 'QR', 'Link'];

    return (
        <>
            <div className={styles.firstBtnCont}>
                <div className={styles.tabBar}>
                    <div
                        className={ClassNames(styles.sliderFirstBtn, {
                            [styles.sliderFirst]: mode === kindOfDocType[0],
                            [styles.sliderSecond]: mode === kindOfDocType[1],
                            [styles.sliderThird]: mode === kindOfDocType[2],
                            [styles.sliderFour]: mode === kindOfDocType[3],
                        })}
                    />
                    <button
                        type="button"
                        // className={ClassNames(styles.barButton, mode === 'SMS' ? styles.barButton__selected : null)}
                        className={ClassNames(styles.secondBarButton, {
                            [styles.activeTabVerify]: mode === kindOfDocType[0],
                        })}
                        onClick={() => setMode(kindOfDocType[0])}
                    >
                        SMS
                    </button>
                    <button
                        type="button"
                        // className={ClassNames(styles.barButton, mode === 'Email' ? styles.barButton__selected : null)}
                        className={ClassNames(styles.secondBarButton, {
                            [styles.activeTabVerify]: mode === kindOfDocType[1],
                        })}
                        onClick={() => setMode(kindOfDocType[1])}
                    >
                        Email
                    </button>
                    <button
                        type="button"
                        // className={ClassNames(styles.barButton, mode === 'QR' ? styles.barButton__selected : null)}
                        className={ClassNames(styles.secondBarButton, {
                            [styles.activeTabVerify]: mode === kindOfDocType[2],
                        })}
                        onClick={() => setMode(kindOfDocType[2])}
                    >
                        QR Code
                    </button>
                    <button
                        type="button"
                        // className={ClassNames(styles.barButton, mode === 'Link' ? styles.barButton__selected : null)}
                        className={ClassNames(styles.secondBarButton, {
                            [styles.activeTabVerify]: mode === kindOfDocType[3],
                        })}
                        onClick={() => setMode(kindOfDocType[3])}
                    >
                        Link
                    </button>
                </div>

                {mode === 'SMS' && (
                    <>
                        <div className={styles.smsBox}>
                            <div className={styles.smsTitleBox}>
                                <span className={styles.smsTitle}>Receive a link your phone</span>
                                <span className={styles.smsSubTitle}>
                                    Enter your mobile phone number and we will send you a link to complete this
                                    verification on your device.
                                </span>
                            </div>
                            <Field
                                className={ClassNames(styles.inputPhoneControl)}
                                label="Phone number"
                                name="phoneNumberVerifyId"
                                placeholder="+1 (XXX) XXX-XXXX"
                                message
                                type="text"
                                component={PhoneControl}
                                data_attr="phoneNumberVerifyId"
                            />
                        </div>
                        <button type="submit" className={styles.sendLinkBtn}>
                            Send link
                        </button>
                    </>
                )}

                {mode === 'Email' && (
                    <>
                        <div className={styles.smsBox}>
                            <div className={styles.smsTitleBox}>
                                <span className={styles.smsTitle}>Receive a link via email</span>
                                <span className={styles.smsSubTitle}>
                                    Enter your email address number and we will send you a link to complete this
                                    verification on your device.
                                </span>
                            </div>
                            <Field
                                className={styles.smsinput}
                                label="Email"
                                name="emailVerifyId"
                                placeholder="you@example.com"
                                message
                                type="email"
                                component={NativeInput}
                                data_attr="emailVerifyId"
                            />
                        </div>
                        <button type="submit" className={styles.sendLinkBtn}>
                            Send link
                        </button>
                    </>
                )}

                {mode === 'QR' && (
                    <>
                        <div className={styles.smsBox}>
                            <div className={styles.smsTitleBox}>
                                <span className={styles.smsTitle}>Scan QR code</span>
                                <span className={styles.smsSubTitle}>
                                    Generate a QR code and point your phone’s camera at it to follow the link
                                </span>
                            </div>
                        </div>
                        <button type="submit" className={styles.sendLinkBtn} name="qrCode">
                            Generate code
                        </button>
                    </>
                )}

                {mode === 'Link' && (
                    <>
                        <div className={styles.smsBox}>
                            <div className={styles.smsTitleBox}>
                                <span className={styles.smsTitle}>Copy link</span>
                                <span className={styles.smsSubTitle}>
                                    Generate a link and open the URL in another browser or on another device.
                                </span>
                            </div>
                        </div>
                        <button type="submit" className={styles.sendLinkBtn} name="link">
                            Generate link
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

const TakeAPictureWithYouWebcam = ({ images, onChangeImages, onDeleteImage }) => {
    const [showCam, setShowCam] = React.useState(false);
    const [screenShot, setScreenshot] = useState(null);
    const [showMini, setShowMini] = React.useState(false);

    const closeCamera = () => setShowCam(false);
    const openCamera = () => setShowCam(true);

    const handleMini = () => {
        setShowMini(!showMini);
        closeCamera();

        fetch(screenShot)
            .then((res) => res.blob())
            .then((blob) => {
                // eslint-disable-next-line no-undef
                const file = [new File([blob], 'file.jpg', { type: 'image/jpeg' })];

                onChangeImages(file);
            });
    };

    const webcamRef = useRef(null);

    const [showImage, setShowImage] = React.useState('');

    const capturePhoto = (e) => {
        e.preventDefault();
        const screenBase64 = webcamRef?.current?.getScreenshot();
        setScreenshot(screenBase64);

        const inputImage = new Image();
        inputImage.src = screenBase64;

        const outputImageAspectRatio = 1.4;

        inputImage.onload = () => {
            const frameWidth = document.getElementById('screenElementId').clientWidth;
            const frameHeight = document.getElementById('screenElementId').clientHeight;
            // let's store the width and height of our image
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            let outputWidth = frameWidth;
            let outputHeight = frameHeight;

            if (inputImageAspectRatio > outputImageAspectRatio) {
                outputWidth = inputHeight * outputImageAspectRatio;
            } else if (inputImageAspectRatio < outputImageAspectRatio) {
                outputHeight = inputHeight / outputImageAspectRatio;
            }

            // calculate the position to draw the image at
            const outputX = (outputWidth - inputWidth) * 0.5;
            const outputY = (outputHeight - inputHeight) * 0.5;

            // create a canvas that will present the output image
            const outputImage = document.createElement('canvas');

            // set it to the same size as the image
            outputImage.width = outputWidth;
            outputImage.height = outputHeight;

            // draw our image at position 0, 0 on the canvas
            const ctx = outputImage.getContext('2d');
            ctx.drawImage(inputImage, outputX, outputY);

            // from canvas to jpeg
            const dataUrl = outputImage.toDataURL('image / jpeg');
            setShowImage(dataUrl);
        };
    };

    useAddClassConditionally(showCam, styles.removeScrollBar);

    return (
        <>
            <div className={styles.firstBtnCont}>
                <div className={styles.smsBox}>
                    <div className={styles.smsTitleBox}>
                        <span className={styles.smsTitle}>Allow Camera Access</span>
                        <span className={styles.smsSubTitle}>
                            To continue the verification, Stripe needs access to your device’s camera.
                        </span>
                        <span className={ClassNames(styles.sms2, styles.smsSubTitle)}>
                            A dialog will be shown asking for permission. Click Allow to grant access.
                        </span>
                    </div>
                    {images?.length ? (
                        <Field
                            name="file"
                            component={DropdownImageVerificationFlow}
                            className={ClassNames(styles.checkboxWebsite)}
                            data_atr="licenseDownload"
                            onChangeImages={onChangeImages}
                            onDeleteImage={onDeleteImage}
                            images={images}
                            verificationFlow
                        />
                    ) : (
                        <button type="button" className={styles.sendLinkBtn} onClick={openCamera}>
                            Continue
                        </button>
                    )}
                    {showCam === true && (
                        <>
                            <div className={style.camWrapp}>
                                <div className={style.cameraBox}>
                                    <div className={style.headerBox}>
                                        <span className={style.headerTitle}>Front of photo ID</span>
                                        <div>
                                            <CloseBtn onClick={closeCamera} style={{ cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                    <div className={style.cameraWrapp}>
                                        <div className={style.cameraAndBtnBox}>
                                            <div className={style.frameWrap}>
                                                {showImage ? (
                                                    <div className={style.canvas}>
                                                        <img
                                                            className={style.canvasImage}
                                                            src={showImage}
                                                            alt="your document"
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <Webcam
                                                            ref={webcamRef}
                                                            audio={false}
                                                            screenshotFormat="image/jpeg"
                                                            videoConstraints={{ facingMode: 'environment' }}
                                                            className={style.cameraCont}
                                                        />
                                                        <div id="screenElementId" className={style.modalFrame} />
                                                    </>
                                                )}
                                            </div>

                                            <span className={style.subTitle}>
                                                Turn your ID around to capture the front
                                            </span>
                                            <div className={style.btnBox}>
                                                <button
                                                    className={ClassNames(
                                                        !showImage ? style.btnCap : style.disabledPhoto
                                                    )}
                                                    onClick={capturePhoto}
                                                    type="button"
                                                >
                                                    Make a photo
                                                </button>
                                                <button
                                                    className={style.btnRef}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowImage(null);
                                                    }}
                                                    type="button"
                                                >
                                                    Try again
                                                </button>
                                            </div>
                                        </div>
                                        <div className={style.applyBtnBox}>
                                            <button
                                                className={ClassNames(showImage ? style.btnApply : style.disabled)}
                                                onClick={handleMini}
                                                type="button"
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const UploadAFile = ({ images, onChangeImages, onDeleteImage }) => {
    return (
        <Field
            name="file"
            component={DropdownImageVerificationFlow}
            className={ClassNames(styles.checkboxWebsite)}
            data_atr="licenseDownload"
            onChangeImages={onChangeImages}
            onDeleteImage={onDeleteImage}
            images={images}
            verificationFlow
        />
    );
};

export default VerifyId;

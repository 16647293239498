import states from './states.json';

function getStates() {
    return states.reduce((acc, e) => {
        acc = [...acc, { label: e.name, value: e.abbreviation }];
        return acc;
    }, []);
}

export const STATES = getStates();

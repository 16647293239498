import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage } from 'react-lazy-images';
import styles from './image.module.sass';
import loader from '../../../components/ui/loader/load.svg';
import gradient from './gradient.svg';
import { useMedia } from 'use-media';

const ImageLazy = ({
    src,
    alt,
    className,
    width,
    height,
    circle,
    proxy,
    placeholderWidth,
    placeholderHeight,
    borderRadius,
    media,
    placeholderClassName,
    ...attrs
}) => {
    const classes = classNames(className, { [styles.circle]: circle });
    // let url;
    if (proxy) {
        // url = process.env.REACT_APP_IMAGE_URL + src;
    }
    if (!src) {
        src = '/images/userAvatar.png';
    }
    
    const heightArr = typeof placeholderHeight === 'string' ? placeholderHeight?.split(';') : [];
    const mediaPoints = typeof media === 'string' ? media?.split(';') : [];  
    
    const isTablet = useMedia({maxWidth: `${mediaPoints[0]}px`});
    const isMobile = useMedia({maxWidth: `${mediaPoints[1]}px`});
    let plHeight = `${heightArr[0]}px`;
    if(isTablet){
        plHeight = `${heightArr[1]}px`;
    }
    if(isMobile) {
        plHeight = `${heightArr[2]}px`;
    }
    return (
        <LazyImage
            src={src}
            alt={alt}
            className={classes}
            width={width}
            height={height}
            observerProps={{threshold: 0.01, rootMargin: "100px 0px"}}
            {...attrs}
            placeholder={({ imageProps, ref }) => (
               
                        <img
                            ref={ref}
                            src={gradient}
                            alt={imageProps.alt}
                            className={placeholderClassName}
                            style={{
                                borderRadius: borderRadius ? borderRadius : '32px',
                                width: `${placeholderWidth}px`,
                                height: plHeight,
                                maxWidth: '100%',
                            }}
                        />
               
            )}
            actual={({ imageProps }) => <img {...imageProps} alt={alt} />}
        />
    );
};

ImageLazy.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    circle: PropTypes.bool,
    className: PropTypes.string,
    proxy: PropTypes.bool,
};

ImageLazy.defaultProps = {
    src: '',
    alt: 'image',
    // width: 100,
    // height: 100,
    circle: false,
    className: '',
    proxy: false,
};

export default ImageLazy;

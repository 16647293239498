import { convertTo24Format, getDatesInRange } from '../../../../../../utils/helpers/timeHelpers';

export const countMinusMonthlyDesk = (data, monthlyBookings, dailyBookings, hourlyBookings) => {
    let minusMonthlyDesk = 0;
    if (data.type === 'monthly') {
        minusMonthlyDesk += monthlyBookings.reduce((acc, item) => acc + item.qty, 0);
        const otherBookings = [...dailyBookings, ...hourlyBookings];
        const otherDates = otherBookings.map((item) => {
            if (item.type_of_date === 'hourly') {
                return { date: item.date_details.split(' ')[1], qty: item.qty };
            }
            if (item.type_of_date === 'daily') {
                return { date: item.date_details.split('-')[0], qty: item.qty };
            }
        });
        otherDates.forEach((item) => {
            if (new Date(item.date).getTime() >= new Date(data.startDate).getTime()) {
                minusMonthlyDesk += item.qty;
            }
        });
    }
    return minusMonthlyDesk;
};

export const countMinusDailyDesk = (data, monthlyBookings, dailyBookings, hourlyBookings) => {
    let minusDailyDesk = 0;
    if (data.type === 'daily') {
        monthlyBookings.forEach((booking) => {
            const bookingStart = new Date(booking.date_details).getTime();
            if (new Date(data?.endDate).getTime() >= bookingStart) {
                minusDailyDesk += booking.qty;
            }
        });
        const chosenDates = getDatesInRange(data?.startDate, data?.endDate);
        for (let booking of [...dailyBookings, ...hourlyBookings]) {
            let start = new Date(booking.date_details.split('-')[0]).getTime();
            let end = new Date(booking.date_details.split('-')[1]).getTime();
            if (booking.type_of_date === 'hourly') {
                start = new Date(booking.date_details.split(' ')[1]).getTime();
                end = new Date(booking.date_details.split(' ')[1]).getTime();
            }
            const inRange = chosenDates.some((item) => new Date(item) >= start && new Date(item) <= end);
            if (inRange) {
                minusDailyDesk += booking.qty;
            }
        }
    }
    return minusDailyDesk;
};

export const countMinusHourlyDesk = (data, monthlyBookings, dailyBookings, hourlyBookings) => {
        
    let minusHourlyDesk = 0;
    if (data.type === 'hourly') {
        monthlyBookings.forEach((booking) => {
            const bookingStart = new Date(booking.date_details).getTime();
            if (new Date(data?.startDate).getTime() >= bookingStart) {
                minusHourlyDesk += booking.qty;
            }
        });
        const date = new Date(data.startDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const start = convertTo24Format(
            `${data?.startTime.slice(0, 2)}:${data?.startTime.slice(2, 4)} ${data?.startTime.slice(4, 6)}`
        );
        const end = convertTo24Format(
            `${data?.endTime.slice(0, 2)}:${data?.endTime.slice(2, 4)} ${data?.endTime.slice(4, 6)}`
        );
        const startUTC = Date.UTC(year, month, day, +start.slice(0, 2), +start.slice(3), 0);
        const endUTC = Date.UTC(year, month, day, +end.slice(0, 2), +end.slice(3), 0);
        const chosenDates = [startUTC, endUTC];
               
        for (let booking of dailyBookings) {
            let start = new Date(booking.date_details.split('-')[0]).getTime();
            let end = new Date(booking.date_details.split('-')[1]).getTime();
            
            const inRange = date.getTime() >= start && date.getTime() <= end;
            if (inRange) {
                minusHourlyDesk += booking.qty;
            }
        }

        for (let booking of hourlyBookings) {
            let start = new Date(booking.date_details.split('-')[0]).getTime();
            let end = new Date(booking.date_details.split('-')[1]).getTime();
            if (booking.type_of_date === 'hourly') {
                const d = new Date(booking.date_details?.split('; ')[1]);
                const year = d.getFullYear();
                const month = d.getMonth();
                const day = d.getDate();
                const sT = booking.date_details?.split('; ')[0].split('-')[0];
                const eT = booking.date_details?.split('; ')[0].split('-')[1];
                const start24 = convertTo24Format(`${sT.slice(0, 2)}:${sT.slice(2, 4)} ${sT.slice(4, 6)}`);
                const end24 = convertTo24Format(`${eT.slice(0, 2)}:${eT.slice(2, 4)} ${eT.slice(4, 6)}`);
                start = Date.UTC(year, month, day, +start24.slice(0, 2), +start24.slice(3), 0);
                end = Date.UTC(year, month, day, +end24.slice(0, 2), +end24.slice(3), 0);
            }
            const inRange =
                (chosenDates[0] >= start && chosenDates[0] < end) || (chosenDates[1] > start && chosenDates[1] <= end);
            if (inRange) {
                minusHourlyDesk += booking.qty;
            }
        }
    }
    return minusHourlyDesk;
};

import { createAction, handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import SubspaceWithCounter from "../../pages/place/article/availableSpaces/child/subspaceWithCounter";
import SubspaceWithAddBtn from "../../pages/place/article/availableSpaces/child/subspaceWithAddBtn";
import React from "react";
import { push } from 'connected-react-router';
import {popupWarning} from "./popupReduser";
import { resource } from '../../service/http';
import { setBookingItem } from './account';

const namespace = 'bookingRequest';

const SET_PRICING_TYPE = `${namespace}/SET_PRICING_TYPE`;
const SET_NUMBER_OF_MONTH = `${namespace}/SET_NUMBER_OF_MONTH`;
const SET_MONTHLY_START_VALUE = `${namespace}/SET_MONTHLY_START_VALUE`;
const SET_DAILY_START_VALUE = `${namespace}/SET_DAILY_START_VALUE`;
const SET_DAILY_END_VALUE = `${namespace}/SET_DAILY_END_VALUE`;
const SET_HOURLY_DATE_VALUE = `${namespace}/SET_HOURLY_DATE_VALUE`;
const SET_START_TIME = `${namespace}/SET_START_TIME`;
const SET_END_TIME = `${namespace}/SET_END_TIME`;
const SET_START_AM = `${namespace}/SET_START_AM`;
const SET_END_AM = `${namespace}/SET_END_AM`;
const SET_LOAD = `${namespace}/SET_LOAD`;

const SET_CART = `${namespace}/SET_CART`;
const SET_CHECK = `${namespace}/SET_CHECK`;
const SET_TYPE_OF_DATE = `${namespace}/SET_TYPE_OF_DATE`;
const SET_DATE = `${namespace}/SET_DATE`;
const REQUEST_BOOKING_INDIVIDUAL = `${namespace}/REQUEST_BOOKING_INDIVIDUAL`;
const REQUEST_BOOKING_COWORKING = `${namespace}/REQUEST_BOOKING_COWORKING`;
const SET_TOTAL = `${namespace}/SET_TOTAL`;
const SET_INDIVIDUAL_BOOKING = `${namespace}/SET_INDIVIDUAL_BOOKING`;
const CLEAR_INDIVIDUAL_BOOKING = `${namespace}/CLEAR_INDIVIDUAL_BOOKING`;

const SET_SWITCHER = `${namespace}/SET_SWITCHER`;
const SET_ACTIVE_TAB = `${namespace}/SET_ACTIVE_TAB`;

const SET_ACTIVE_TAB_CALENDAR = `${namespace}/SET_ACTIVE_TAB_CALENDAR`;
const CANCEL_SUBSCRIPTION = `${namespace}/CANCEL_SUBSCRIPTION`;
const CLEAR_DATA = `${namespace}/CLEAR_DATA`;
const SAVE_CART = `${namespace}/SAVE_CART`;
const SET_IND_CALENDAR = `${namespace}/SET_IND_CALENDAR`;

export const setPricingType = createAction(SET_PRICING_TYPE);
export const setNumberOfMonths = createAction(SET_NUMBER_OF_MONTH);
export const setMonthlyStartVal = createAction(SET_MONTHLY_START_VALUE);
export const setDailyStartVal = createAction(SET_DAILY_START_VALUE);
export const setDailyEndVal = createAction(SET_DAILY_END_VALUE);
export const setHourlyDateVal = createAction(SET_HOURLY_DATE_VALUE);
export const setHourlyStartTime = createAction(SET_START_TIME);
export const setHourlyEndTime = createAction(SET_END_TIME);
export const setStartAM = createAction(SET_START_AM);
export const setEndAM = createAction(SET_END_AM);
export const setLoad = createAction(SET_LOAD);

export const setCart = createAction(SET_CART);
export const setCheck = createAction(SET_CHECK);
export const setTypeOfDate = createAction(SET_TYPE_OF_DATE);
export const setDate = createAction(SET_DATE);
export const requestBookingInd = createAction(REQUEST_BOOKING_INDIVIDUAL);
export const requestBookingCoworking = createAction(REQUEST_BOOKING_COWORKING);
export const setTotal = createAction(SET_TOTAL);
export const setIndividualBooking = createAction(SET_INDIVIDUAL_BOOKING);
export const clearIndividualBooking = createAction(CLEAR_INDIVIDUAL_BOOKING);

export const setSwitcher = createAction(SET_SWITCHER);
export const setActiveTab = createAction(SET_ACTIVE_TAB);

export const setActiveTabCalendar = createAction(SET_ACTIVE_TAB_CALENDAR);
export const cancelSubscription = createAction(CANCEL_SUBSCRIPTION);
export const clearData = createAction(CLEAR_DATA);
export const saveCart = createAction(SAVE_CART);
export const setIndCalendar = createAction(SET_IND_CALENDAR);

const initialState = {
    individualBooking: {
        pricingType: null,
        numberOfMonths: null,
        monthlyStartVal: null,
        dailyStartVal: null,
        dailyEndVal: null,
        hourlyDateVal: null,
        hourlyStartTime: null,
        hourlyEndTime: null,
        startAM: null,
        endAM: null,
    },
    load: false,
    check: [],
    cart: {},
    typeOfData: null,
    total: null,
    indCalendarOpen: false,
    data: {
        type: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        numberOfMonth: null,
        dateKey: null,
    },
    checkEmptyFilter: {
        'hot desk': 0,
        'private office': 0,
        'private meeting rooms': 0,
        'event space': 0,
        'membership passes': 0,
    },
    switcher: false,
    activeTab: "",
    activeTabCalendar: {
        hourly: true,
        daily: true,
        monthly: true,
    }
};

export default handleActions(
    {
        [SET_PRICING_TYPE]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, pricingType: payload}}),
        [SET_NUMBER_OF_MONTH]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, numberOfMonths: payload} }),
        [SET_MONTHLY_START_VALUE]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, monthlyStartVal: payload} }),
        [SET_DAILY_START_VALUE]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, dailyStartVal: payload} }),
        [SET_DAILY_END_VALUE]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, dailyEndVal: payload} }),
        [SET_HOURLY_DATE_VALUE]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, hourlyDateVal: payload} }),
        [SET_START_TIME]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, hourlyStartTime: payload} }),
        [SET_END_TIME]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, hourlyEndTime: payload} }),
        [SET_START_AM]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, startAM: payload} }),
        [SET_END_AM]: (state, { payload }) => ({ ...state, individualBooking: {...state.individualBooking, endAM: payload} }),
        [CLEAR_INDIVIDUAL_BOOKING]: (state, { payload }) => ({ ...state, individualBooking: {...initialState.individualBooking} }),
        [SET_INDIVIDUAL_BOOKING]: (state, { payload }) => ({ ...state, individualBooking: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),

        [SET_CHECK]: (state, { payload }) => ({ ...state, check: payload }),
        [SET_CART]: (state, { payload }) => ({ ...state, cart: payload }),
        [SET_TYPE_OF_DATE]: (state, { payload }) => ({ ...state, typeOfData: payload }),
        [SET_DATE]: (state, { payload }) => ({ ...state, data: payload }),
        [SET_TOTAL]: (state, { payload }) => ({ ...state, total: payload }),

        [SET_SWITCHER]: (state, { payload }) => ({ ...state, switcher: payload }),
        [SET_ACTIVE_TAB]: (state, { payload }) => ({ ...state, activeTab: payload }),

        [SET_ACTIVE_TAB_CALENDAR]: (state, { payload }) => ({ ...state, activeTabCalendar: payload }),
        [CLEAR_DATA]: (state, { payload }) => ({ ...state, data: {...initialState.data}, check: [], cart: {} }),
        [SET_IND_CALENDAR]: (state, { payload }) => ({ ...state, indCalendarOpen: payload })
    },
    initialState
);

export const bookingSelector = (state) => state[namespace];
export const individualBookingSelector = (state) => state[namespace].individualBooking;
export const loadSelector = (state) => state[namespace].load;

export function* individualBookingRequest({payload}) {
   
        yield put(setLoad(true));
        const { placeId, setSubmitting } = payload;
        try {   
            const response = yield call(resource.post, '/booking-request', payload);
            if (response.booking) {
                localStorage.removeItem(placeId);
                yield put(clearIndividualBooking());
                yield put(
                    push({
                        pathname: '/thanks',
                        state: {
                            title: 'Your booking request has been sent to the host for approval.',
                            description: 'One of our agents will be in touch shortly. Please call for urgent requests.',
                            buttonTitle: 'Go to my dashboard',
                            buttonLink: '/account/requests',
                            // phone: `${response.user.phoneNumber}`,
                        },
                    })
                );
                
            }
        } catch (error) {
            // console.log(error);
            yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
        } finally {
            yield put(setLoad(false));
            setSubmitting(false);
        }
   
}

export function* coworkingBookingRequest({payload}) {
   
    yield put(setLoad(true));
    const { placeId, setSubmitting } = payload;
    try {
        const response = yield call(resource.post, '/booking-request-coworking', payload);
        if (response.booking) {
            localStorage.removeItem(`${placeId}-Check`);
            localStorage.removeItem(`${placeId}-Cart`);
            yield put(clearData());
            yield put(
                push({
                    pathname: '/thanks',
                    state: {
                        title: 'Your booking request has been sent to the host for approval.',
                        description: 'One of our agents will be in touch shortly. Please call for urgent requests.',
                        buttonTitle: 'Go to my dashboard',
                        buttonLink: '/account/requests',
                        // phone: `${response.user.phoneNumber}`,
                    },
                })
            );
            
        }
    } catch (error) {
        // console.log(error);
        yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
    } finally {
        yield put(setLoad(false));
        setSubmitting(false);
    }

}

export function* cancelSubscriptionRequest({payload}) {
    const response = yield call(resource.post, '/request/cancelsubscription', payload);
    if(response.status === "success") {
        const newBookings = yield call(resource.get, '/user/bookings');
        yield put(setBookingItem(newBookings));
    }
}

export function* syncCart({ payload }) {
    try {
        const { key, cart } = payload;
        const isAuth = select((state) => state.auth.authorization);
        if (isAuth) {
            const response = yield call(resource.post, '/cart/store', { cart_key: key, cart_item: cart});
        }
    } catch (err) {
    } finally {
    }
}

export function* getUserCarts() {
    try {
        const res = yield call(resource.get, '/cart/get');
        if(res.cart.length > 0) {
            res.cart.forEach(item => {
                if(item.cart_item !== '[]'){
                    const keys = [...Array(localStorage.length)].map((o, i) => {
                        return localStorage.key(i);
                    });
                    if(!keys.includes(item.cart_key)) {
                        const cart = JSON.parse(item.cart_item);
                        const check = Object.keys(cart);
                        const id = item?.cart_key?.split('-')?.[0];
                        localStorage.setItem(item.cart_key, item.cart_item);
                        localStorage.setItem(`${id}-Check`, JSON.stringify(check));
                    }         
                }        
            })
        }
    } catch {

    } finally {

    }
}

export function* sagas() {
    yield all([
        takeLatest(REQUEST_BOOKING_INDIVIDUAL, individualBookingRequest),
        takeLatest(REQUEST_BOOKING_COWORKING, coworkingBookingRequest),
        takeLatest(CANCEL_SUBSCRIPTION, cancelSubscriptionRequest),
        takeLatest(SAVE_CART, syncCart),
        getUserCarts()
    ]);
}

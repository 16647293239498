/* eslint-disable indent */
import { createAction, handleActions } from 'redux-actions';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { popupClose, popupLoading, popupSuccess, popupWarning } from './popupReduser';
import { getUser, setUser, userSelector } from './user';
import { resource } from '../../service/http';

const namespace = 'nativeVerification';

const SET_NATIVE = `${namespace}/SET_NATIVE`;
const SET_LOAD = `${namespace}/SET_LOAD`;
const SET_TYPE = `${namespace}/SET_TYPE`;
const SET_CATEGORY_WEB = `${namespace}/SET_CATEGORY_WEB`;
const SET_CATEGORY_UPLOAD = `${namespace}/SET_CATEGORY_UPLOAD`;
const SET_BUSINESS_STRUCTURE = `${namespace}/SET_BUSINESS_STRUCTURE`;
const UPLOAD_IMAGE = `${namespace}/UPLOAD_IMAGE`;
const UPDATE_IMAGE_TYPE = `${namespace}/UPDATE_IMAGE_TYPE`;
const DELETE_IMAGE = `${namespace}/DELETE_IMAGE`;
const SUBMIT_FORM = `${namespace}/SUBMIT_FORM`;
const SET_IMAGES = `${namespace}/SET_IMAGES`;
const SET_INDUSTRY = `${namespace}/SET_INDUSTRY`;
const SET_BUSINESS_BACK_UP = `${namespace}/SET_BUSINESS_BACK_UP`;
// const ADD_MEMBER = `${namespace}/ADD_MEMBER;`
// const REMOVE_MEMBER = `${namespace}/REMOVE_MEMBER;`
const UPDATE_STRIPE_INFO = `${namespace}/UPDATE_STRIPE_INFO`;
const DELETE_DATA_FROM_STRIPE = `${namespace}/DELETE_DATA_FROM_STRIPE`;

export const setLoad = createAction(SET_LOAD);

export const setNative = createAction(SET_NATIVE);
export const setTypeVerification = createAction(SET_TYPE);
export const setImgWebVerification = createAction(SET_CATEGORY_WEB);
export const setImgUploadVerification = createAction(SET_CATEGORY_UPLOAD);
export const setBusinessStructure = createAction(SET_BUSINESS_STRUCTURE);
export const setIndustry = createAction(SET_INDUSTRY);

export const submitForm = createAction(SUBMIT_FORM);

export const uploadImages = createAction(UPLOAD_IMAGE);
export const updateImageType = createAction(UPDATE_IMAGE_TYPE);
export const deleteImage = createAction(DELETE_IMAGE);
export const setImages = createAction(SET_IMAGES);
export const setBusinessBackUp = createAction(SET_BUSINESS_BACK_UP);
// export const addMember = createAction(ADD_MEMBER);
// export const removeMember = createAction(REMOVE_MEMBER);
export const updateStripeInfo = createAction(UPDATE_STRIPE_INFO);
export const deleteDataFromStripe = createAction(DELETE_DATA_FROM_STRIPE);

const getErrorMessage = (err) => {
    let message = err?.message;

    try {
        const errMessage = JSON.parse(err?.message)?.message;
        message = errMessage;
    } catch (er) {}

    return message;
};

const initialState = {
    data: null,
    type: null,
    businesStructure: null,
    industry: null,
    images: null,
    imgCategory: null,
    uploadCategory: null,
    load: false,
    businessBackUp: { type: null, structure: null, fields: null },
    // members: [{
    //     id: 0, cityPerson: "",
    //     addressPerson1: "",
    //     addressPerson2: "",
    //     zipCodePerson: "",
    //     statePerson: "",
    //     datePerson: "",
    //     emailPerson: "",
    //     namePerson: "",
    //     lastNamePerson: "",
    //     phoneNumberPerson: "",
    //     socialSecurityNumber: "",
    // }],
};

export default handleActions(
    {
        [SET_NATIVE]: (state, { payload }) => ({ ...state, data: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_TYPE]: (state, { payload }) => ({ ...state, type: payload }),
        [SET_CATEGORY_WEB]: (state, { payload }) => ({ ...state, imgCategory: payload }),
        [SET_CATEGORY_UPLOAD]: (state, { payload }) => ({ ...state, uploadCategory: payload }),
        [SET_BUSINESS_STRUCTURE]: (state, { payload }) => ({ ...state, businesStructure: payload }),
        [SET_INDUSTRY]: (state, { payload }) => ({ ...state, industry: payload }),
        [SET_IMAGES]: (state, { payload }) => ({ ...state, images: payload }),
        [SET_BUSINESS_BACK_UP]: (state, { payload }) => ({
            ...state,
            businessBackUp: {
                ...state.businessBackUp,
                ...payload,
            },
        }),
        // [ADD_MEMBER]: (state, { payload }) => ({ ...state, members: [...state.members, payload] }),
        // [REMOVE_MEMBER]: (state, { payload }) => ({ ...state, members: state.members.filter((e) => { return e.name != payload }) })
    },
    initialState
);

export const nativeSelector = (state) => state[namespace].data;
export const typeSelector = (state) => state[namespace].type;
export const businessStructureSelector = (state) => state[namespace].businesStructure;
export const industryeSelector = (state) => state[namespace].industry;
export const imagesSelector = (state) => state[namespace].images;
export const loadSelector = (state) => state[namespace].load;
export const businessBackUpSelector = (state) => state[namespace].businessBackUp;
export const membersSelector = (state) => state[namespace].members;

export function* getNativeSaga() {
    while (true) {
        yield take(SET_NATIVE);
        const { data } = yield call(resource.get, '/verification');
        yield put(setNative(data));
    }
}

function* submitFormRequest() {
    while (true) {
        const { payload } = yield take(SUBMIT_FORM);
        payload.business_type = yield select(typeSelector);
        if (payload.business_type == 'company') {
            const typeOfCompany = yield select(businessStructureSelector);
            payload.businessType = typeOfCompany.value;
        }

        try {
            yield put(setLoad(true));
            yield put(
                popupLoading({ h2: 'Profile verification', message: 'Please wait while we verify your profile' })
            );

            const response = yield call(resource.post, '/user/verification', payload);
            if (response) {
                yield put(setUser(response));
            }
            yield put(setLoad(false));
            yield put(popupClose());
            yield put(
                popupSuccess({
                    h2: 'Congratulations!',
                    message: 'Your profile has been verified',
                    showImg: true,
                    mainBtnText: 'Go it',
                    btn_link: '/summary',
                    openBtnLinkOnOverlay: true,
                })
            );
        } catch (err) {
            // console.log('err :>> ', err.message);
            const message = getErrorMessage(err);

            let relocateToSummary = false;
            try {
                const user = yield call(resource.get, '/user');

                if (user.account.business_type === 'company' || user.account.business_type === 'non_profit') {
                    relocateToSummary = true;
                    yield put(setUser(user));
                }
            } catch (error) {}

            yield put(setLoad(false));
            yield put(popupClose());
            yield put(
                popupWarning({
                    h2: 'Oops!',
                    message: message ?? 'Something went wrong. Please try again.',
                    showImg: true,
                    btn_link: relocateToSummary ? '/summary' : null,
                    disableClose: relocateToSummary,
                    closeOnMainButton: !relocateToSummary,
                })
            );
        }
    }
}

function* uploadImageRequest({ payload }) {
    const { images, setFieldValue, docType, imgUpload, imgWeb } = payload;
    if (images !== null) {
        yield put(setLoad(true));

        const data = new FormData();

        data.append('name', docType);
        data.append('imgCategory', imgWeb);
        data.append('uploadCategory', imgUpload);
        images.forEach((e) => {
            data.append('file[]', e, 'file');
        });

        try {
            const response = yield call(resource.post, '/user/verification-photos', data);
            if (response && Array.isArray(response) && response?.length) {
                const data = response.map((e) => e.id);
                setFieldValue('file', [...data]);
                yield put(setImages([...response]));
            }
        } catch (err) {
            // console.log('err :>> ', err);
            yield put(popupWarning({ closeOnMainButton: true, h2: 'You need to upload photo', message: '' }));
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* deleteImageRequest() {
    while (true) {
        const {
            payload: { url, setFieldValue },
        } = yield take(DELETE_IMAGE);

        yield put(setLoad(true));
        try {
            const response = yield call(resource.post, `/user/verification-photos-delete`, { link: url });
            if (response && Array.isArray(response) && response?.length) {
                const data = response.map((e) => e.id);
                setFieldValue('file', [...data]);
                yield put(setImages([...response]));
            } else {
                setFieldValue('file', []);
                yield put(setImages([]));
            }
        } catch (err) {
            // console.log('err :>> ', err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* updateStripeInfoRequest() {
    while (true) {
        const {
            payload: { values, updateType, successCB },
        } = yield take(UPDATE_STRIPE_INFO);

        if (values.industry) {
            const industry = yield select(industryeSelector);
            values.industry = industry.value;
        }

        try {
            yield put(setLoad(true));
            yield put(
                popupLoading({ h2: 'Profile verification', message: 'Please wait while we verify your profile' })
            );

            switch (updateType) {
                case 'business_ownership_info': {
                    const response = yield call(resource.post, '/user/verification-member', values);
                    yield put(popupClose());
                    const user = yield select(userSelector);

                    yield put(setUser({ ...user, persons: { ...user.persons, data: response.data } }));
                    yield put(
                        popupSuccess({
                            h2: 'Congratulations!',
                            message: 'Business ownership information has been updated',
                            showImg: true,
                            mainBtnText: 'Go it',
                            btn_link: '/summary',
                            openBtnLinkOnOverlay: true,
                        })
                    );
                    break;
                }

                case 'payment_method': {
                    const response = yield call(resource.post, '/user/payment-method', values);
                    const user = yield select(userSelector);
                    yield put(popupClose());

                    if (response) {
                        yield put(
                            setUser({
                                ...user,
                                paymentsMethods: {
                                    ...user.paymentsMethods,
                                    data: [...user.paymentsMethods.data, response.source],
                                },
                            })
                        );
                    }
                    break;
                }

                case 'set_default_payment_method': {
                    const response = yield call(resource.post, '/user/payment-method-setDefault', values);
                    const user = yield select(userSelector);
                    yield put(setUser({ ...user, paymentsMethods: { ...user.paymentsMethods, data: response } }));
                    yield put(popupClose());
                    break;
                }

                case 'ssn_upload_document':
                case 'ein_upload_document':
                case 'generic_upload_document': {
                    const { file, ...params } = values;
                    const data = new FormData();
                    Object.entries(params).forEach(([key, value]) => {
                        data.append(key, value);
                    });
                    file.forEach((e) => {
                        data.append('file[]', e, 'file');
                    });

                    yield call(resource.post, '/user/verification-photos', data);

                    // const user = yield select(userSelector);
                    // yield put(setUser({ ...user, paymentsMethods: { ...user.paymentsMethods, data: response } }));
                    yield put(popupClose());
                    yield put(
                        popupSuccess({
                            h2: 'Congratulations!',
                            message: 'Your profile has been updated',
                            showImg: true,
                            mainBtnText: 'Go it',
                            btn_link: '/summary',
                            openBtnLinkOnOverlay: true,
                        })
                    );
                    yield put(getUser());
                    break;
                }

                default: {
                    const response = yield call(resource.post, '/user/verification-edit', values);

                    yield put(setUser(response));
                    yield put(popupClose());
                    yield put(
                        popupSuccess({
                            h2: 'Congratulations!',
                            message: 'Your profile has been updated',
                            showImg: true,
                            mainBtnText: 'Go it',
                            btn_link: '/summary',
                            openBtnLinkOnOverlay: true,
                        })
                    );
                    yield put(getUser());
                }
            }
            yield put(setLoad(false));

            if (successCB) {
                successCB();
            }
        } catch (err) {
            const message = getErrorMessage(err);

            yield put(setLoad(false));
            yield put(popupClose());
            yield put(
                popupWarning({
                    closeOnMainButton: true,
                    h2: 'Oops!',
                    message: message ?? 'Something went wrong. Please try again.',
                    showImg: true,
                })
            );
        }
    }
}

function* deleteDataFromStripeRequest() {
    while (true) {
        const {
            payload: { values, updateType, successCB },
        } = yield take(DELETE_DATA_FROM_STRIPE);

        try {
            yield put(setLoad(true));
            yield put(
                popupLoading({ h2: 'Profile verification', message: 'Please wait while we verify your profile' })
            );

            if (updateType === 'delete_member') {
                yield call(resource.post, '/user/remove-member', values);
                const user = yield select(userSelector);

                yield put(
                    setUser({
                        ...user,
                        persons: {
                            ...user.persons,
                            data: user.persons.data.filter(({ id }) => id !== values.person_id),
                        },
                    })
                );
            }

            if (updateType === 'delete_bank_account') {
                const response = yield call(resource.post, '/user/payment-method-delete', values);
                const user = yield select(userSelector);
                if (response) {
                    yield put(setUser({ ...user, paymentsMethods: { ...user.paymentsMethods, data: response } }));
                }
            }

            // yield put(setUser(response));
            yield put(setLoad(false));
            yield put(popupClose());
            yield put(
                popupSuccess({
                    h2: 'Successfully deleted!',
                    message: '',
                    showImg: true,
                    mainBtnText: 'Go it',
                    closeOnMainButton: true,
                })
            );

            if (successCB) {
                successCB();
            }
        } catch (err) {
            // console.log('err :>> ', err.message);
            const message = getErrorMessage(err);
            yield put(setLoad(false));
            yield put(popupClose());
            yield put(
                popupWarning({
                    closeOnMainButton: true,
                    h2: 'Oops!',
                    message: message ?? 'Something went wrong. Please try again.',
                    showImg: true,
                })
            );
        }
    }
}

function* updateDocumentType() {
    while (true) {
        const {
            payload: { id, name },
        } = yield take(UPDATE_IMAGE_TYPE);
        const data = new FormData();
        // yield put(setLoad(true));
        data.append('name', name);
        data.append('id', id);

        try {
            const response = yield call(resource.post, '/user/verification-photos', data);
            // if (response && Array.isArray(response) && response?.length) {
            //     const data = response.map((e) => e.id);
            //     //setFieldValue('file', [...data]);
            //     yield put(setImages([...response]));
            // }
        } catch (err) {
            // console.log('err :>> ', err);
        } finally {
            // yield put(setLoad(false));
        }
    }
}

export function* sagas() {
    yield all([
        getNativeSaga(),
        submitFormRequest(),
        takeEvery(UPLOAD_IMAGE, uploadImageRequest),
        deleteImageRequest(),
        updateDocumentType(),
        updateStripeInfoRequest(),
        deleteDataFromStripeRequest(),
    ]);
}

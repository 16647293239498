import React, { useRef } from 'react';
import { get } from 'lodash';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styles from './native-input.module.sass';
import Typography from '../../../components/ui/typography';

const NativeInput = ({
    maxlength,
    readonly,
    className,
    label,
    labelClassName,
    error,
    refEffect,
    type,
    children,
    message,
    // onChange,
    placeholder,
    hidden,
    data_attr,
    variant: Component,
    ...props
}) => {
    const {
        field: { name },
        form,
        editVerificationFlow,
    } = props;
    // document.addEventListener(
    //     'touchmove',
    //     function (event) {
    //         if (event.scale !== 1) {
    //             event.preventDefault();
    //         }
    //     },
    //     { passive: false }
    // );

    const htmlFor = `${type}-${Math.random()}`;
    const wrapperRef = useRef(null);
    const maxLength = (inputObj) => {
        const { value } = inputObj;
        if (value.toString().length <= props.valueLength) return true;
        return false;
    };

    const allowedProps = {};
    if (props.valueLength) {
        allowedProps.isAllowed = maxLength;
    }

    const attrs = props.field ? { ...props.field } : { ...props };

    let errorStyle; // Styles fof different states (verifications and edit verifications flows)

    if (!editVerificationFlow) {
        errorStyle = props.form
            ? props.form.errors &&
              get(props.form.errors, attrs.name) &&
              props.form.touched &&
              get(props.form.touched, attrs.name)
            : error;
    } else errorStyle = props.form ? props.form.errors && get(props.form.errors, attrs.name) : error;

    const value = props.field ? (props.field.value ? props.field.value : '') : props.value;

    return (
        <div
            className={ClassNames(
                props.erorStyle ? styles.eror : styles.wrapper,
                styles.isActiveInput,
                className
                // props?.field?.value?.length > 0 && styles.filled
            )}
            ref={wrapperRef}
        >
            <label
                className={ClassNames(
                    styles.label,
                    errorStyle ? styles.error : '',
                    labelClassName,
                    props?.field?.value?.length > 0 && styles.label__filledLabel
                )}
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <Component
                maxLength={maxlength}
                id={htmlFor}
                type={type}
                {...attrs}
                value={value}
                {...allowedProps}
                className={ClassNames(
                    errorStyle ? styles.error : null,
                    props?.field?.value?.length > 0 && styles.filled
                )}
                placeholder={placeholder}
                // onChange={onChange}
                wrap="hard"
                data_attr={data_attr}
                onBlur={editVerificationFlow ? () => form.validateField(name) : () => form.setFieldTouched(name)}
            />
            {children}
            {!editVerificationFlow && (
                <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                    {attrs.name && message && <ErrorMessage name={attrs.name} />}
                </Typography>
            )}
            {editVerificationFlow && props.form.errors[attrs.name] && (
                <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                    {props.form.errors[attrs.name]}
                </Typography>
            )}
        </div>
    );
};

NativeInput.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node,
    message: PropTypes.bool,
    error: PropTypes.string,
    labelClassName: PropTypes.string,
    placeholder: PropTypes.string,
    refEffect: PropTypes.func,
    variant: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};
NativeInput.defaultProps = {
    type: 'text',
    children: null,
    message: false,
    error: '',
    labelClassName: '',
    placeholder: '',
    refEffect: () => {},
    variant: 'input',
    data: '',
};

export default NativeInput;

import { createAction, handleActions } from 'redux-actions';
import { all, call, put, take } from 'redux-saga/effects';
import { resource } from '../../service/http';

const namespace = 'homePage';

const GET_SPACES_SLIDER = `${namespace}/GET_SPACES_SLIDER`;
const SET_SPACES_SLIDER = `${namespace}/SET_SPACES_SLIDER`;
const SET_SEARCH_ACTIVITY = `${namespace}/SET_SEARCH_ACTIVITY`;
const SET_STORE_ACTIVITY = `${namespace}/SET_STORE_ACTIVITY`;
const SET_FEATURED_CITIES = `${namespace}/SET_FEATURED_CITIES`;
const SET_ICON_ACTIVITIES = `${namespace}/SET_ICON_ACTIVITIES`;
const SET_SQUARE_ACTIVITIES = `${namespace}/SET_SQUARE_ACTIVITIES`;
const SET_BANNER = `${namespace}/SET_BANNER`;
const SET_MAIN_TEXT = `${namespace}/SET_MAIN_TEXT`;
const SET_UNFINISHED_BOOKINGS = `${namespace}/SET_UNFINISHED_BOOKINGS`;
const SET_PARTNER_LOGOS = `${namespace}/SET_PARTNER_LOGOS`;

export const getSpacesSlider = createAction(GET_SPACES_SLIDER);
export const setSpacesSlider = createAction(SET_SPACES_SLIDER);
export const setSearchActivity = createAction(SET_SEARCH_ACTIVITY);
export const setStoreActivity = createAction(SET_STORE_ACTIVITY);
export const setFeaturedCities = createAction(SET_FEATURED_CITIES);
export const setIconActivities = createAction(SET_ICON_ACTIVITIES);
export const setSquareActivities = createAction(SET_SQUARE_ACTIVITIES);
export const setBanner = createAction(SET_BANNER);
export const setMainText = createAction(SET_MAIN_TEXT);
export const setUnfinishedBookings = createAction(SET_UNFINISHED_BOOKINGS);
export const setPartnerLogos = createAction(SET_PARTNER_LOGOS);

const initialState = {
    slider: null,
    store: null,
    featuredCities: null,
    iconActivities: null,
    squaresActivities: null,
    banner: null,
    mainText: null,
    unfinishedBookings: null,
    partnerLogos: null,
};

export default handleActions(
    {
        [SET_SPACES_SLIDER]: (state, { payload }) => ({ ...state, slider: payload }),
        [SET_STORE_ACTIVITY]: (state, { payload }) => ({ ...state, store: payload }),
        [SET_FEATURED_CITIES]: (state, { payload }) => ({ ...state, featuredCities: payload }),
        [SET_ICON_ACTIVITIES]: (state, { payload }) => ({ ...state, iconActivities: payload }),
        [SET_SQUARE_ACTIVITIES]: (state, { payload }) => ({ ...state, squaresActivities: payload }),
        [SET_BANNER]: (state, { payload }) => ({ ...state, banner: payload }),
        [SET_MAIN_TEXT]: (state, { payload }) => ({ ...state, mainText: payload }),
        [SET_PARTNER_LOGOS]: (state, { payload }) => ({ ...state, partnerLogos: payload }),
        [SET_UNFINISHED_BOOKINGS]: (state, { payload }) => ({ ...state, unfinishedBookings: payload }),
    },
    initialState
);

export const spacesSliderSelector = (state) => state[namespace].slider;
export const storeSearchActivitySelector = (state) => state[namespace].store;
export const featuredCitiesSelector = (state) => state[namespace].featuredCities;
export const iconActivitiesSelector = (state) => state[namespace].iconActivities;
export const squaresActivitiesSelector = (state) => state[namespace].squaresActivities;
export const bannerSelector = (state) => state[namespace].banner;
export const mainTextSelector = (state) => state[namespace].mainText;
export const unfinishedBookingsSelector = (state) => state[namespace].unfinishedBookings;
export const partnerLogosSelector = (state) => state[namespace].partnerLogos;

// export function* getSpacesSliderSaga() {
//     while (true) {
//         yield take(GET_SPACES_SLIDER);
//         const slider = yield select(spacesSliderSelector);
//         if (!slider) {
//             try {
//                 const { request } = Http;
//                 const response = yield call(request, '/home/getData'); //?limit=5
//                 console.log('-> response', response);
//
//                 yield put(setSpacesSlider(response.spaces));
//             } catch (err) {
//                 console.log(err);
//             }
//         }
//     }
// }

//getSearchActivitySaga
export function* getSearchActivitySaga() {
    while (true) {
        yield take(SET_SEARCH_ACTIVITY);
        const { searchactivities, featuredCities, iconActivities, squaresActivities, slider, banner, maintext, cart, logos } = yield call(
            resource.get,
            '/home/getData'
        );
        yield put(setStoreActivity(searchactivities));
        yield put(setFeaturedCities(featuredCities));
        yield put(setIconActivities(iconActivities));
        yield put(setSquareActivities(squaresActivities));
        yield put(setSpacesSlider(slider));
        yield put(setBanner(banner));
        yield put(setMainText(maintext));
        yield put(setUnfinishedBookings(cart));
        yield put(setPartnerLogos(logos));
    }
}

export function* sagas() {
    yield all([getSearchActivitySaga()]); //getSpacesSliderSaga(),
}

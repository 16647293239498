import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMedia } from 'use-media';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { parse } from 'date-fns';
import styles from '../../busines-details.module.sass';
import { useScrollUp } from '../../../../../components/hooks/useScrollUp';
import Button from '../../../../../components/ui/button';
import Header from '../../../../../components/header';
import { submitForm } from '../../../../../store/reducers/nativeVerification';
import IndustrySelect from '../../components/IndustrySelect';
import LegalProduct from '../../components/groupedFields/LegalProduct';
import HomeAddress from '../../components/groupedFields/HomeAddress';
import AddPaymentMethod from '../../components/groupedFields/AddPaymentMethod';
import ProgressBar from '../../components/ProgresBar';
import LegalNameOfPerson from '../../components/groupedFields/LegalNameOfPerson';
import Footer from '../../../../../components/footerNew';
import { LeftArrow } from '../../../../../assets/svg';
import useBackUp from '../../hooks/useBackUp';

const initialFieldsData = {
    industry: '',
    website: '',
    productDescription: '',
    nameLegal: '',
    lastNamePerson: '',
    emailPerson: '',
    phoneNumberPerson: '',
    dateLegal: '',
    countryLegal: 'USA',
    countryPerson: 'USA',
    stateLegal: '',
    statePerson: '',
    cityLegal: '',
    cityPerson: '',
    zipCodeLegal: '',
    zipCodePerson: '',
    address1Legal: '',
    addressPerson1: '',
    address2Legal: '',
    // nameOfBank: '',
    first_name: '',
    last_name: '',
    // addressOfBank1: '',
    // addressOfBank2: '',
    // cityVerifyPayment: '',
    // stateVerifyPayment: '',
    accountNumberVerifyPayment: '',
    accountNumberConfirmVerifyPayment: '',
    routingNumberVerifyPayment: '',
    phoneNumberVerifyId: '',
    emailVerifyId: '',
    licenseWebcam: [],
    file: [],
    socialSecurityNumber: '',
};

function FormStepper({ children, step, setStepper, ...props }) {
    const history = useHistory();
    useScrollUp(step);
    const dispatch = useDispatch();
    const childrenToArray = React.Children.toArray(children);
    const currentChild = childrenToArray[step];
    const lastStep = childrenToArray.length;
    const formikRef = React.useRef();

    React.useEffect(() => {
        if (step > 0) history.push('/verification-step');
    }, []);

    React.useEffect(() => {
        if (formikRef.current.validateForm) formikRef.current.validateForm();
    }, [step]);

    const currentValidationSchema = currentChild.props.validationSchema;
    const onSubmitHandler = (values, { setSubmitting, setTouched, setFieldValue }) => {
        setTouched({});
        setSubmitting(false);
        step + 1 < lastStep ? setStepper((s) => s + 1) : dispatch(submitForm(values)); // If last step need dispatch submit form // TO DO
    };

    const { initialValues } = useBackUp({ type: 'individual', formikRef, initialFieldsData });

    const isMobile = useMedia({ maxWidth: '560px' });

    return (
        <Formik
            {...props}
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={onSubmitHandler}
        >
            {({ isSubmitting, isValid, dirty, values, validateForm, errors, ...props }) => {
                return (
                    <>
                        <ProgressBar step={step} lastStep={lastStep} />
                        <Form>
                            {React.cloneElement(currentChild, { action: props })}
                            <div className={styles.button__container}>
                                {!isMobile && (
                                    <Button
                                        className={styles.backBtn}
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // step - 1 < 0 ? history.goBack() : setStepper((s) => s - 1);
                                            step === 0 ? history.push('/verification') : setStepper((s) => s - 1);
                                        }}
                                    >
                                        <LeftArrow fill="#2A1BA1" />
                                        Back
                                    </Button>
                                )}
                                {step > 3 ? (
                                    <Button type="submit" className={styles.button} disabled={!isValid || isSubmitting}>
                                        Submit Verification
                                    </Button>
                                ) : (
                                    <Button type="submit" className={styles.button} disabled={!isValid || isSubmitting}>
                                        Next
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
}

function Step({ children }) {
    return <>{children}</>;
}

// -------------------------------------------------------------------------------------------------------------------------------------------------//

const IndividualForm = () => {
    const [showCam, setshowCam] = React.useState(false);
    const handleCamera = () => {
        setshowCam(!showCam);
    };
    // const data = useSelector(nativeSelector);
    return (
        <div className={styles.nativeBox}>
            <Header />
            <SoleProp showCam={showCam} handleCamera={handleCamera} setshowCam={setshowCam} />
            {showCam === true ? null : <Footer />}
        </div>
    );
};

export default IndividualForm;

const SoleProp = () => {
    const [type, setType] = React.useState('');
    const [step, setStepper] = React.useState(0);
    const isMobile = useMedia({ maxWidth: '560px' });
    const history = useHistory();

    function parseDateString(value, originalValue) {
        let temp;

        if (originalValue.length == 8) {
            temp = `${originalValue.substr(0, 2)}-${originalValue.substr(2, 2)}-${originalValue.substr(4, 7)}`;
            return parse(temp, 'MM-dd-yyyy', new Date());
        }
        return value;
    }

    return (
        <>
            {isMobile && (
                <Button
                    className={styles.backBtn}
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        // step - 1 < 0 ? history.goBack() : setStepper((s) => s - 1);
                        step === 0 ? history.push('/verification') : setStepper((s) => s - 1);
                    }}
                >
                    <LeftArrow fill="#2A1BA1" />
                    Back
                </Button>
            )}
            <section className={styles.businessWrapper}>
                <FormStepper step={step} setStepper={setStepper}>
                    <Step
                        validationSchema={yup.object().shape(
                            {
                                industry: yup.string().required('Industry is a required field'),
                                productDescription: yup.string().when('website', {
                                    is: (website) => !website || website.length === 0,
                                    then: yup.string().required('Product description is a required field'),
                                    otherwise: yup.string(),
                                }),
                                website: yup.string().when('productDescription', {
                                    is: (productDescription) => !productDescription || productDescription.length === 0,
                                    then: yup
                                        .string()
                                        .url('Website must begin with https://')
                                        .required('Website is a required field'),
                                    otherwise: yup.string(),
                                }),
                            },
                            ['website', 'productDescription']
                        )}
                    >
                        <div className={styles.businessTitleBox}>
                            <h1 className={styles.businessMainTitle}>Professional Information</h1>
                            <span className={styles.businessSubtitleIndividual}>
                                Please provide more information to verify your account for payouts.
                            </span>
                        </div>
                        <div className={styles.businessInput}>
                            <div className={styles.inputBox}>
                                <Field
                                    name="industry"
                                    label="Industry"
                                    component={IndustrySelect}
                                    placeholder="Please select your industry"
                                    data_attr="industryVerify"
                                />
                            </div>
                            <LegalProduct type={type} setType={setType} />
                        </div>
                    </Step>
                    <Step
                        validationSchema={yup.object().shape({
                            namePerson: yup
                                .string()
                                .min(2, 'First name must be at least 2 characters')
                                .max(50, 'First name must be at most 50 characters')
                                .required('First name is a required field'),
                            lastNamePerson: yup
                                .string()
                                .min(2, 'Last name must be at least 2 characters')
                                .max(50, 'Last name must be at most 50 characters')
                                .required('Last name is a required field'),
                            socialSecurityNumber: yup
                                .string()
                                .min(9, 'Security number must be 9 characters')
                                .max(9)
                                .required('Security number is a required field'),
                            emailPerson: yup
                                .string()
                                .email('Email must be a valid email')
                                .required('Email is a required field'),
                            phoneNumberPerson: yup
                                .string()
                                .min(11, 'Phone number must be at least 11 characters')
                                .max(11)
                                .required('Phone number is a required field'),
                            // datePerson: yup.string().required('Date is a required field'),
                            datePerson: yup
                                .date()
                                .transform(parseDateString)
                                .max(new Date('01-01-2004'.replace(/-/g, '/')), 'too young')
                                .min(new Date('01-01-1897'.replace(/-/g, '/')), 'too old')
                                .required('Date is a required field'),
                            countryPerson: yup.string().required('Country is a required field'),
                            statePerson: yup.string().required('State is a required field'),
                            cityPerson: yup.string().required('City is a required field'),
                            zipCodePerson: yup
                                .string()
                                .min(5, 'Must be at least 5 characters')
                                .max(9, 'must be at least 5 characters')
                                .required('Zip code is a required field'),
                            addressPerson1: yup.string().required('Address is a required field'),
                            addressPerson2: yup.string(),
                        })}
                    >
                        <div className={styles.businessTitleBox}>
                            <h1 className={styles.businessMainTitle}>Personal details</h1>
                            <span className={styles.businessSubtitle}>
                                Please provide more information to verify your account for payouts.
                            </span>
                            <LegalNameOfPerson SSN errors />
                            <div>
                                <HomeAddress />
                            </div>
                        </div>
                    </Step>
                    {/* <Step
                        validationSchema={yup.object().shape({
                            file: yup.array().min(1, 'Need upload image').required(),
                        })}
                    >
                        <VerifyId />
                    </Step> */}
                    <Step
                        validationSchema={yup.object().shape({
                            // nameOfBank: yup
                            //     .string()
                            //     .min(3, 'Must be at least 3 characters')
                            //     .max(255)
                            //     .required('Name of bank is a required field'),
                            // addressOfBank1: yup
                            //     .string()
                            //     .min(3, 'Must be at least 3 characters')
                            //     .max(255)
                            //     .required('Address of bank is a required field'),
                            // addressOfBank2: yup
                            //     .string()
                            //     .min(3, 'Must be at least 3 characters')
                            //     .max(255)
                            //     .required('Address of bank is a required field'),
                            // cityVerifyPayment: yup
                            //     .string()
                            //     .min(3, 'Must be at least 3 characters')
                            //     .max(255)
                            //     .required('City is a required field'),
                            // stateVerifyPayment: yup.string().required('State is a required field'),
                            first_name: yup
                                .string()
                                .min(2, 'First name must be at least 2 characters')
                                .max(50, 'First name must be at most 50 characters')
                                .required('First name is a required field'),
                            last_name: yup
                                .string()
                                .min(2, 'Last name must be at least 2 characters')
                                .max(50, 'Last name must be at most 50 characters')
                                .required('Last name is a required field'),
                            accountNumberVerifyPayment: yup.string().required('Account number is a required field'),
                            accountNumberConfirmVerifyPayment: yup.string().when('accountNumberVerifyPayment', {
                                is: (accountNumberVerifyPayment) => accountNumberVerifyPayment,
                                then: yup
                                    .string()
                                    .oneOf(
                                        [yup.ref('accountNumberVerifyPayment'), null],
                                        'Checking account number must match'
                                    )
                                    .required('Confirm checking account is a required field'),
                            }),
                            routingNumberVerifyPayment: yup
                                .string()
                                .min(9, 'Routing number must have 9 digits')
                                .max(9, 'Routing number must have 9 digits')
                                .required('Routing number is a required field'),
                        })}
                    >
                        <AddPaymentMethod />
                    </Step>
                </FormStepper>
            </section>
        </>
    );
};

import React from 'react'
import styles from './more-filters.module.sass';
import { ReactComponent as Wifi } from "./../../pages/create-place/assets/amenities/wifi.svg";
import { ReactComponent as Access } from "./../../pages/create-place/assets/amenities/access.svg";
import { ReactComponent as Drinks } from "./../../pages/create-place/assets/amenities/drinks.svg";
import { ReactComponent as Desk } from "./../../pages/create-place/assets/amenities/desk.svg";
import { ReactComponent as Kitchen } from "./../../pages/create-place/assets/amenities/kitchen.svg";
import { ReactComponent as Parking } from "./../../pages/create-place/assets/amenities/parking.svg";
import { ReactComponent as PetFriendly } from "./../../pages/create-place/assets/amenities/petFriendly.svg";
import { ReactComponent as Printer } from "./../../pages/create-place/assets/amenities/printer.svg";
import { ReactComponent as Bathrooms } from "./../../pages/create-place/assets/amenities/bathrooms.svg";
import { ReactComponent as NursingRoom } from "./../../pages/create-place/assets/amenities/nursingRoom.svg";
import { ReactComponent as Security } from "./../../pages/create-place/assets/amenities/security.svg";
import { ReactComponent as Furnished } from "./../../pages/create-place/assets/amenities/furnished.svg";
import { ReactComponent as Keyless } from "./../../pages/create-place/assets/amenities/keyless.svg";
import { ReactComponent as Other } from "./../../pages/create-place/assets/amenities/other.svg";
import { ReactComponent as Monitor } from "./../../pages/create-place/assets/amenities/monitor.svg";
import { ReactComponent as Mail } from "./../../pages/create-place/assets/amenities/mail.svg";
import { ReactComponent as WhiteBoard } from "./../../pages/create-place/assets/amenities/whiteboard.svg";
import { ReactComponent as Video } from "./../../pages/create-place/assets/amenities/video.svg";

import { ReactComponent as Office } from "./office.svg";
import { ReactComponent as Coworking } from "./coworking.svg";
import { ReactComponent as Passes } from "./passes.svg";
import { ReactComponent as HotDesk } from "./hot-desk.svg";
import { ReactComponent as Events } from "./events.svg";
import { ReactComponent as Meeting } from "./meeting.svg";
import { ReactComponent as Retail } from "./retail.svg";
import { ReactComponent as OfficeCoworking } from "./office-c.svg";
import { ReactComponent as EventsCoworking } from "./events-c.svg";

export const commercial = [
  {title: 'Meeting rooms', img: <Meeting className={styles.icon}/>, name: "meeting rooms"},
  {title: 'Office space', img: <Office className={styles.icon}/>, name: "office space"},
  {title: 'Events', img: <Events className={styles.icon}/>, name: "event space"},
  {title: 'Retail Space', img: <Retail className={styles.icon}/>, name: "retail space"},
  {title: 'Other', img: <Other className={styles.icon}/>, name: "other"}
]

export const coworking = [
  {title: 'Hot desk', img: <HotDesk className={styles.icon}/>, name: "hot desk"},
  {title: 'Office space', img: <Office className={styles.icon}/>, name: "private office coworking"},
  {title: 'Meeting rooms', img: <Meeting className={styles.icon}/>, name: "private meeting rooms"},
  {title: 'Events', img: <EventsCoworking className={styles.icon}/>, name: "event space coworking"},
  {title: 'Membership Passes', img: <Passes className={styles.icon}/>, name: "membership passes"}
]

export const coworkingFirstItem = {title: 'Coworking', img: <Coworking className={styles.icon}/>, name: "coworking"};

export const amenitiesList = [
  {title: 'WiFi', img: <Wifi className={styles.icon}/>, name: "wifi"},
   {title: 'Accessibility', img: <Access className={styles.icon}/>, name: "access"},
   {title: 'Coffee/Tea/Water', img: <Drinks className={styles.icon}/>, name: "office_drinks"},
  {title: 'Reception desk', img: <Desk className={styles.icon}/>, name: "reception_desk"},
   {title: 'Kitchen', img: <Kitchen className={styles.icon}/>, name: "kitchen"},
  {title: 'Parking', img: <Parking className={styles.icon}/>, name: "parking"},
  {title: 'Pet friendly', img: <PetFriendly className={styles.icon}/>, name: "petFriendly"},
  {title: 'TV Monitor', img: <Monitor className={styles.icon}/>, name: "tv_monitor"},
  {title: 'Nursing room', img: <NursingRoom className={styles.icon}/>, name: "nursing_room"},
  {title: 'Security', img: <Security className={styles.icon}/>, name: "security"},
   {title: 'Receive mail', img: <Mail className={styles.icon}/>, name: "mail"},
   {title: 'Keyless entry', img: <Keyless className={styles.icon}/>, name: "keyless"},
   {title: 'Fully furnished', img: <Furnished className={styles.icon}/>, name: "fullyFurnished"},
   {title: 'Bathrooms', img: <Bathrooms className={styles.icon}/>, name: "bathrooms"},
   {title: 'Print/Scan/Copy', img: <Printer className={styles.icon}/>, name: "printer"}, 
   {title: 'White board', img: <WhiteBoard className={styles.icon}/>, name: "white_board"},   
  {title: 'Video/Voice Conferencing', img: <Video className={styles.icon}/>, name: "video"},
]



import {createAction, handleActions} from "redux-actions";
import {all, call, put, take} from "redux-saga/effects";
import {resource} from "../../service/http";
import {popupWarning} from "./popupReduser";

const namespace = 'footer';

const SUBSCRIBE = `${namespace}/SUBSCRIBE`;

export const subscribeAction = createAction(SUBSCRIBE);

const initialState = {
    email: "",
};

export default handleActions(
    {
        [SUBSCRIBE]: (state, { payload }) => ({ ...state, ...payload }),
    },
    initialState
);

// export const subscribeSelector = (state) => state[namespace];

export function* subscribe() {
    while (true) {
        const {
            payload: {
                values,
                actions: { setSubmitting, resetForm, setFieldError, setStatus },
            },
        } = yield take(SUBSCRIBE);
        setSubmitting(true);
        try {
            const response = yield call(resource.post, '/subscribe', { ...values });
            if (response.status === 'success') {
                resetForm();
                setStatus({ sent: "You’re now subscribed to our newsletter"})
            } else if (response.message === 'This account is already exist') {
                setFieldError('email', 'This email is already taken');
            }
        } catch (err) {
            yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
            // console.log(err);
        } finally {
            setSubmitting(false);
        }
    }
}

export function* sagas() {
    yield all([subscribe()]);
}
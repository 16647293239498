import React from 'react';
import styles from '../busines-details.module.sass';

import ClassNames from 'classnames';
import {CheckArrowIcon} from "../../../../assets/svg";

const ProgressBar = ({ step, lastStep }) => {
    const steps = Array.from(Array(lastStep).keys()); // Fills array for map
    return (
        <>
            <div className={styles.progressBox}>
                {steps.map((item, idx) => (
                    <React.Fragment key={item + idx}>
                        <div
                            className={ClassNames(
                                item < step ? styles.progressNumberReady : styles.progressNumber,
                                item == step && styles.progressNumberBorder
                            )}
                        >
                            {item < step ? (
                                <CheckArrowIcon stroke="white"/>
                            ) : (
                                <span className={styles.progressSpan}>{item + 1}</span>
                            )}
                        </div>
                        {item !== lastStep - 1 && (
                            <div className={item < step ? styles.progressLineReady : styles.progressLine} />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};
export default ProgressBar;

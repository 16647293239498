import React from 'react';
import styles from './price-block.module.sass';
import minus from '../../../assets/Minus.svg';
import plus from '../../../assets/Plus.svg';
import classNames from 'classnames';
import { countIndividual } from '../../../aside/countIndividual';
import { prettifyPrice } from '../../../../../utils/helpers/helper';


const PriceBlock = ({
    withCounter,
    qty,
    setQty,
    typeOfData,
    type,
    availableDailyPasses,
    availableMonthlyPasses,
    availableHotDeskQty,
    place,
    disabledDatesString,
    date
}) => {
   
    let dateState = {}
    if(date?.type === 'hourly') {
        dateState = {
            data: new Date(date.startDate),
            time: {
                startTime: `${date.startTime.slice(0,2)}:${date.startTime.slice(2,4)} ${date.startTime.slice(4)}`,
                endTime: `${date.endTime.slice(0,2)}:${date.endTime.slice(2,4)} ${date.endTime.slice(4)}`
            },
            type: date.type
        }
    }
    if(date?.type === 'daily') {
        dateState = {
            data: [{
                startDate: new Date(date.startDate),
                endDate: new Date(date.endDate),
            }],
            type: date.type
        }
    }
    if(date?.type === 'monthly') {
        dateState = {
            data: [{
                startDate: new Date(date.startDate),
                endDate: new Date(date.endDate),
            }],
            months: date.number_months,
            type: date.type
        }
    }
   
    const price = countIndividual(place, dateState, disabledDatesString, qty)
   
    const qtyAvailable = type === 'hot desk' ? availableHotDeskQty : typeOfData === 'daily' ? availableDailyPasses : availableMonthlyPasses;
    React.useEffect(() => {
        setQty && setQty(1);
    }, [typeOfData])
    const increment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(qty >= qtyAvailable) {
            return;
        }
        setQty((q) => q + 1);
    };
    const decrement = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (qty < 2) {
            return;
        }
        setQty((q) => q - 1);
    };
    const onInputChange = (e) => {
        if(e.target.value < 0 || e.target.value > qtyAvailable) {
            return;
        }
        setQty(e.target.value)
    }
    
    return (
        <div>
            {withCounter && (
                <div className={styles.quantityWrapper}>
                    <label className={styles.quantityLabel} htmlFor="quantity">
                        Quantity available: {qtyAvailable}
                    </label>
                    <input id="quantity" className={styles.quantityInput} value={qty} onChange={onInputChange} />
                    <button className={classNames(styles.btn, styles.btnDecrement)} onClick={decrement}>
                        <img src={minus} />
                    </button>
                    <button className={classNames(styles.btn, styles.btnIncrement)} onClick={increment}>
                        <img src={plus} />
                    </button>
                </div>
            )}
           {/* <PriceInfo dateState={dateState} price={price} place={place}/> */}
        </div>
    );
};
export default PriceBlock


export const PriceInfo = ({ dateState, price, place }) => {
    return (
        <>
            {(dateState?.type === 'hourly' || dateState?.type === 'daily') && (
                <>
                    <div className={styles.row}>
                        <p>Timeline</p>
                        <p>${prettifyPrice(price.timeline, true)}</p>
                    </div>
                    <div className={styles.row}>
                        <p>Service fee</p>
                        <p>${prettifyPrice(price.serviceFee, true)}</p>
                    </div>
                    <div className={styles.row}>
                        <p>Total</p>
                        <p>${prettifyPrice(price.total, true)}</p>
                    </div>
                </>
            )}
            {dateState?.type === 'monthly' && (
                <>
                    {place.capturePrice > 0 && (
                        <div className={styles.row}>
                            <p>Secutiry deposit</p>
                            <p>${prettifyPrice(price.deposit, true)}</p>
                        </div>
                    )}
                    <div className={styles.row}>
                        <p>Service fee</p>
                        <p>${prettifyPrice(price.serviceFee, true)}</p>
                    </div>
                    <div className={styles.row}>
                        <p>Total due today</p>
                        <p>${prettifyPrice(price.total, true)}</p>
                    </div>
                    <div className={styles.row}>
                        <p>Monthly recurring</p>
                        <p>${prettifyPrice(price.recurring, true)}</p>
                    </div>
                </>
            )}

            {!dateState?.type && (
                <>
                    {' '}
                    <div className={styles.row}>
                        <p>Service fee</p>
                        <p>$0.00</p>
                    </div>
                    <div className={styles.row}>
                        <p>Total</p>
                        <p>$0.00</p>
                    </div>
                </>
            )}
        </>
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import styles from './header.module.sass';
import Image from '../ui/image';
import logo from '../assets/images/Logo.png';
import Typography from '../ui/typography';
import Authorization from '../../service/Authorization';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelector } from '../../store/reducers/auth';
import Load from '../ui/loader';
import { searchLoad, searchSelector } from '../../store/reducers/search';
import { createImageUrl } from '../../utils/imageUrl';
import { userSelector } from '../../store/reducers/user';
import Role from '../../service/Role';
import { useBlockBodyScroll } from '../../pages/create-place/useBlockBodySrcoll';
import { useMedia } from 'use-media';
import dot from './dot.svg';
import { useQuery } from '../../components/hooks/useQuery';
import PriceFilter from '../../components/new-filters/PriceFilter';
import MoreFilters from '../../components/new-filters/MoreFilters';
import { amenitiesList } from '../../components/new-filters/utils';
import { format } from 'date-fns';
import { convertToAmFormat } from '../../utils/helpers/timeHelpers';
import trash from '../../pages/search-page/trash.svg';
import { signupRoutes } from '../../utils/routing';

const SearchHeader = ({
    defaultActivity,
    refInteraction,
    setMobileSearch,
    clearAll,
    indicatorState,
    setIndicatorState,
}) => {
    // const { indicatorState, setIndicatorState } = props;
    const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
    // const isMobileButtons = useMedia({ maxWidth: '550px' });
    const role = useSelector(roleSelector);
    const { load } = useSelector(searchLoad);
    const user = useSelector(userSelector);
    const isMobile = useMedia({ maxWidth: '990px' });
    //const [mobileSearch, setMobileSearch] = React.useState(false);
    const dispatch = useDispatch();
    useBlockBodyScroll(openMobileMenu);
    let accountUrl = '/';
    if (role === 'host') {
        accountUrl = '/account/spaces';
    } else if (role === 'tenant') {
        accountUrl = '/account/requests';
    }
    const openMobileMenuClick = () => {
        setOpenMobileMenu(!openMobileMenu);
    };
    const { city, places, pagination } = useSelector(searchSelector);
    const [amenities, setAmenities] = React.useState([]);
    const [squareMin, setSquareMin] = React.useState('');
    const [squareMax, setSquareMax] = React.useState('');
    const [kind, setKind] = React.useState([]);

    // const [indicatorState, setIndicatorState] = React.useState(false);
    // console.log('-> indicatorState', indicatorState);

    const amenitiesValues = amenitiesList.map((item) => item.name);
    const { query } = useSelector(({ router: { location } }) => location);
    React.useEffect(() => {
        if (!Object.keys(query).length) {
            setAmenities([]);
            setSquareMin('');
            setSquareMax('');
            setKind([]);
        } else {
            setAmenities([]);
            setSquareMin('');
            setSquareMax('');
            setKind([]);
            Object.keys(query).forEach((item) => {
                if (amenitiesValues.includes(item)) {
                    setAmenities((state) => [...state, item]);
                }
                if (item === 'squareMin') {
                    setSquareMin(query[item]);
                }
                if (item === 'squareMax') {
                    setSquareMax(query[item]);
                }
                if (item === 'kind') {
                    setKind(query[item]?.split('%2C').map((item) => item.split('+').join(' ')));
                }
            });
        }
    }, [query]);
    const editQuery = useQuery();
    const capacity = editQuery.get('capacity');
    const hoursStart = editQuery.get('hoursstart');
    const hoursEnd = editQuery.get('hoursend');
    const date = editQuery.get('date');
    const dateStart = editQuery.get('datestart');
    const dateEnd = editQuery.get('dateend');
    const monthly = editQuery.get('months');
    const type = editQuery.get('type');
    const searchCity = useQuery().get('city_id');
    const searchMap = useQuery().get('x1');
    const priceSort = useQuery().get('priceSort');
    const priceMin = useQuery().get('priceMin');
    const priceMax = useQuery().get('priceMax');

    const showDateValue = () => {
        if (!type) {
            return 'Date, Time, Length';
        }
        if (type === 'hourly') {
            return `${format(
                new Date(`${date?.split('-')[1]}/${date?.split('-')[0]}/${date?.split('-')[2]}`),
                'MMM d'
            )}, ${convertToAmFormat(`${hoursStart?.slice(0,2)}:${hoursStart?.slice(2)}`)}-${convertToAmFormat(`${hoursEnd?.slice(0,2)}:${hoursEnd?.slice(2)}`)}`;
        }
        if (type === 'daily') {
            if (dateStart === dateEnd) {
                return format(
                    new Date(`${dateStart?.split('-')[1]}/${dateStart?.split('-')[0]}/${dateStart?.split('-')[2]}`),
                    'MMM d'
                );
            }
            return `${format(
                new Date(`${dateStart?.split('-')[1]}/${dateStart?.split('-')[0]}/${dateStart?.split('-')[2]}`),
                'MMM d'
            )}-${format(
                new Date(`${dateEnd?.split('-')[1]}/${dateEnd?.split('-')[0]}/${dateEnd?.split('-')[2]}`),
                'MMM d'
            )}`;
        }
        if (type === 'monthly') {
            return `${format(
                new Date(`${dateStart?.split('-')[1]}/${dateStart?.split('-')[0]}/${dateStart?.split('-')[2]}`),
                'MMM d'
            )}, ${monthly} month${+monthly > 1 ? 's' : ''}`;
        }
    };
    return (
        <>
            {load && <Load />}
            <header className={styles.search__header}>
                <div className={styles.top_wrapper}>
                    <div className={ClassNames(styles.content, styles.content__search)}>
                        {!openMobileMenu && (
                            <Link to="/" className={styles.logo__container}>
                                <Image className={styles.logo} src={logo} alt="logo" />
                            </Link>
                        )}
                        {/* {!openMobileMenu && (
                        <Search
                            refInteraction={refInteraction}
                            defaultActivity={defaultActivity}
                            defaultCity={defaultCity}
                            className={styles.search__component}
                            formClassName={styles.form}
                            buttonClassName={styles.search__button}
                            maxSize="120px"
                            selectProps={{
                                item: styles.select__item,
                                value: styles.select__value,
                                label: styles.select__label,
                                dropdown: styles.select__dropdown,
                                separator: styles.select__separator,
                                options: styles.options,
                                search: styles.dropdown__search,
                                dropdownOptionContainer: styles.select__dropdown_options,
                                searchClassNameContainer: styles.select__search_container,
                                gutter: 10,
                            }}
                        />
                    )} */}
                        <ul variant="ul" className={ClassNames(styles.nav, styles.search__navigation)}>
                            <NavLink
                                exact
                                to="/contact"
                                className={styles.nav__item}
                                activeClassName={styles.nav__item_active}
                            >
                                <Typography fontVariant="bold" variant="span">
                                    Contact us
                                </Typography>
                            </NavLink>
                            <NavLink
                                to="/search"
                                exact
                                className={ClassNames(styles.nav__item, styles.nav__item_adaptive)}
                                activeClassName={styles.nav__item_active}
                            >
                                <Typography fontVariant="bold" variant="span">
                                    Search spaces
                                </Typography>
                            </NavLink>
                            <Authorization>
                                <Link
                                    exact
                                    to={"/become-a-host"}
                                    className={ClassNames(styles.nav__item, styles.host)}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Become a Host
                                    </Typography>
                                </Link>
                                <NavLink
                                    exact
                                    to={`/registration-guest/${signupRoutes[0]}`}
                                    className={styles.nav__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Sign Up
                                    </Typography>
                                </NavLink>
                                <NavLink
                                    exact
                                    to="/login"
                                    className={styles.nav__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Sign In
                                    </Typography>
                                </NavLink>
                            </Authorization>
                            <Role auth role="host">
                                <NavLink
                                    exact
                                    to="/start-listing"
                                    activeClassName={styles.nav__item}
                                    className={ClassNames(
                                        styles.nav__item,
                                        styles.nav__item_adaptive,
                                        styles.nav__item__border
                                    )}
                                >
                                    <Typography fontVariant="bold" variant="span" data_atr="listSpace">
                                        List your space
                                    </Typography>
                                </NavLink>
                            </Role>
                            <Authorization auth>
                                {accountUrl && (
                                    <NavLink
                                        exact
                                        to={accountUrl}
                                        className={styles.nav__item}
                                        activeClassName={styles.nav__item_active}
                                    >
                                        <div className={styles.user__avatar__block}>
                                            {user.avatarUrl && (
                                                <Image
                                                    src={createImageUrl(user.avatarUrl, 50)}
                                                    className={styles.user__avatar}
                                                />
                                            )}
                                            {!user.avatarUrl && user.firstName && !user.lastName && (
                                                <div className={styles.user__avatar__letter}>
                                                    {user.firstName.substring(0, 1)}
                                                </div>
                                            )}
                                            {!user.avatarUrl && user.firstName && user.lastName && (
                                                <div className={styles.user__avatar__letter}>
                                                    {user.firstName.substring(0, 1)}
                                                    {user.lastName.substring(0, 1)}
                                                </div>
                                            )}
                                        </div>
                                        <Typography fontVariant="bold" variant="span">
                                            My profile
                                        </Typography>
                                    </NavLink>
                                )}
                            </Authorization>
                        </ul>

                        {/* {openMobileMenu && (
                        <ul variant="ul" className={styles.mobile__menu}>
                            <li className={styles.mobile__item}>
                                <NavLink
                                    exact
                                    to="/contact"
                                    className={styles.nav__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Contact us
                                    </Typography>
                                </NavLink>
                            </li>
                            <Authorization>
                                <NavLink
                                    exact
                                    to="/login"
                                    className={styles.mobile__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Sign In
                                    </Typography>
                                </NavLink>
                                <NavLink
                                    exact
                                    to="/registration-guest"
                                    className={styles.mobile__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Sign Up
                                    </Typography>
                                </NavLink>
                                <Link
                                    exact
                                    to="/registration-host"
                                    className={ClassNames(styles.nav__item, styles.host)}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        Become a Host
                                    </Typography>
                                </Link>
                            </Authorization>
                            <Authorization auth>
                                <NavLink
                                    exact
                                    to={accountUrl}
                                    className={styles.mobile__item}
                                    activeClassName={styles.nav__item_active}
                                >
                                    <Typography fontVariant="bold" variant="span">
                                        My account
                                    </Typography>
                                </NavLink>
                            </Authorization>
                        </ul>
                    )} */}
                        <div
                            className={ClassNames(styles.mobile__menu, styles.mobile__menu_search, {
                                [styles.active]: openMobileMenu,
                            })}
                        >
                            <div className={styles.menu__top}>
                                <h4 className={styles.menu__title}>Menu</h4>
                            </div>
                            <div className={styles.links__wrapper}>
                                {!role && (
                                    <Authorization>
                                        <Link
                                            onClick={openMobileMenuClick}
                                            data_atr="burger_signup"
                                            exact
                                            to={`/registration-guest/${signupRoutes[0]}`}
                                            className={styles.mobile__item}
                                        >
                                            <Typography fontVariant="bold" variant="span">
                                                Sign Up
                                            </Typography>
                                        </Link>
                                    </Authorization>
                                )}
                                {!role && (
                                    <Authorization>
                                        <Link
                                            onClick={openMobileMenuClick}
                                            data_atr="sign_in"
                                            exact
                                            to="/login"
                                            className={styles.mobile__item}
                                        >
                                            <Typography fontVariant="bold" variant="span">
                                                Sign In
                                            </Typography>
                                        </Link>
                                    </Authorization>
                                )}
                                <Link
                                    onClick={openMobileMenuClick}
                                    data_atr="burger_contact"
                                    to="/contact"
                                    exact
                                    className={ClassNames(styles.mobile__item)}
                                >
                                    Contact us
                                </Link>
                                <Link
                                    to="/blog"
                                    className={styles.mobile__item}
                                    data_atr="burger_blog"
                                >
                                    Blog
                                </Link>
                                <Link
                                    to="/faq"
                                    className={styles.mobile__item}
                                    data_atr="burger_FAQ"
                                >
                                    FAQ
                                </Link>
                                <Link
                                    onClick={openMobileMenuClick}
                                    data_atr="burger_contact"
                                    to="/search"
                                    exact
                                    className={ClassNames(styles.mobile__item)}
                                >
                                    Find a Space
                                </Link>
                                {!role && (
                                    <Authorization>
                                        <Link
                                            exact
                                            onClick={openMobileMenuClick}
                                            data_atr="burger_becomehost"
                                            to={"/become-a-host"}
                                            className={ClassNames(styles.nav__item, styles.host, styles.mobile__host)}
                                        >
                                            <Typography fontVariant="bold" variant="span">
                                                Become a Host
                                            </Typography>
                                        </Link>
                                    </Authorization>
                                )}
                                <Authorization auth>
                                    <Link
                                        onClick={openMobileMenuClick}
                                        data_atr="my_acc"
                                        to={accountUrl}
                                        className={styles.mobile__item}
                                    >
                                        <div className={styles.user__avatar__block}>
                                            {user.avatarUrl && (
                                                <Image
                                                    src={createImageUrl(user.avatarUrl, 50)}
                                                    className={styles.user__avatar}
                                                />
                                            )}
                                            {!user.avatarUrl && user.firstName && !user.lastName && (
                                                <div className={styles.user__avatar__letter}>
                                                    {user.firstName.substring(0, 1)}
                                                </div>
                                            )}
                                            {!user.avatarUrl && user.firstName && user.lastName && (
                                                <div className={styles.user__avatar__letter}>
                                                    {user.firstName.substring(0, 1)}
                                                    {user.lastName.substring(0, 1)}
                                                </div>
                                            )}{' '}
                                        </div>
                                        <Typography fontVariant="bold" variant="span">
                                            My profile
                                        </Typography>
                                    </Link>
                                </Authorization>
                                <Role auth role="host">
                                    <Link
                                        onClick={openMobileMenuClick}
                                        data_atr="listSpace"
                                        exact
                                        to="/start-listing"
                                        className={ClassNames(styles.mobile__item)}
                                    >
                                        <Typography fontVariant="bold" variant="span">
                                            List your space
                                        </Typography>
                                    </Link>
                                </Role>
                            </div>
                        </div>

                        <button
                            className={ClassNames(styles.burger__menu, styles.burger__search)}
                            data_atr="burger_menu"
                            onClick={openMobileMenuClick}
                        >
                            <div
                                className={ClassNames(styles.burger__wrapper, {
                                    [styles.open]: openMobileMenu,
                                })}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>

                {isMobile && (
                    <>
                        <div
                            className={styles.search__block}
                            onClick={(e) => {
                                setMobileSearch(true);
                            }}
                        >
                            <div className={styles.date__wrapper}>
                                {<p>{showDateValue()}</p>}
                                <div className={styles.imgWrapper}>
                                    <img src={dot} />
                                </div>
                                {capacity ? <p>{capacity} guests</p> : <p>Space capacity</p>}
                            </div>
                            <div className={styles.city}>{city ? city?.name : 'Location by city or zip'}</div>
                        </div>
                        <div className={styles.filterWrapper}>
                            <p className={styles.searchResults}>
                                {(pagination && pagination.total) || (places && places.length) || 0} search results
                            </p>
                            <div className={styles.filtersRight}>
                                {searchCity ||
                                capacity ||
                                type ||
                                searchMap ||
                                amenities.length ||
                                kind.length ||
                                squareMin ||
                                squareMax ||
                                priceSort ||
                                priceMin ||
                                priceMax ||
                                (indicatorState === true && isMobile) ? (
                                    <button onClick={clearAll} className={styles.clearAll}>
                                        <img src={trash} />
                                    </button>
                                ) : null}

                                <PriceFilter indicatorState={indicatorState} setIndicatorState={setIndicatorState} />
                                <MoreFilters
                                    amenities={amenities}
                                    squareMin={squareMin}
                                    squareMax={squareMax}
                                    kind={kind}
                                />
                            </div>
                        </div>
                    </>
                )}
            </header>
        </>
    );
};

SearchHeader.propTypes = {
    defaultActivity: PropTypes.string,
    defaultCity: PropTypes.string,
};
SearchHeader.defaultProps = {
    defaultActivity: '',
    defaultCity: '',
};

export default SearchHeader;

import { businessStructureSelector } from '../../../../../store/reducers/nativeVerification';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MultiMember from './forms/MultiMember';
import SoleProprietorship from './forms/SoleProprietorship';
import { push } from 'connected-react-router';
import SingleMember from './forms/SingleMember';
import PrivatePartnership from './forms/PrivatePartnership';
import PrivateCompany from './forms/PrivateCompany';

const FormSwitcher = () => {
    const type = useSelector(businessStructureSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!type) {
            dispatch(push('/'));
        }
    }, []);

    switch (type.value) {
        case 'multi_member_llc':
            return <MultiMember />;
            break;
        case 'sole_proprietorship':
            return <SoleProprietorship />;
            break;
        case 'single_member_llc':
            return <SingleMember />;
            break;
        case 'private_partnership':
            return <PrivatePartnership />;
            break;
        case 'private_corporation':
            return <PrivateCompany />;
            break;

        default:
            return <h1>Multi-member LLC and Sole proprietorship can open another in progress (need design ?)</h1>; // To Do  ///
    }
};

export default FormSwitcher;

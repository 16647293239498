import { useEffect } from 'react';
export function useOnClickOutside(ref, handler) {
  useEffect(
      () => {
          const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || ref.current.contains(event.target)) {
                  return;
              }
              handler(event);
          };
          document.addEventListener('mousedown', listener);
          document.addEventListener('touchstart', listener);
          return () => {
              document.removeEventListener('mousedown', listener);
              document.removeEventListener('touchstart', listener);
          };
      },
     
      [ref, handler]
  );
}

export function useOnClickOutsideEdit(ref, ref2, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target) || ref2.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
       
        [ref, handler]
    );
  }

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'use-media';

export const useBlockBodyScroll = (modal) => {
  const [scroll, setScroll] = React.useState(0);
  const location = useLocation();
  const isEdit = location?.pathname?.includes('edit');
  const isMobile = useMedia({maxWidth: '990px'});
  React.useEffect(() => {
    if (modal) {
        if(isMobile){
            document.body.style.overflow = "hidden";
        }else{
            document.body.style.position = "fixed";
            document.body.style.width = "100%";
            document.body.style.overflowY = "scroll";    
        }    
       
    } else {
        if(isMobile){
            document.body.style.overflow= "";
        }else{
            document.body.style.position = "";
            document.body.style.overflowY = "";
            document.body.style.width = "";
        }   
    }
    return () => {
        document.body.style.position = "";
        document.body.style.overflowY = "";
        document.body.style.width = "";
    };
}, [modal]);
}

export const useBlockBodyScrollSearch = (modal) => {
    const [scroll, setScroll] = React.useState(0);
    React.useEffect(() => {
      if (modal) {
          document.body.style.top = -window.scrollY + "px";
          setScroll(window.scrollY);
          document.body.style.position = "fixed";
          document.body.style.width = "100%";
          document.body.style.overflowY = "scroll";    
      } else {
          document.body.style.top = "";
          document.body.style.position = "";
          document.body.style.overflowY = "";
          document.body.style.width = "";
          window.scrollTo({top: scroll})
      }
      return () => {
          document.body.style.position = "";
          document.body.style.overflowY = "";
          document.body.style.width = "";
      };
  }, [modal]);
  }
  
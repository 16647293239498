// $ 8000 -> $ 8,000
// export function prettifyPrice(x) {
//     let parts = parseFloat(x).toFixed(2).toString().split(".");
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     if(parts[1] === "00") {
//         return parseFloat(parts[0]);
//     } else {
//         return parseFloat(parts.join(".")).toFixed(2);
//     }
// }

export function prettifyPrice(x, showZeros = false) {
    let parts = parseFloat(x).toFixed(2).split(".");   
    if(parts[1] === "00" && showZeros===true) {
        return `${Number(parts[0]).toLocaleString('en-EN')}.00`;
    } else if(parts[1] === "00") {
        return Number(parts[0]).toLocaleString('en-EN');
    } else {
        return `${Number(parts[0]).toLocaleString('en-EN')}.${parts[1]}`
    }
}

// 0.9766 -> 0.97 (without rounding)
export function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
}

export const findMinPrice = (hour, day, month, type, priceMin, priceMax) => {
    const prices = [
        { price: hour, type: 'hour' },
        { price: day, type: 'day' },
        { price: month, type: 'month' },
    ];
    if (type === 'hourly') {
        return prices[0];
    }
    if (type === 'daily') {
        return prices[1];
    }
    if (type === 'monthly') {
        return prices[2];
    }


    let filtered = prices.filter((item) => item.price);
    if(priceMin || priceMax) {
        filtered = prices.filter((item) => item.price).filter(item => Math.abs(item.price/100) >= +priceMin && Math.abs(item.price/100) <= +priceMax);
    }

    if (!filtered.length) {
        return { price: 0, type: 'day' };
    }
    const min = filtered.reduce((acc, item) => (acc < item?.price ? acc : item?.price), filtered[0]?.price);
    const minObj = prices.find((item) => item?.price === min);

    return minObj;
};

export const findMaxPrice = (hour, day, month, type, priceMin, priceMax) => {
    const prices = [
        { price: hour, type: 'hour' },
        { price: day, type: 'day' },
        { price: month, type: 'month' },
    ];
    if (type === 'hourly') {
        return prices[0];
    }
    if (type === 'daily') {
        return prices[1];
    }
    if (type === 'monthly') {
        return prices[2];
    }

    let filtered = prices.filter((item) => item.price);
    if(priceMin || priceMax) {
        filtered = prices.filter((item) => item.price).filter(item => Math.abs(item.price/100) >= +priceMin && Math.abs(item.price/100) <= +priceMax);
    }
    if (!filtered.length) {
        return { price: 0, type: 'day' };
    }
    const max = filtered.reduce((acc, item) => (acc > item?.price ? acc : item?.price), filtered[0]?.price);
    const maxObj = prices.find((item) => item?.price === max);

    return maxObj;
};

export function countServiceFee (number) {
    return Number((number * 0.095).toFixed())
}

export function timeConverter(UNIX_timestamp){
    let a = new Date(UNIX_timestamp * 1000);
    let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let time =  month + ' ' + date + ', ' + year;
    return time;
}

export function toTimestamp(strDate){
    let datum = Date.parse(strDate);
    return datum/1000;
}


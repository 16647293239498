import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { all, call, put, take } from 'redux-saga/effects';
import { resource } from '../../service/http';

const URL = '/space';
const namespace = 'place';

const GET_PLACE = `${namespace}/GET_PLACE`;
const SET_PLACE = `${namespace}/SET_PLACE`;
const GET_RECOMEND_PLACE = `${namespace}/GET_RECOMEND_PLACE`;
const SET_RECOMEND_PLACE = `${namespace}/SET_RECOMEND_PLACE`;
const SET_PLACE_ACTIVITIES = `${namespace}/SET_PLACE_ACTIVITIES`;
const RESET_PLACE = `${namespace}/RESET_PLACE`;

const SET_OPTIONS = `${namespace}/SET_OPTIONS`;
const SET_BOOKINGS = `${namespace}/SET_BOOKINGS`;
const SET_LAST_BOOKINGS = `${namespace}/SET_LAST_BOOKINGS`;
const SET_HOST_BOOKINGS = `${namespace}/SET_HOST_BOOKINGS`;

const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_LOAD = `${namespace}/SET_LOAD`;

const CANCEL_BOOKING = `${namespace}/CANCEL_BOOKING`;

export const getPlace = createAction(GET_PLACE);
export const setPlace = createAction(SET_PLACE);
export const getRecomendPlace = createAction(GET_RECOMEND_PLACE);
export const setRecomendPlace = createAction(SET_RECOMEND_PLACE);
export const setPlaceActivities = createAction(SET_PLACE_ACTIVITIES);
export const resetPlace = createAction(RESET_PLACE);
export const setOptions = createAction(SET_OPTIONS);
export const setBookings = createAction(SET_BOOKINGS);
export const setLastBookings = createAction(SET_LAST_BOOKINGS);
export const setHostBookings = createAction(SET_HOST_BOOKINGS);
export const setError = createAction(SET_ERROR);
export const setLoad = createAction(SET_LOAD);
export const canselBooking = createAction(CANCEL_BOOKING);

const initialState = {
    place: null,
    placeActivities: null,
    recomendPlaces: null,
    options: null,
    bookings: null,
    lastBookings: null,
    hostBookings: null,
    load: false,
    error: false,
};

export default handleActions(
    {
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_PLACE]: (state, { payload }) => ({ ...state, place: payload }),
        [SET_RECOMEND_PLACE]: (state, { payload }) => ({ ...state, recomendPlaces: payload }),
        [SET_OPTIONS]: (state, { payload }) => ({ ...state, options: payload }),
        [SET_BOOKINGS]: (state, { payload }) => ({ ...state, bookings: payload }),
        [SET_LAST_BOOKINGS]: (state, { payload }) => ({ ...state, lastBookings: payload }),
        [SET_HOST_BOOKINGS]: (state, { payload }) => ({ ...state, hostBookings: payload }),
        [SET_PLACE_ACTIVITIES]: (state, { payload }) => ({ ...state, placeActivities: payload }),
        [RESET_PLACE]: () => initialState,
    },
    initialState
);

export const placeSelecor = (state) => state[namespace];

function* canselBookingRequest() {
    while (true) {
        const { payload, f } = yield take(CANCEL_BOOKING);
        yield put(setLoad(true));
        const response = yield call(resource.post, `/booking-request/cancel`, { booking_id: payload });

        if (response.status === 'cancelled') {
            yield put(push('/account/'));
        }
        yield put(setLoad(false));
    }
}

export function* getPlaceRequest() {
    while (true) {
        const { payload } = yield take(GET_PLACE);
        yield put(setLoad(true));
        try {
            const [places, { spaces: recomendPlaces }] = yield all([
                call(resource.get, `${URL}/${payload}`),
                call(resource.get, `/featured-spaces-by-space`, { spaceId: payload }),
            ]);
            const { space: place, activities, bookings, userLastBooking, hostBookings } = places;
            yield put(setPlace(place));
            yield put(setPlaceActivities(activities));
            yield put(setRecomendPlace(recomendPlaces));

            const options = {
                activities: activities?.map(({ id, title }) => ({ value: id, label: title })) || null,
            };
            yield put(setOptions(options));
            yield put(setBookings(bookings));
            yield put(setLastBookings(userLastBooking || null));

            if (hostBookings) {
                yield put(setHostBookings(hostBookings));
            }
        } catch (error) {
            // console.log(error);
            yield put(setError(error.message ? error.message : error));
            yield put(push('/page-not-found'));
            // yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));;
        } finally {
            yield put(setLoad(false));
        }
    }
}

export const sagas = function* () {
    yield all([getPlaceRequest(), canselBookingRequest()]);
};

import React, { useRef } from 'react';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styles from './input-control.module.sass';
import Typography from '../typography';

/**
 *
 *  The  params with Formik https://formik.org/docs/api/
 */

const InputControl = ({
    maxlength,
    className,
    label,
    labelClassName,
    error,
    refEffect,
    type,
    children,
    message,
    // onChange,
    placeholder,
    hidden,
    variant: Component,
    autocomplete,
    data_atr,
    ...props
}) => {
    // document.addEventListener(
    //     'touchmove',
    //     function (event) {
    //         if (event.scale !== 1) {
    //             event.preventDefault();
    //         }
    //     },
    //     { passive: false }
    // );
    const htmlFor = `${type}-${Math.random()}`;
    const wrapperRef = useRef(null);
    const maxLength = (inputObj) => {
        const { value } = inputObj;
        if (value.toString().length <= props.valueLength) return true;
        return false;
    };

    const allowedProps = {};
    if (props.valueLength) {
        allowedProps.isAllowed = maxLength;
    }

    const attrs = props.field ? { ...props.field } : { ...props };
    let errorStyle;

    if (props.editVerificationFlow) {
        errorStyle = props.form.errors && props.form.errors[attrs.name];
    } else {
        errorStyle = props.form
            ? props.form.errors && props.form.errors[attrs.name] && props.form.touched && props.form.touched[attrs.name]
            : error;
    }

    const value = props.field ? (props.field.value ? props.field.value : '') : props.value;
    return (
        <div className={ClassNames(props.erorStyle ? styles.eror : styles.wrapper, className)} ref={wrapperRef}>
            <label
                className={ClassNames(
                    styles.label,
                    errorStyle ? styles.error : '',
                    labelClassName,
                    value ? styles.isActiveLabel : ''
                )}
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <Component
                autoComplete={autocomplete}
                id={htmlFor}
                type={type}
                {...attrs}
                value={value}
                {...allowedProps}
                className={ClassNames(errorStyle ? styles.error : null, value ? styles.isActiveInput : '')}
                placeholder={placeholder}
                data_atr={data_atr ? data_atr : null}
                // onChange={onChange}
                wrap="hard"
                maxLength={maxlength}
            />
            {children}
            {!props.editVerificationFlow && attrs.name && message && (
                <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                    <ErrorMessage name={attrs.name} />
                </Typography>
            )}
            {props.editVerificationFlow && props?.form?.errors[attrs.name] && (
                <Typography className={styles.message} variant="div" fontVariant="subtextMini">
                    {props?.form?.errors[attrs.name]}
                </Typography>
            )}
        </div>
    );
};

InputControl.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node,
    message: PropTypes.bool,
    error: PropTypes.string,
    labelClassName: PropTypes.string,
    placeholder: PropTypes.string,
    refEffect: PropTypes.func,
    variant: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};
InputControl.defaultProps = {
    type: 'text',
    children: null,
    message: false,
    error: '',
    labelClassName: '',
    placeholder: '',
    refEffect: () => {},
    variant: 'input',
};

export default InputControl;

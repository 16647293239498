/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import ClassNames from 'classnames';
import Image from '../image';
import Typography from '../typography';
import styles from './dropdown-image.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { photoErrorSelector, setPhotoError } from '../../../store/reducers/create-place';
import { useLocation } from 'react-router-dom';
import useMedia from 'use-media';
import { CloseBtn } from '../../../assets/svg';

function prepareFiles(files) {
    if (files instanceof FileList && files.length) {
        const arr = [];
        for (let i = 0; i < files.length; i++) {
            arr.push(files[i]);
        }
        return arr;
    }
    return null;
}

function validateImages(arr) {
    if (!Array.isArray(arr)) {
        return null;
    }

    return arr.reduce((acc, e) => {
        const { type } = e;
        const validElement = type.split('/')[0];
        if (validElement === 'image') {
            acc = [...acc, e];
        }
        return acc;
    }, []);
}

const DropdownImageVerificationFlow = (
    {
        field: { name },
        form: { values, setFieldValue, setFieldError, setErrors },
        form,
        onChangeImages,
        onDeleteImage,
        onSetCoverImage,
        images,
        placeId,
        flow,
        editVerificationFlow,
    },
    load
) => {
    const photoError = useSelector(photoErrorSelector);
    const [menu, setMenu] = React.useState({});
    // console.log('-> form', form);

    useEffect(() => {
        if (editVerificationFlow) {
            form.validateForm();
        }
    }, [values]);

    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        setTimeout(() => form.validateForm(), 100);
    }, []);

    useEffect(() => {
        const ids = images?.map((item) => item.id).reduce((acc, item) => ({ ...acc, [item]: false }), {});
        setMenu(ids);
    }, [images]);

    useEffect(() => {
        if (images?.length) {
            const data = images.map((e) => e.id);
            setFieldValue('file', [...data]);
            setTimeout(() => setErrors({}), 500);
        }
    }, []);

    useMedia({ maxWidth: '990px' });
    const isMobile = useMedia({ maxWidth: '768px' });
    React.useEffect(() => {
        if (menu && Object.keys(menu).find((item) => menu[item]) && isMobile) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [menu, isMobile]);

    const handleDrop = (e) => {
        e.preventDefault();
        const { files } = e.dataTransfer;
        const prepare = prepareFiles(files);
        const validResult = validateImages(prepare);

        if ([files].some(({ size }) => size > 10800332)) {
            dispatch(setPhotoError('Images must be smaller than 10mb.'));
            return;
        } else if (files.length === 0) {
            return;
        } else if (files.length > 10) {
            dispatch(setPhotoError('You cannot upload more than 10 images at a time.'));
            return;
        }
        onChangeImages(validResult, setFieldValue);
    };
    // const alert = useMessage();
    const inputRef = React.useRef(null);
    const handleChange = ({ target, err }) => {
        const { files } = target;
        const prepare = prepareFiles(files);
        const validResult = validateImages(prepare);

        if (target?.files[0]?.size > 10800332) {
            // alert.danger(
            //     'One or more of the images you tried to upload are too large. Please make sure images are 10mb or less.'
            // );
            dispatch(setPhotoError('Images must be smaller than 10mb.'));
            return;
        } else if (target.files.length === 0) {
            // alert.danger('You need to upload photo');
            return;
        } else if (target.files.length > 10) {
            //alert.danger('You can not upload more  than 10 photos at the same time');
            dispatch(setPhotoError('You cannot upload more than 10 images at a time.'));
            return;
        }
        onChangeImages(validResult, setFieldValue, placeId);
    };
    useEffect(() => {
        return () => {
            dispatch(setPhotoError(null));
        };
    }, []);

    return (
        <div className={styles.container}>
            <div
                className={ClassNames(styles.imaageWrapper, {
                    [styles.photoError]: photoError,
                    [styles.textCenter]: true,
                    [styles.imaageWrapperSpace]: !images?.length,
                })}
                onDragEnter={images && images.length ? () => {} : (e) => e.preventDefault()}
                onDragLeave={images && images.length ? () => {} : (e) => e.preventDefault()}
                onDragOver={images && images.length ? () => {} : (e) => e.preventDefault()}
                onDrop={images && images.length ? () => {} : handleDrop}
            >
                <label className={!images?.length ? styles.intoLabel : null}>
                    {!images?.length && (
                        <input
                            className={styles.input}
                            type="file"
                            accept=".jpg,.png,.jpeg,.jpe,.HEIC"
                            multiple={false}
                            ref={inputRef}
                            onChange={handleChange}
                            name={name}
                            //onClick={() => dispatch(setPhotoError(null))}
                        />
                    )}

                    <div className={styles.imageFlex}>
                        {images && images.length > 0 && (
                            <>
                                {images.map((item) => (
                                    <div className={styles.miniPicCloseBox} key={item.id}>
                                        <Image
                                            src={item.serverUrl}
                                            width={100}
                                            height={64}
                                            className={styles.miniPicCloseBox__blur}
                                        />
                                        <div
                                            className={styles.close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onDeleteImage(item.serverUrl, setFieldValue);
                                            }}
                                        >
                                            <CloseBtn stroke="#2A1BA1" />
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div>
                        <Typography
                            variant="p"
                            fontVariant="heavy"
                            className={ClassNames(styles.label__description, {
                                [styles.label__small]: images && images.length,
                            })}
                        >
                            Front of your ID document
                        </Typography>
                        <Typography
                            variant="p"
                            className={ClassNames(styles.subtitle, {
                                [styles.subtitle_small]: images && images.length,
                            })}
                        >
                            Drag and drop or upload an image of the front of your identity document here
                        </Typography>
                    </div>
                    <button
                        className={ClassNames(styles.choose, { [styles.choose_small]: images && images.length })}
                        style={{ pointerEvents: 'none' }}
                        type="button"
                    >
                        Choose file
                    </button>
                </label>
                {photoError && (
                    <Typography
                        className={styles.errorMessage}
                        variant="p"
                        style={location.pathname.includes('list-space') ? { bottom: '-28px' } : { bottom: '-36px' }}
                    >
                        {photoError}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default DropdownImageVerificationFlow;

import { push } from 'connected-react-router';
import { createAction, handleActions } from 'redux-actions';
import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { resource } from '../../service/http';
import { getDealHistory } from './dealHistory';
import { popupSuccess, popupWarning } from './popupReduser';

const namespace = 'account';

const GET_BOOKING_ITEM = `${namespace}/GET_BOOKING_ITEM`;
const SET_BOOKING_ITEM = `${namespace}/SET_BOOKING_ITEM`;
const SET_BOOKING_PAG = `${namespace}/SET_BOOKING_PAG`;
const GET_BOOKING_PAG = `${namespace}/GET_BOOKING_PAG`;

const GET_REQUEST_ITEM = `${namespace}/GET_REQUEST_ITEM`;
const SET_REQUEST_ITEM = `${namespace}/SET_REQUEST_ITEM`;
const GET_LOCATIONS_ITEM = `${namespace}/GET_LOCATIONS_ITEM`;
const SET_LOCATIONS_ITEM = `${namespace}/SET_LOCATIONS_ITEM`;
const GET_SPACE_REQUEST_ITEM = `${namespace}/GET_SPACE_REQUEST_ITEM`;
const SET_SPACE_REQUEST_ITEM = `${namespace}/SET_SPACE_REQUEST_ITEM`;
const GET_SPACE_REQUEST_COLLECTIONS = `${namespace}/GET_SPACE_REQUEST_COLLECTIONS`;
const SET_SPACE_REQUEST_COLLECTIONS = `${namespace}/SET_SPACE_REQUEST_COLLECTIONS`;
const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_LOAD = `${namespace}/SET_LOAD`;

const APPROVE_REQUEST = `${namespace}/APPROVE_REQUEST`;
const DECLINE_REQUEST = `${namespace}/DECLINE_REQUEST`;
const CANCEL_REQUEST = `${namespace}/CANCEL_REQUEST`;
const DECLINE_APPROVED_REQUEST = `${namespace}/DECLINE_APPROVED_REQUEST`;
const DECLINE_APPROVED_TENANT_REQUEST = `${namespace}/DECLINE_APPROVED_TENANT_REQUEST`;

const RELEASE_DEPOSIT_REQUEST = `${namespace}/RELEASE_DEPOSIT_REQUEST`;

const GET_PAID_LIST = `${namespace}/GET_PAID_LIST`;
const SET_PAID_LIST = `${namespace}/SET_PAID_LIST`;

const GET_PAID = `${namespace}/GET_PAID`;

const ADD_PAYMENT_METHOD = `${namespace}/ADD_PAYMENT_METHOD`;
const GET_PAYMENT_METHODS = `${namespace}/GET_PAYMENT_METHODS`;
const SET_PAYMENT_METHODS = `${namespace}/SET_PAYMENT_METHODS`;
const REMOVE_PAYMENT_METHOD = `${namespace}/REMOVE_PAYMENT_METHOD`;
const SET_DEFAULT_PAYMENT_METHOD = `${namespace}/SET_DEFAULT_PAYMENT_METHOD`;

const GET_REQUEST_MESSAGES = `${namespace}/GET_REQUEST_MESSAGES`;
const SET_REQUEST_MESSAGES = `${namespace}/SET_REQUEST_MESSAGES`;
const GET_SPACE_REQUESTS_BY_ID = `${namespace}/GET_SPACE_REQUESTS_BY_ID`;
const SET_SPACE_REQUESTS_BY_ID = `${namespace}/SET_SPACE_REQUESTS_BY_ID`;

export const getBookingItem = createAction(GET_BOOKING_ITEM);
export const setBookingItem = createAction(SET_BOOKING_ITEM);
export const setBookingPag = createAction(SET_BOOKING_PAG);
export const getBookingPag = createAction(GET_BOOKING_PAG);

export const getRequestItem = createAction(GET_REQUEST_ITEM);
export const setRequestItem = createAction(SET_REQUEST_ITEM);
export const getLocationsItem = createAction(GET_LOCATIONS_ITEM);
export const setLocationsItem = createAction(SET_LOCATIONS_ITEM);
export const getSpaceRequest = createAction(GET_SPACE_REQUEST_ITEM);
export const setSpaceRequest = createAction(SET_SPACE_REQUEST_ITEM);
export const getSpaceRequestCollections = createAction(GET_SPACE_REQUEST_COLLECTIONS);
export const setSpaceRequestCollections = createAction(SET_SPACE_REQUEST_COLLECTIONS);

export const setError = createAction(SET_ERROR);
export const setLoad = createAction(SET_LOAD);

export const approveRequest = createAction(APPROVE_REQUEST);
export const cancelRequest = createAction(CANCEL_REQUEST);
export const declineRequest = createAction(DECLINE_REQUEST);
export const declineApprovedRequest = createAction(DECLINE_APPROVED_REQUEST);
export const declineApprovedTenantRequest = createAction(DECLINE_APPROVED_TENANT_REQUEST);

export const releaseDepositRequest = createAction(RELEASE_DEPOSIT_REQUEST);

// export const canceledRequest = createAction(CANCELED_REQUEST);

export const getPaidList = createAction(GET_PAID_LIST);
export const setPaidList = createAction(SET_PAID_LIST);

export const getPaid = createAction(GET_PAID);

export const addPaymentMethod = createAction(ADD_PAYMENT_METHOD);
export const getPaymentMethods = createAction(GET_PAYMENT_METHODS);
export const setPaymentMethods = createAction(SET_PAYMENT_METHODS);
export const removePaymentMethod = createAction(REMOVE_PAYMENT_METHOD);
export const setDefaultPaymentMethod = createAction(SET_DEFAULT_PAYMENT_METHOD);

export const getRequestMessages = createAction(GET_REQUEST_MESSAGES);
export const setRequestMessages = createAction(SET_REQUEST_MESSAGES);
export const getSpaceRequestsById = createAction(GET_SPACE_REQUESTS_BY_ID);
export const setSpaceRequestsById = createAction(SET_SPACE_REQUESTS_BY_ID);

const initialState = {
    error: false,
    load: false,
    booking: null,
    request: null,
    locations: null,
    spaceRequest: null,
    spaceRequestCollections: null,
    paidList: null,
    paymentMethods: null,
    requestMessages: null,
    pagination: null,
    requestsById: null
};

export default handleActions(
    {
        [SET_BOOKING_ITEM]: (state, { payload }) => ({ ...state, booking: payload }),
        [SET_BOOKING_PAG]: (state, { payload }) => ({ ...state, pagination: payload }),

        [SET_REQUEST_ITEM]: (state, { payload }) => ({ ...state, request: payload }),
        [SET_LOCATIONS_ITEM]: (state, { payload }) => ({ ...state, locations: payload }),
        [SET_SPACE_REQUEST_ITEM]: (state, { payload }) => ({ ...state, spaceRequest: payload }),
        [SET_SPACE_REQUEST_COLLECTIONS]: (state, { payload }) => ({ ...state, spaceRequestCollections: payload }),
        [SET_PAID_LIST]: (state, { payload }) => ({ ...state, paidList: payload }),
        [SET_PAYMENT_METHODS]: (state, { payload }) => ({ ...state, paymentMethods: payload }),
        [SET_REQUEST_MESSAGES]: (state, { payload }) => ({ ...state, requestMessages: payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_SPACE_REQUESTS_BY_ID]: (state, { payload }) => ({ ...state, requestsById: payload }),
    },
    initialState
);

export const bookingSelector = (state) => state[namespace].booking;
export const bookingSelectorPag = (state) => state[namespace].pagination;
export const approveLoader = (state) => state[namespace].load;
export const requestSelector = (state) => state[namespace].request;
export const locationsSelector = (state) => state[namespace].locations;
export const spaceRequestSelector = (state) => state[namespace].spaceRequest;
export const spaceRequestCollectionsSelector = (state) => state[namespace].spaceRequestCollections;
export const paidListSelector = (state) => state[namespace].paidList;
export const paymentMethodsSelector = (state) => state[namespace].paymentMethods;
export const requestMessagesSelector = (state) => state[namespace].requestMessages;
export const requestsByIdSelector = (state) => state[namespace].requestsById;

export function* getBookingItemsRequest() {
    while (true) {
        const { payload } = yield take(GET_BOOKING_ITEM);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, '/user/bookings', { ...payload });
            yield put(setBookingItem(response));
        } catch (err) {
            // console.log(err);
            yield put(setError(err));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* getRequestsItemsRequest() {
    while (true) {
        // yield take(GET_REQUEST_ITEM);
        const { payload } = yield take(GET_REQUEST_ITEM);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, '/user/requests', { ...payload });
            yield put(setRequestItem(response));
        } catch (err) {
            // console.log(err);
            yield put(setError(err));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* getLocationsItemRequest() {
    while (true) {
        const { payload } = yield take(GET_LOCATIONS_ITEM);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, '/user/spaces', { ...payload });
            yield put(setLocationsItem(response));
        } catch (err) {
            // console.log(err);
            yield put(setError(err));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* getSpaceRequestItemRequest() {
    while (true) {
        const { payload } = yield take(GET_SPACE_REQUEST_ITEM);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, '/user/requests', { ...payload });
            yield put(setSpaceRequest(response));
        } catch (err) {
            // console.log(err);
            yield put(setError(err));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* getSpaceRequestCollectionsRequest() {
    while (true) {
        const {
            payload: { id, ...props },
        } = yield take(GET_SPACE_REQUEST_COLLECTIONS);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.get, `/space/requests/${id}`, props);
            yield put(setSpaceRequestCollections(response));
        } catch (err) {
            // console.log(err);
            yield put(setError(err));
        } finally {
            yield put(setLoad(false));
        }
    }
}

export function* approveRequestAction({ payload }) {
    yield put(setLoad(true));
    const {id, spaceId} = payload;
    try {
        const response = yield call(resource.post, `/request/approve/${id}`);
        yield put(setSpaceRequestCollections(response));
        yield put(getDealHistory(id));
        yield put(getSpaceRequestsById(spaceId))
        // yield put(getSpaceRequest());
        yield put(popupSuccess({ h2: 'Request Approved', message: 'You’ve approved the space booking request.' }));
        // const { booking } = yield call(resource.post, `/user/messages-by-request-id?requestId=${payload}`);
    } catch (err) {
        yield put(popupWarning({ h2: 'Oops!', message: 'Something went wrong. Please try again.', showImg: true }));
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* cancelRequestAction({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/request/cancel/${payload}`, 'ProfileController@cancel');
        yield put(setSpaceRequestCollections(response));
        // yield put(popupSuccess({h2:'Request Declined', message:'You’ve declined the space booking request.'}));
        yield put(popupSuccess({ h2: 'Request Canceled', message: 'You’ve canceled the space booking request.' }));
        yield put(getDealHistory(payload));
    } catch (err) {
        yield put(
            popupWarning({
                h2: 'Having Issues?',
                message: 'Contact a member of our team for help or email contactus@theofficeexchange.com.',
                showImg: true,
            })
        );
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* declineRequestAction({ payload }) {
    yield put(setLoad(true));
    const { id, spaceId } = payload;
    try {
        const response = yield call(resource.post, `/request/decline/${id}`);
        yield put(setSpaceRequestCollections(response));
        yield put(getDealHistory(id));
        yield put(getSpaceRequestsById(spaceId))
        //yield put(getSpaceRequest());
        yield put(popupSuccess({ h2: 'Request Denied', message: 'You’ve denied the space booking request.' }));
    } catch (err) {
        yield put(
            popupWarning({
                h2: 'Having Issues?',
                message: 'Contact a member of our team for help or email contactus@theofficeexchange.com.',
                showImg: true,
            })
        );
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* declineRequestApproveAction({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/request/refunddecline/${payload}`, 'ProfileController@refund');
        yield put(setSpaceRequestCollections(response));
        yield put(popupSuccess({ h2: 'Request Declined', message: 'You’ve declined the space booking request.' }));
        yield put(getDealHistory(payload));
    } catch (err) {
        yield put(
            popupWarning({
                h2: 'Having Issues?',
                message: 'Contact a member of our team for help or email contactus@theofficeexchange.com.',
                showImg: true,
            })
        );
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* declineRequestApproveTenantAction({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(resource.post, `/request/refund/${payload}`, 'ProfileController@refund');
        yield put(setSpaceRequestCollections(response));
        yield put(popupSuccess({ h2: 'Request Declined', message: 'You’ve declined the space booking request.' }));
        yield put(getDealHistory(payload));
    } catch (err) {
        yield put(
            popupWarning({
                h2: 'Having Issues?',
                message: 'Contact a member of our team for help or email contactus@theofficeexchange.com.',
                showImg: true,
            })
        );
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

export function* releaseDepositRequestAction({ payload }) {
    yield put(setLoad(true));
    try {
        const response = yield call(
            resource.post,
            `/request/releasedeposit/${payload}`,
            'ProfileController@releasedeposit'
        );
        yield put(setSpaceRequestCollections(response));
        yield put(popupSuccess({ h2: 'Deposit released', message: '' }));
        yield put(getDealHistory(payload));
    } catch (err) {
        yield put(popupWarning({ h2: 'The deposit do not released!', message: '' }));
        // console.log(err);
    } finally {
        yield put(setLoad(false));
    }
}

function* getPaidListRequest() {
    while (true) {
        yield take(GET_PAID_LIST);
        try {
            yield put(setLoad(true));
            const response = yield call(resource.get, `/user/check-balance`);
            yield put(setPaidList(response));
        } catch (err) {
            // console.log(err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* getPaidRequest() {
    while (true) {
        const {
            payload: { amount, closeModal, method },
        } = yield take(GET_PAID);

        try {
            yield put(setLoad(true));
            const response = yield call(resource.post, `/user/get-paid`, { amount, token: method });
            if (response) {
                closeModal();
                yield put(getPaidList());
            }
        } catch (err) {
            if (err.message)
                yield put(
                    popupWarning({
                        h2: 'Insufficient Funds',
                        message: 'Your account has insufficient funds.',
                        showImg: true,
                    })
                );
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* addPaymentMethodRequest() {
    while (true) {
        const { payload } = yield take(ADD_PAYMENT_METHOD);
        const { values, setSubmitting } = payload;
        try {
            yield put(setLoad(true));
            const response = yield call(resource.post, `/user/payment-method`, values);
            if (response) {
                // yield put(popupSuccess({h2:'Payment method successfully added'}));
                yield put(push('/account/payout-method'));
            }
            //window.location.reload();
        } catch (err) {
            // console.log(err);
            setSubmitting(false);
            yield put(
                popupWarning({ h2: 'Invalid Data', message: 'Something went wrong. Please try again.', showImg: true })
            );
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* getPaimentMethodsRequest() {
    while (true) {
        yield take(GET_PAYMENT_METHODS);
        try {
            const response = yield call(resource.get, `/user/payment-method`);
            yield put(setPaymentMethods(response));
        } catch (err) {
            // console.log(err);
        }
    }
}

function* getRequestMessagesRequest() {
    while (true) {
        yield take(GET_REQUEST_MESSAGES);
        try {
            const response = yield call(
                resource.get,
                `/user/messages?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
            );
            if (response.length) {
                const data = response.reduce((acc, data) => {
                    if (data.messages?.length) {
                        // const { space } = data;
                        const message = data.messages[0];
                        // const tenant = data.tenant;
                        message.tenant = data.tenant;
                        message.owner = data.owner;
                        acc = [...acc, message];
                    }
                    return acc;
                }, []);

                yield put(setRequestMessages(data));
            } else {
                yield put(setRequestMessages([]));
            }
        } catch (err) {
            // console.log(err);
        }
    }
}

function* removePaymentMethodRequest() {
    while (true) {
        const { payload } = yield take(REMOVE_PAYMENT_METHOD);
        yield put(setLoad(true));
        try {
            const response = yield call(resource.post, `/user/payment-method-delete?token=${payload}`);
            if (response) {
                yield put(getPaymentMethods());
                yield put(
                    popupSuccess({
                        h2: 'Payout Method Removed',
                        message: 'Your payout method has been successfully deleted.',
                    })
                );
            }
        } catch (err) {
            // console.log(err);
            yield put(
                popupWarning({ h2: 'Invalid Data', message: 'Something went wrong. Please try again.', showImg: true })
            );
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* setDefaultPaymentMethodRequest() {
    while (true) {
        const { payload } = yield take(SET_DEFAULT_PAYMENT_METHOD);
        try {
            yield put(setLoad(true));
            const response = yield call(resource.post, `/user/payment-method-setDefault`, payload);
            if (response) {
                yield put(getPaymentMethods());
                yield put(
                    popupSuccess({
                        h2: 'Payout Method Updated',
                        message: 'Your default bank payout method has been updated.',
                    })
                );
            }
        } catch (err) {
            // console.log(err);
        } finally {
            yield put(setLoad(false));
        }
    }
}

function* getRequestsById({payload}) {
    try {
        yield put(setLoad(true));
        const response = yield call(resource.get, `/user/requests?id=${payload}`);
        yield put(setSpaceRequestsById(response?.data[0]))
        console.log(response)
    } catch(error) {
        console.log(error)
    } finally {
        yield put(setLoad(false));
    }

}

export function* sagas() {
    yield all([
        getBookingItemsRequest(),
        // getBookingPagRequest(),
        getRequestsItemsRequest(),
        getLocationsItemRequest(),
        getSpaceRequestItemRequest(),
        getSpaceRequestCollectionsRequest(),
        takeLatest(APPROVE_REQUEST, approveRequestAction),
        takeLatest(CANCEL_REQUEST, cancelRequestAction),
        takeLatest(DECLINE_REQUEST, declineRequestAction),
        takeLatest(DECLINE_APPROVED_TENANT_REQUEST, declineRequestApproveTenantAction),
        takeLatest(DECLINE_APPROVED_REQUEST, declineRequestApproveAction),
        takeLatest(RELEASE_DEPOSIT_REQUEST, releaseDepositRequestAction),
        getPaidListRequest(),
        getPaidRequest(),
        addPaymentMethodRequest(),
        getPaimentMethodsRequest(),
        getRequestMessagesRequest(),
        removePaymentMethodRequest(),
        setDefaultPaymentMethodRequest(),
        takeLatest(GET_SPACE_REQUESTS_BY_ID, getRequestsById)
    ]);
}

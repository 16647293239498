import React from 'react';
import ClassNames from 'classnames';
import styles from './input-control.module.sass';

function InputCodeControl({
    errors,
    value,
    onChange,
    onBlur,
    message,
    type,
    label,
    placeholder,
    hidden,
    mask,
    error,
    ...props
}) {
    const dateFormat = new Date(value).toLocaleDateString();

    const htmlFor = `code-${Math.random()}`;
    const ref = React.useRef(null);
    const handleClick = (e) => {
        e.stopPropagation();
        ref.current.focus();
    };

    return (
        <div
            className={ClassNames(
                props.erorStyle ? styles.code__containerError : styles.code__container,
                value && props.erorStyle === undefined && styles.isActiveCode
            )}
            onClick={handleClick}
        >
            <label
                // className={ClassNames(styles.label, props.labelClassName, value ? styles.isActiveLabel : '')}
                className={ClassNames(
                    styles.label,
                    props.erorStyle ? styles.error : '',
                    props.labelClassName,
                    value ? styles.isActiveLabel : ''
                )}
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <div className={styles.code__wrapper}>
                <span className={styles.code__placeholder}>
                    <i>{hidden}</i>
                    {placeholder || mask}
                </span>
                <input
                    ref={ref}
                    id={htmlFor}
                    type="text"
                    data_atr="codeInput"
                    className={ClassNames(
                        styles.code,
                        value ? styles.isActiveLabel : '',
                        props.erorStyle ? '__containerError' : styles.code
                    )}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
}

export default InputCodeControl;
